/**
 * This component is meant to be used for containing stuff at the bottom of the screen.
 *
 * Case 1: Body content is NOT lengthy enough to fill in the screen white space.
 * By itself, this component should have no visible changes in this case.
 *
 * Case 2: Body content is long and a scrollbar is needed to explore more.
 * This component keeps the contained children on the screen, attached to the
 * bottom of the viewport.
 */

import { FC } from "react"
import { sprinkles } from "@pomebile/primitives-web"
import { negativeMargin } from "./StickyBottom.css"

type StickyBottomProps = {
  children: React.ReactNode
}

export const StickyBottom: FC<StickyBottomProps> = ({ children }) => {
  return (
    <div
      className={
        negativeMargin +
        " " +
        sprinkles({
          position: "sticky",
          bottom: 0,
          background: "background-sticky-bottom-default",
        })
      }
    >
      {children}
    </div>
  )
}
