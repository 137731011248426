import { TextField } from "@pomebile/design-system"
import { Link, Secure, Txt, VStack } from "@pomebile/primitives"
import * as Yup from "yup"
import * as V from "../utils/formValidation/validationMessages"
import { ScreenForm } from "../components/ScreenForm"
import { StickyBottom } from "../components/StickyBottom"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { useForm } from "../components/Form/useForm"
import { TermsAndConditionsDialog } from "../components/TermsAndConditionsDialog"
import { useRef, useState } from "react"
import { validateDateOfBirth } from "../utils/formValidation/validators"
import { VerifyIdentityProps } from "./VerifyIdentity"

export const IdentitySchema = Yup.object().shape({
  dateOfBirth: validateDateOfBirth({ minAge: 18 }), // Placed at the end to show this error first
  last4Ssn: Yup.string()
    .test("is-valid-ssn4", V.MSG_SSN, (value) => !!value && /^\d{4}$/.test(value))
    .max(4, V.MSG_LAST_4_SSN)
    .min(4, V.MSG_LAST_4_SSN)
    .required(V.MSG_REQUIRED),
})

//TODO: Deprecate this once the expirement is done/we're required to collect full 9
export function VerifyIdentityLastFour({ api, onDone }: VerifyIdentityProps) {
  const prevDateOfBirthValue = useRef("")
  const [termsDialog, setTermsDialog] = useState<boolean>(false)
  const [submit, getFieldProps, status, { isFormValid }] = useForm({
    name: "Personal Details", // Note: Must match 1.0 name for analytics
    schema: IdentitySchema,
    initial: { dateOfBirth: "", last4Ssn: "" },
    submit: async ({ dateOfBirth, last4Ssn }) => {
      let dateOfBirthParts: number[]

      if (dateOfBirth.includes("-")) {
        // follows the format [yyyy, mm, dd]
        dateOfBirthParts = dateOfBirth.split("-").map((numberStr) => Number(numberStr))
      } else {
        // follows the format [mm, dd, yyyy]
        const [mm, dd, yyyy] = dateOfBirth.split("/").map((numberStr) => Number(numberStr))
        // reorder to follow [yyyy, mm, dd] format
        dateOfBirthParts = [yyyy, mm, dd]
      }

      const _ = await api.submitDateOfBirth(dateOfBirthParts as [number, number, number])
      const res = await api.submitLast4Ssn(last4Ssn)

      onDone(res)
    },
  })

  const { onChange: onChangeLast4Ssn, ...restOfLast4SsnProps } = getFieldProps("last4Ssn")
  const { onChange: onChangeDateOfBirth, ...restOfDateOfBirthProps } = getFieldProps("dateOfBirth")

  const handleSsn4Change = (value: string) => {
    if (value.length <= 4 && !isNaN(Number(value))) {
      onChangeLast4Ssn(value)
    }
  }

  const handleDateOfBirthChange = (value: string) => {
    prevDateOfBirthValue.current = restOfDateOfBirthProps.value

    if (value === "") onChangeDateOfBirth("")

    // Regex that covers all possible formating combinations while users type in their date of birth
    const datePattern =
      /^(0|1|0[1-9]|1[0-2])(\/(0|1|2|3|0[1-9]|[12][0-9]|3[01])?(\/(1|2|19|20|\d{3}|\d{4})?)?)?$/

    let inputDate = value

    if (!datePattern.test(inputDate)) {
      return
    }

    const isExpectedLength = inputDate.length === 2 || inputDate.length === 5 // expects the formats MM and MM/DD
    const userTypedInCharacter = prevDateOfBirthValue.current.length <= inputDate.length // user typed in a new character

    // Auto-format adding a slash in between month, day, and year
    if (isExpectedLength && userTypedInCharacter) {
      inputDate = `${inputDate}/`
    }

    onChangeDateOfBirth(inputDate)
  }

  return (
    <ScreenForm onSubmit={submit}>
      <VStack gap="xl2">
        <VStack gap="sm">
          <Txt variant="headline2" as="h2">
            Verify your identity
          </Txt>
          <Txt>
            We are required to ask for this information to verify your identity. Your info is
            encrypted using banking-grade technology.
          </Txt>
        </VStack>
        <VStack gap="xs">
          <Secure>
            <TextField
              label="Date of birth"
              placeholder="MM/DD/YYYY"
              type="text"
              onChange={handleDateOfBirthChange}
              {...restOfDateOfBirthProps}
            />
            <TextField
              label="Last 4 of SSN"
              placeholder="0000"
              type="number"
              {...restOfLast4SsnProps}
              onChange={handleSsn4Change}
            />
          </Secure>

          <Txt as="p" variant="caption" color="text-caption">
            See important information about the procedures for opening a new account in our{" "}
            <Link color="neutral" decoration="underline" onClick={() => setTermsDialog(true)}>
              terms and conditions
            </Link>
          </Txt>
        </VStack>
      </VStack>
      <StickyBottom>
        <FormSubmitButton disabled={!isFormValid} status={status}>
          Continue
        </FormSubmitButton>
      </StickyBottom>

      <TermsAndConditionsDialog
        open={termsDialog}
        showMoneyTransferPromo={false} // We haven't shown the GCash promo yet at this point
        onClose={() => setTermsDialog(false)}
      />
    </ScreenForm>
  )
}
