/**
 * This component is meant to be used for full screen wrappers as a frame element.
 * Helps to control the empty white space-between elements in a column in the
 * scenario when the body content is not enough to fill in the vertical void.
 */

import { FC } from "react"
import { sprinkles } from "@pomebile/primitives-web"

type ScreenFrameProps = {
  children: React.ReactNode
}

export const ScreenFrame: FC<ScreenFrameProps> = ({ children }) => {
  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        flexGrow: 1, // height: 100%?
        maxWidth: "maxWidth",
        padding: "xl",
      })}
    >
      {children}
    </div>
  )
}
