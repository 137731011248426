import { Path, Svg } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export function NotificationIcon({ width = 24, height = 24, fill }: IconProps) {
  return (
    <Svg width={width} height={height} viewBox="0 0 16 16">
      <Path
        d="M13.5 8V13C13.5 13.2652 13.3947 13.5196 13.2071 13.7071C13.0196 13.8946 12.7652 14 12.5 14H3.00002C2.7348 14 2.48045 13.8946 2.29291 13.7071C2.10537 13.5196 2.00002 13.2652 2.00002 13V3.5C2.00002 3.23478 2.10537 2.98043 2.29291 2.79289C2.48045 2.60536 2.7348 2.5 3.00002 2.5H8.00002C8.13262 2.5 8.2598 2.55268 8.35357 2.64645C8.44734 2.74021 8.50002 2.86739 8.50002 3C8.50002 3.13261 8.44734 3.25979 8.35357 3.35355C8.2598 3.44732 8.13262 3.5 8.00002 3.5H3.00002V13H12.5V8C12.5 7.86739 12.5527 7.74021 12.6465 7.64645C12.7402 7.55268 12.8674 7.5 13 7.5C13.1326 7.5 13.2598 7.55268 13.3536 7.64645C13.4473 7.74021 13.5 7.86739 13.5 8Z"
        fill={fill}
      />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5667 8V13C13.5667 13.2829 13.4543 13.5542 13.2543 13.7542C13.0542 13.9543 12.7829 14.0667 12.5 14.0667H3.00002C2.71712 14.0667 2.44581 13.9543 2.24577 13.7542C2.04573 13.5542 1.93335 13.2829 1.93335 13V3.5C1.93335 3.2171 2.04573 2.94579 2.24577 2.74575C2.44581 2.54571 2.71712 2.43333 3.00002 2.43333H8.00002C8.15031 2.43333 8.29444 2.49304 8.40071 2.59931C8.50698 2.70558 8.56668 2.84971 8.56668 3C8.56668 3.15029 8.50698 3.29442 8.40071 3.40069C8.29444 3.50696 8.15031 3.56667 8.00002 3.56667H3.06668V12.9333H12.4333V8C12.4333 7.84971 12.4931 7.70558 12.5993 7.59931C12.7056 7.49304 12.8497 7.43333 13 7.43333C13.1503 7.43333 13.2944 7.49304 13.4007 7.59931C13.507 7.70558 13.5667 7.84971 13.5667 8ZM12.5 8C12.5 7.86739 12.5527 7.74021 12.6465 7.64645C12.7402 7.55268 12.8674 7.5 13 7.5C13.1326 7.5 13.2598 7.55268 13.3536 7.64645C13.4473 7.74021 13.5 7.86739 13.5 8V13C13.5 13.2652 13.3947 13.5196 13.2071 13.7071C13.0196 13.8946 12.7652 14 12.5 14H3.00002C2.7348 14 2.48045 13.8946 2.29291 13.7071C2.10537 13.5196 2.00002 13.2652 2.00002 13V3.5C2.00002 3.23478 2.10537 2.98043 2.29291 2.79289C2.48045 2.60536 2.7348 2.5 3.00002 2.5H8.00002C8.13262 2.5 8.2598 2.55268 8.35357 2.64645C8.44734 2.74021 8.50002 2.86739 8.50002 3C8.50002 3.13261 8.44734 3.25979 8.35357 3.35355C8.2598 3.44732 8.13262 3.5 8.00002 3.5H3.00002V13H12.5V8Z"
        fill={fill}
      />
      <Path
        d="M14.5 3.75C14.5 4.19501 14.3681 4.63002 14.1208 5.00003C13.8736 5.37004 13.5222 5.65843 13.1111 5.82873C12.6999 5.99903 12.2475 6.04358 11.8111 5.95677C11.3746 5.86995 10.9737 5.65566 10.659 5.34099C10.3444 5.02632 10.1301 4.62541 10.0433 4.18895C9.95643 3.7525 10.001 3.3001 10.1713 2.88896C10.3416 2.47783 10.63 2.12643 11 1.87919C11.37 1.63196 11.805 1.5 12.25 1.5C12.8468 1.5 13.4191 1.73705 13.841 2.15901C14.263 2.58097 14.5 3.15326 14.5 3.75Z"
        fill={fill}
      />
    </Svg>
  )
}
