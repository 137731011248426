import { AnalyticsBrowser } from "@segment/analytics-next"
import * as env from "../envConstants"
import { ErrorEventData, LoggingContext } from "./logging"
import { ResponseStatusError } from "../api/issueRequest"
import { ResponseValidationError, ExpectedResponseError } from "../api/webRoutes"

const isAnalyticsEnabled = env.SEGMENT_ENABLED
const analytics = isAnalyticsEnabled
  ? AnalyticsBrowser.load({ writeKey: env.SEGMENT_WRITE_KEY })
  : undefined

export const anonymousIdPromise = isAnalyticsEnabled
  ? analytics
      ?.user()
      .then((u) => u.anonymousId())
      .catch(() => undefined)
  : undefined

// We should ensure we keep all the 1.0 properties for continuity
// {
//   source: "Client Side",
//   reason: string,
//   errorType: string,
//   expected: boolean,
//   formName: formName,
// }
function errorEventProperties(err: unknown) {
  if (err instanceof ResponseValidationError) {
    return {
      source: "Client Side",
      reason: err.message,
      errorType: "Bad Server Response",
      expected: false,
    }
  } else if (err instanceof ResponseStatusError) {
    return {
      source: "Client Side",
      reason: err.message,
      errorType: "Unexpected Server Response",
      expected: false,
    }
  } else if (err instanceof ExpectedResponseError) {
    return {
      source: "Client Side",
      reason: err.message,
      errorType: "Expected Server Response",
      expected: true,
    }
  }

  return {
    source: "Client Side",
    reason: "Unknown",
    errorType: "Unknown",
    expected: false,
  }
}

export const segmentAnalyticsLogger: LoggingContext = {
  logError: (_err: unknown) => {
    // TODO
  },

  logUser: (userId: string, traits) => {
    if (!analytics) return

    analytics.identify(userId, traits)
  },

  logScreen: (name: string, data?: object) => {
    if (!analytics) return

    analytics.page(name, data)
  },

  logEvent: (name: string, data?: object) => {
    if (!analytics) return

    analytics.track(name, data)
  },

  logFormEvent: (name, event) => {
    if (!analytics) return

    switch (event.tag) {
      case "submitted":
        analytics.track("Information Submitted", {
          formName: name,
        })
        break
      case "submissionRejected":
        analytics.track("Information Submitted Rejected", {
          formName: name,
          source: "Client Side",
          reason: event.reason,
          errorType: "Server validation",
          expected: true,
        })
        break
      case "validationRejected":
        analytics.track("Information Submitted Rejected", {
          formName: name,
          source: "Client Side",
          reasons: event.reasons,
          errorType: "Validation",
          expected: true,
        })
        break
      case "submissionFailed":
        analytics.track("Information Submitted Rejected", {
          formName: name,
          ...errorEventProperties(event.err),
        })
    }
  },

  logErrorEvent: (name: string, data?: ErrorEventData) => {
    if (!analytics) return

    analytics.track(name, data)
  },
}
