import { useMemo } from "react"
import * as Yup from "yup"
import { DropdownMenuField, TextField } from "@pomebile/design-system"
import { HStack, Secure, Txt, VStack } from "@pomebile/primitives"
import { ScreenForm } from "../components/ScreenForm"
import * as V from "../utils/formValidation/validationMessages"
import { StickyBottom } from "../components/StickyBottom"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { useForm } from "../components/Form/useForm"
import { PHAddress } from "../api/webRoutes"
import phProvinces from "../utils/phProvinces.json"

type PHAddressSchema = PHAddress
export const PHAddressSchema: Yup.ObjectSchema<PHAddressSchema> = Yup.object().shape({
  addressLineOne: Yup.string().required(V.MSG_REQUIRED).max(44, V.MSG_MAX_CHARACTERS(44)),
  addressLineTwo: Yup.string().max(44, V.MSG_MAX_CHARACTERS(44)),
  barangay: Yup.string().required(V.MSG_REQUIRED).max(44, V.MSG_MAX_CHARACTERS(44)),
  cityOrMunicipality: Yup.string().max(50, V.MSG_MAX_CHARACTERS(50)).required(V.MSG_REQUIRED),
  province: Yup.string().required(V.MSG_REQUIRED),
  postalCode: Yup.string()
    .matches(/(^\d{4}$)/, V.MSG_INVALID_ZIP)
    .required(V.MSG_REQUIRED),
  country: Yup.string().oneOf(["PH"]).required(V.MSG_REQUIRED),
})

export type PHHomeAddress = {
  api: {
    addPHAddress: (address: PHAddress) => Promise<void>
  }

  onDone: (address: PHAddress) => void
}

export function PHHomeAddress({ api, onDone }: PHHomeAddress) {
  // Todo: Uncomment if we can/will use SmartyStreets with ph addresses.
  // const { suggestions, fetchSuggestions } = useAddressSuggestions()
  const [submit, getFieldProps, status, { setError }] = useForm({
    name: "Nrau Home Address", // Note: Must match 1.0 name for analytics
    schema: PHAddressSchema,
    initial: {
      addressLineOne: "",
      addressLineTwo: "",
      barangay: "",
      cityOrMunicipality: "",
      province: "",
      postalCode: "",
      country: "PH",
    },
    submit: async (address) => {
      /* TODO: replace with actual validation function for PH postal code */
      const isValidPostalCode = () => {
        return true
      }

      if (!isValidPostalCode) {
        return {
          status: "validationErr",
          err: { field: "postalCode", errorMessage: V.MSG_INVALID_POSTAL_CODE },
        }
      }

      await api.addPHAddress(address)
      onDone(address)
      return undefined
    },
  })

  const { onChange: onChangeLineOne, ...restOfLineOneProps } = getFieldProps("addressLineOne")
  const {
    onChange: onChangeLineTwo,
    value: lineTwo = "",
    ...restOfLineTwoProps
  } = getFieldProps("addressLineTwo")
  const { onChange: onChangeCityOrMunicipality, ...restOfCityProps } =
    getFieldProps("cityOrMunicipality")
  const { onChange: onChangeBarangay, ...restOfBarangayProps } = getFieldProps("barangay")
  const { onChange: onChangeProvince, ...restOfStateProps } = getFieldProps("province")
  const {
    onChange: onChangePostalCode,
    value: postalCode = "",
    ...restOfPostalCodeProps
  } = getFieldProps("postalCode")

  // Todo: Uncomment if we can/will use SmartyStreets with ph addresses.
  // const formatAddressSuggestion = (suggestion: usAutocompletePro.Suggestion) => {
  //   return `${suggestion.streetLine}${
  //     suggestion.secondary.length > 0 ? ` ${suggestion.secondary}` : ""
  //   }, ${suggestion.city} ${suggestion.state}, ${suggestion.zipcode}`
  // }

  const provinceOptions = useMemo(
    () =>
      phProvinces.map(({ name, code }) => ({
        value: code,
        label: name,
      })),
    [],
  )

  return (
    <ScreenForm onSubmit={submit}>
      <VStack gap="xl2" padding={{ bottom: "xl2" }}>
        <VStack gap="sm">
          <Txt variant="headline2" as="h2">
            Your home address
          </Txt>

          <Txt variant="body1" as="p">
            Your Pomelo Mastercard® will be sent to this address. Only residential addresses may be
            entered. P.O. Boxes and commercial addresses will not be accepted.
          </Txt>
        </VStack>

        <VStack gap="xs">
          {/* Todo: Uncomment if we can/will use SmartyStreets with ph addresses. */}
          {/* <Combobox
            options={suggestions}
            renderOption={formatAddressSuggestion}
            onSelectOption={(suggestion) => {
              const { streetLine, secondary, city, state, zipcode } = suggestion
              onChangeLineOne(streetLine)
              onChangeLineTwo(secondary)
              onChangeCityOrMunicipality(city)
              onChangeProvince(state)
              onChangePostalCode(zipcode)
            }}
          > */}
          <Secure>
            <TextField
              label="House Number and Street Address"
              placeholder="Street Address..."
              type="text"
              onChange={(v) => {
                onChangeLineOne(v)
                // v && fetchSuggestions(v)
              }}
              {...restOfLineOneProps}
            />
            {/* </Combobox> */}
            <TextField
              label="Unit or Floor Number"
              placeholder="Unit or Floor number"
              type="text"
              {...restOfLineTwoProps}
              value={lineTwo}
              onChange={onChangeLineTwo}
            />
          </Secure>

          <TextField
            label="Barangay"
            placeholder="Enter Barangay..."
            type="text"
            onChange={onChangeBarangay}
            {...restOfBarangayProps}
          />

          <TextField
            label="City/Municipality"
            placeholder="Enter City..."
            type="text"
            onChange={onChangeCityOrMunicipality}
            {...restOfCityProps}
          />

          <HStack gap="sm">
            <DropdownMenuField
              options={provinceOptions}
              label="Province"
              placeholder="Select..."
              onChange={onChangeProvince}
              {...restOfStateProps}
            />
            <TextField
              label="Postal Code"
              type="text"
              placeholder="0000"
              value={postalCode}
              onChange={(value) => {
                if (value.length > 4) {
                  return
                }

                onChangePostalCode(value)
              }}
              {...restOfPostalCodeProps}
            />
          </HStack>
        </VStack>
      </VStack>

      <StickyBottom>
        <FormSubmitButton status={status}>Continue</FormSubmitButton>
      </StickyBottom>
    </ScreenForm>
  )
}
