/* 
  Validate SSNs against the following rules:
  - The format must be 123-45-6789 (including dashes)
  - All characters must be digits
  - The first group of digits cannot be 000 or 666
  - No group of digits can be all zeros
  - The SSN can't be 123-45-6789

  Note: This function explicitly does NOT return false for ITINs, which start with a 9. We have
  a separate validation rule for that (with different messaging), and we may be able to support 
  ITINs at some point in the future.
*/
export const validateSsnOrItin = (ssn: string): boolean => {
  const isCorrectFormat = (value: string) => /^\d{3}-\d{2}-\d{4}$/.test(value)
  if (!isCorrectFormat(ssn)) {
    return false
  }

  const ssnParts = ssn.split("-")
  const [firstGroup, middleGroup, lastGroup] = ssnParts

  if (firstGroup === "000" || middleGroup === "00" || lastGroup === "0000") {
    // None of the three groups may be all zeros
    return false
  }

  if (firstGroup === "666") {
    // The first group may not be 666
    return false
  }

  if (firstGroup === "123" && middleGroup === "45" && lastGroup === "6789") {
    // SSN may not be 123-45-6789
    return false
  }

  return true
}
