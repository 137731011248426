import { formatPhoneNumber } from "./format"

export const handlePhoneNumberChange = (
  onChangePhoneNumber: (formattedPhone: string) => void,
  separator = " ",
) => {
  return (value: string) => {
    if (value.length <= 12) {
      const formattedPhone = formatPhoneNumber(value, separator)
      onChangePhoneNumber(formattedPhone)
    }
  }
}
