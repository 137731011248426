import { Avatar, HStack, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { IdCardIcon, PomeloMonochromeIcon, EnvelopeIcon } from "@pomebile/design-system"

export function ManualReviewScreen() {
  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="space-between">
        <VStack height="full" gap="xl2" padding={{ bottom: "lg" }}>
          <VStack padding={{ bottom: "lg" }}>
            <Txt variant="headline2">Identity verification in progress</Txt>
            <Txt variant="body1">
              We're working to verify your identity and are here to help. Here's what to expect
              next:
            </Txt>
          </VStack>

          <VStack gap="xl2">
            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<EnvelopeIcon fill="icon-brand" />} />
              <VStack>
                <Txt variant="subtitle2">Watch out for our email</Txt>
                <Txt variant="body2">
                  Expect an email with next steps within 1-2 hours as we review your application.
                </Txt>
              </VStack>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<IdCardIcon width={32} height={32} fill="icon-brand" />} />
              <VStack>
                <Txt variant="subtitle2">Prepare necessary documents</Txt>
                <Txt variant="body2">
                  You may need to provide documents like a photo of your ID or a recent bill. We'll
                  let you know in the email.
                </Txt>
              </VStack>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar
                size="lg"
                svg={<PomeloMonochromeIcon width={25.2} height={32} fill="icon-brand" />}
              />
              <VStack>
                <Txt variant="subtitle2">View your offer</Txt>
                <Txt variant="body2">
                  We'll notify you once your identity is confirmed, so you can view your offer.
                </Txt>
              </VStack>
            </HStack>
          </VStack>
        </VStack>
      </VStack>
    </ScreenFrame>
  )
}
