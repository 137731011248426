import { Txt, VStack } from "@pomebile/primitives"
import { TextField, VSpace } from "@pomebile/design-system"
import { StickyBottom } from "../components/StickyBottom"
import { BankTransactionItemSvg } from "../components/BankTransactionItemSvg"
import * as Yup from "yup"
import * as V from "../utils/formValidation/validationMessages"
import { useForm } from "../components/Form/useForm"
import { MicroDepositVerificationResponse } from "../api/webRoutes"
import { ScreenForm } from "../components/ScreenForm"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { ResubmissionDialog } from "./Veriff/ResubmissionDialog"
import { useState } from "react"
import { segmentAnalyticsLogger } from "../utils/segment"

const DepositVerificationSchema = Yup.object({
  depositAmount: Yup.string()
    .required(V.MSG_REQUIRED)
    .matches(/^\d+$/, V.MSG_INVALID_CHARACTERS)
    .test("is greater than zero", V.MSG_INVALID_DEPOSIT_AMOUNT, (value) => {
      try {
        const parsed = Number.parseInt(value)

        if (Number.isNaN(parsed)) {
          return false
        }

        return parsed > 0
      } catch (error) {
        return false
      }
    }),
})

interface DepositVerificationInfo extends Yup.InferType<typeof DepositVerificationSchema> {}

const initialFormValues: DepositVerificationInfo = {
  depositAmount: "",
}

type RecipientVerificationOutcome =
  | {
      tag: "success"
    }
  | {
      tag: "tooManyAttempts"
    }
  | {
      tag: "unexpectedError"
    }

interface RecipientVerificationConfirmDepositProps {
  bankPayer: string
  last4: string

  api: {
    verifyDepositAmount: (depositAmount: number) => Promise<MicroDepositVerificationResponse>
  }

  onDone: (outcome: RecipientVerificationOutcome) => void
}

export function RecipientVerificationConfirmDeposit({
  bankPayer,
  last4,
  api: { verifyDepositAmount },
  onDone,
}: RecipientVerificationConfirmDepositProps) {
  const [showResubmissionDialog, setResubmissionDialog] = useState(false)

  const [submit, getFieldProps, status] = useForm({
    name: "Deposit Amount",
    schema: DepositVerificationSchema,
    initial: initialFormValues,
    submit: async ({ depositAmount }) => {
      const amount = Number(depositAmount.replace(/\D/g, ""))
      segmentAnalyticsLogger.logEvent("Confirm deposit amount continue button clicked")

      const depositVerificationRes = await verifyDepositAmount(amount)

      if (depositVerificationRes.tag === "incorrect") {
        segmentAnalyticsLogger.logEvent("Confirm deposit amount error incorrect value")
        return {
          status: "validationErr",
          err: {
            errorMessage: V.MSG_INCORRECT_DEPOSIT_AMOUNT,
            field: "depositAmount",
          },
        }
      }

      onDone(depositVerificationRes)

      return undefined
    },
  })

  const { onChange, ...restofDepositAmountFieldProps } = getFieldProps("depositAmount")
  const handleChange = (value: string) => {
    // Only allow up to two digits
    if (value.match(/^[0-9]{0,2}$/)) {
      if (value.length === 2) {
        segmentAnalyticsLogger.logEvent("Confirm deposit amount field filled")
      }
      onChange(value)
    }
  }

  return (
    <ScreenForm onSubmit={submit}>
      <VStack height="full">
        <VStack height="full" gap="xl2">
          <VStack>
            <Txt variant="headline2">Confirm your bank account</Txt>
            <Txt>
              You’ll receive a test deposit below ₱1.00 into your bank account ({bankPayer} ••
              {last4}). Please confirm the 2-digit centavo amount.
            </Txt>
          </VStack>

          <TextField
            label="Confirm deposit amount"
            type="number"
            inputAdornment={<Txt>₱0.</Txt>}
            placeholder="00"
            onChange={handleChange}
            {...restofDepositAmountFieldProps}
          />

          <VStack>
            <BankTransactionItemSvg />
          </VStack>
        </VStack>

        <StickyBottom>
          <FormSubmitButton status={status}>Continue</FormSubmitButton>
        </StickyBottom>
      </VStack>

      <ResubmissionDialog isOpen={showResubmissionDialog} onContinue={() => {}} />
    </ScreenForm>
  )
}
