import { Path, Svg } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const DeviceMobileCameraIcon = ({ width = 24, height = 24, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 34 34" customFill="none">
      <Path
        d="M23.1363 2.68182H10.8636C10.0499 2.68182 9.26946 3.00508 8.69406 3.58047C8.11866 4.15587 7.79541 4.93627 7.79541 5.75001V28.25C7.79541 29.0637 8.11866 29.8441 8.69406 30.4195C9.26946 30.9949 10.0499 31.3182 10.8636 31.3182H23.1363C23.9501 31.3182 24.7305 30.9949 25.3059 30.4195C25.8812 29.8441 26.2045 29.0637 26.2045 28.25V5.75001C26.2045 4.93627 25.8812 4.15587 25.3059 3.58047C24.7305 3.00508 23.9501 2.68182 23.1363 2.68182ZM24.159 28.25C24.159 28.5212 24.0513 28.7814 23.8595 28.9732C23.6677 29.165 23.4076 29.2727 23.1363 29.2727H10.8636C10.5923 29.2727 10.3322 29.165 10.1404 28.9732C9.94862 28.7814 9.84086 28.5212 9.84086 28.25V5.75001C9.84086 5.47876 9.94862 5.21863 10.1404 5.02683C10.3322 4.83503 10.5923 4.72728 10.8636 4.72728H23.1363C23.4076 4.72728 23.6677 4.83503 23.8595 5.02683C24.0513 5.21863 24.159 5.47876 24.159 5.75001V28.25ZM18.534 8.30682C18.534 8.61024 18.4441 8.90684 18.2755 9.15912C18.1069 9.4114 17.8673 9.60803 17.587 9.72414C17.3067 9.84025 16.9983 9.87063 16.7007 9.81144C16.4031 9.75224 16.1297 9.60614 15.9152 9.39159C15.7006 9.17704 15.5545 8.90369 15.4953 8.60611C15.4361 8.30853 15.4665 8.00007 15.5826 7.71975C15.6988 7.43943 15.8954 7.19984 16.1477 7.03127C16.3999 6.86271 16.6965 6.77273 17 6.77273C17.4068 6.77273 17.797 6.93436 18.0847 7.22206C18.3724 7.50976 18.534 7.89996 18.534 8.30682Z"
        fill={fill}
      />
    </Svg>
  )
}
