import {
  matchDeferred,
  matchDeferredTuple,
  taggedUnion,
  Variants,
} from "@pomebile/shared/tagged-union"
import { RecipientVerificationValidUrl } from "./api/webRoutes"

interface Screens {
  FetchingUniqueLinkInfo: {
    recipientVerificationAttemptId: string
  }
  VerifyIdentity: {
    senderName: string
    transferAmount: number
    transferExpiration: string
    veriff: { tag: "veriffRequired"; veriffUrl: string } | { tag: "veriffDone" }
  }
  ConfirmDeposit: {
    recipientVerificationAttemptId: string
    recipientBankPayer: string
    recipientBankLast4: string
  }
  FetchingVeriffDecision: {
    recipientVerificationAttemptId: string
  }
  Success: {
    recipientBankPayer: string
    recipientBankLast4: string
  }
  RetryIdentityVerification: {
    veriffUrl: string
  }
  Rejected: void
  InvalidLink: void
  TooManyAttempts: void
  TimeLimitError: void
  GeneralError: void
}

export const AppScreen = taggedUnion<Screens>()
export type AppScreen = Variants<Screens>

interface AppStates {
  FetchingInfo: {
    recipientVerificationAttemptId: string
  }
  VerifyingIdentity: {
    recipientVerificationAttemptId: string
    uniqueLinkInfo: RecipientVerificationValidUrl
  }
  ConfirmingDeposit: {
    recipientVerificationAttemptId: string
    recipientBankPayer: string
    recipientBankLast4: string
  }
  FetchingVeriffDecision: {
    recipientVerificationAttemptId: string
    recipientBankPayer: string
    recipientBankLast4: string
  }
  RetryIdentityVerification: {
    recipientVerificationAttemptId: string
    recipientBankPayer: string
    recipientBankLast4: string
    veriffUrl: string
  }
  Complete: {
    recipientBankPayer: string
    recipientBankLast4: string
  }
  Rejected: void
  TimeoutError: void
  InvalidLinkError: void
  TooManyAttemptsError: void
  GeneralError: void
}

interface AppEvents {
  FetchedUniqueLinkInfo: {
    uniqueLinkInfo: RecipientVerificationValidUrl
  }
  SubmittedIdentityVerification: void
  CompletedIdentityVerification: void
  SkippedIdentityVerification: void
  ConfirmedDepositAmount: void
  RequiredIdentityVerificationResubmission: {
    veriffUrl: string
  }
  FailedRecipientVerification: void
  UsedInvalidLink: void
  RequestTimedOut: void
  RequiredSupport: void
  ExceededAttemptsLimit: void
  EncounteredGeneralError: { errorType: string }
}

export const AppState = taggedUnion<AppStates>()
export type AppState = Variants<AppStates>

export const AppEvent = taggedUnion<AppEvents>()
export type AppEvent = Variants<AppEvents>

export const calculateRecipientVerificationScreen: (state: AppState) => AppScreen = matchDeferred(
  AppState,
  {
    FetchingInfo: (prev) => AppScreen.FetchingUniqueLinkInfo({ ...prev }),
    VerifyingIdentity: ({ uniqueLinkInfo }) =>
      AppScreen.VerifyIdentity({
        senderName: uniqueLinkInfo.senderName,
        transferAmount: uniqueLinkInfo.transferAmount,
        transferExpiration: uniqueLinkInfo.linkExpirationTs,
        veriff:
          uniqueLinkInfo.veriff.tag === "created"
            ? {
                tag: "veriffRequired",
                veriffUrl: uniqueLinkInfo.veriff.url,
              }
            : { tag: "veriffDone" },
      }),
    InvalidLinkError: () => AppScreen.InvalidLink(),
    TooManyAttemptsError: () => AppScreen.TooManyAttempts(),
    Complete: (prev) => AppScreen.Success({ ...prev }),
    ConfirmingDeposit: (prev) => AppScreen.ConfirmDeposit({ ...prev }),
    FetchingVeriffDecision: ({ recipientVerificationAttemptId }) =>
      AppScreen.FetchingVeriffDecision({ recipientVerificationAttemptId }),
    RetryIdentityVerification: ({ veriffUrl }) =>
      AppScreen.RetryIdentityVerification({ veriffUrl }),
    TimeoutError: () => AppScreen.TimeLimitError(),
    GeneralError: () => AppScreen.GeneralError(),
    Rejected: () => AppScreen.Rejected(),
  },
)

const {
  Complete,
  ConfirmingDeposit,
  VerifyingIdentity,
  InvalidLinkError,
  TooManyAttemptsError,
  TimeoutError,
  GeneralError,
  Rejected,
  FetchingVeriffDecision,
  RetryIdentityVerification,
} = AppState

export const updateRecipientVerificationAppState = matchDeferredTuple(AppState, AppEvent, {
  FetchingInfo: {
    FetchedUniqueLinkInfo: ({ recipientVerificationAttemptId }, { uniqueLinkInfo }) => {
      const { veriff, recipientBankLast4, recipientBankPayer, isMdvRequired } = uniqueLinkInfo

      if (veriff.tag === "created") {
        return VerifyingIdentity({ recipientVerificationAttemptId, uniqueLinkInfo })
      }

      if (isMdvRequired) {
        return ConfirmingDeposit({
          recipientBankLast4,
          recipientBankPayer,
          recipientVerificationAttemptId,
        })
      }

      switch (veriff.tag) {
        case "approved":
          return Complete({ recipientBankLast4, recipientBankPayer })

        case "declined":
          return Rejected()

        case "resubmit":
          return RetryIdentityVerification({
            recipientBankLast4,
            recipientBankPayer,
            recipientVerificationAttemptId,
            veriffUrl: veriff.url,
          })

        case "submitted":
          return FetchingVeriffDecision({
            recipientBankLast4,
            recipientBankPayer,
            recipientVerificationAttemptId,
          })

        default:
          return GeneralError()
      }
    },
    UsedInvalidLink: () => InvalidLinkError(),
    EncounteredGeneralError: () => GeneralError(),
  },

  VerifyingIdentity: {
    SubmittedIdentityVerification: ({
      recipientVerificationAttemptId,
      uniqueLinkInfo: { recipientBankLast4, recipientBankPayer },
    }) =>
      ConfirmingDeposit({
        recipientVerificationAttemptId,
        recipientBankLast4,
        recipientBankPayer,
      }),
  },

  ConfirmingDeposit: {
    ConfirmedDepositAmount: (prev) => FetchingVeriffDecision({ ...prev }),
    ExceededAttemptsLimit: () => TooManyAttemptsError(),
    EncounteredGeneralError: () => GeneralError(),
  },

  FetchingVeriffDecision: {
    CompletedIdentityVerification: (prev) => Complete({ ...prev }),
    RequiredIdentityVerificationResubmission: (prev, { veriffUrl }) =>
      RetryIdentityVerification({ ...prev, veriffUrl }),
    FailedRecipientVerification: () => Rejected(),
    RequestTimedOut: () => TimeoutError(),
    EncounteredGeneralError: () => GeneralError(),
  },

  RetryIdentityVerification: {
    SubmittedIdentityVerification: ({
      recipientVerificationAttemptId,
      recipientBankLast4,
      recipientBankPayer,
    }) =>
      FetchingVeriffDecision({
        recipientVerificationAttemptId,
        recipientBankLast4,
        recipientBankPayer,
      }),
  },

  default: (prev) => prev,
})
