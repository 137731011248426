import { useReducer } from "react"
import { useLogging } from "../utils/logging"

export type SubmitStatus = "done" | "submitErr" | "submitting" | "idle"

type SubmitState = {
  status: SubmitStatus
}

export type UseSubmitResult = [handleSubmit: () => void, status: SubmitStatus]

const update = (prev: SubmitState, ev: SubmitState): SubmitState => {
  switch (ev.status) {
    case "done":
      return { ...prev, status: "done" }
    case "submitErr":
      return { ...prev, status: "submitErr" }
    case "submitting":
      return { ...prev, status: "submitting" }
    case "idle":
      return { ...prev, status: "idle" }
    default:
      return prev
  }
}

export function useSubmit(submit: () => Promise<void>): UseSubmitResult {
  const { logError } = useLogging()
  const [{ status }, send] = useReducer(update, { status: "idle" })

  return [
    function handleSubmit() {
      if (status === "submitting") {
        // no double submit
        return
      }
      send({ status: "submitting" })

      submit()
        .then(() => {
          send({ status: "done" })
        })
        .catch((err) => {
          logError(err)
          send({ status: "submitErr" })
        })
    },
    status,
  ]
}
