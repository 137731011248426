import { Dialog } from "../../components/Dialog"
import { VStack } from "@pomebile/primitives"
import { Txt } from "@pomebile/primitives-web"

export interface SubmittingDialogProps {
  isOpen: boolean
}

export const SubmittingDialog = ({ isOpen }: SubmittingDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={() => {}}>
      <VStack padding="xl" gap="xl" justifyContent="center" alignItems="center">
        <VStack gap="xs">
          <VStack gap="sm" justifyContent="center" alignItems="center">
            <img
              src="images/pomelo_spinner_gray.gif"
              alt="Loading Spinner"
              width={60}
              height={60}
            />
            <Txt variant="headline2" textAlign="center">
              Submitting Documents
            </Txt>
          </VStack>
          <Txt variant="body2" textAlign="center">
            Submitting your documents for processing. This may take up to a minute.
          </Txt>
        </VStack>
      </VStack>
    </Dialog>
  )
}
