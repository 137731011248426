import LogRocket from "logrocket"
import * as env from "../envConstants"

const isLogRocketEnabled = env.LOGROCKET_ENABLED
const logRocketId = env.LOGROCKET_ID

export function initLogRocket(): void {
  if (!isLogRocketEnabled) {
    return
  }

  LogRocket.init(logRocketId, {
    network: {
      requestSanitizer: (_request) => {
        // TODO: Add whitelisted fields here
        return null
      },
      responseSanitizer: (_response) => {
        // TODO: Add whitelisted fields here
        return null
      },
    },
  })
}

export const identifyLogRocketUser = (data: { id: string; email: string }): void => {
  const { id, email } = data

  if (!isLogRocketEnabled) {
    return
  }

  LogRocket.identify(id, {
    email,
    environment: env.BUILD_MODE,
  })
}

/**
 * Returns the LogRocket session URL synchronously. Useful for when users haven't authenticated yet.
 */
export const getLogRocketUrlSync = (): string | null => LogRocket.sessionURL

/**
 * Fetches the LogRocket session URL. Useful for when we need to guarantee the presence of a LogRocket session URL (e.g. user authenticates, Sentry captures an error, etc.)
 */
export const getLogRocketUrlAsync = (): Promise<string> =>
  new Promise<string>((resolve) => {
    LogRocket.getSessionURL(resolve)
  })
