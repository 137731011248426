type MonthIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

export type MonthYear = {
  type: "mmyyyy" | "mmyy"
  /**
   * type: "yyyy" -> full 4 digits like 2023
   * type: "yy" -> 2 digits max like "23" or just "3" in case of  year 2003
   */
  year: number

  /**
   * month of the year starting from 1 and ending at 12 inclusive
   */
  month: MonthIndex
}

const MM_YY_REGEX = /^\d{2}\/\d{2}$/

export const isValidMonth = (month: number): month is MonthIndex => month >= 1 && month <= 12

/**
 * Parses date in the short form like "05/23"
 * @param dateStr data represented in "mm/yy" format
 */
export function tryParseMMYYDate(dateStr: string): MonthYear | undefined {
  if (!MM_YY_REGEX.test(dateStr)) {
    return undefined
  }

  const [month, year] = dateStr.split("/").map(Number)

  if (!isValidMonth(month)) {
    return undefined
  }

  return { type: "mmyy", month, year }
}

/**
 * Simple check if the "date" in the future relative to "today"
 * @param today
 * @param date
 * @returns true if "date" in the future from "today"
 */
export function isNotExpired(today: Date, date: MonthYear): boolean {
  const todayMonth = today.getMonth()
  const todayYear = today.getFullYear()

  const todayYearStr = todayYear.toString()

  const year =
    date.type === "mmyyyy"
      ? date.year
      : todayYearStr.length === 4
        ? Number(todayYearStr.substring(0, 2) + "00") + date.year
        : 0

  return todayYear < year || (todayYear === year && todayMonth <= date.month)
}

export type MaskResult = { type: "invalid" } | { type: "keep" } | { type: "adjust"; value: string }

// TODO consider making it first class citizen of design system
export type InputMask = {
  (prev: string, next: string): MaskResult
}

const JUST_MONTH = /^\d{1,2}$/
const FILLING_YEAR = /^\d{2}\/\d{0,2}$/

export const dateInputMask: InputMask = (prev, next) => {
  // means we cleared the input
  if (next === "") return { type: "keep" }

  // cannot go beyond "mm/yy"
  if (next.length > 5) {
    return { type: "invalid" }
  }

  const isJustMonth = JUST_MONTH.test(next)
  const hasStartedFillingYear = FILLING_YEAR.test(next)
  if (!isJustMonth && !hasStartedFillingYear) {
    // that means invalid char in the new input
    return { type: "invalid" }
  }

  if (isJustMonth) {
    const month = Number(next)
    // just the first digit filled in, and it cannot be the first digit of a valid MM format
    // for example "2", that means that it needs to become "02"
    if (next.length === 1 && month > 1 && prev.length === 0) {
      return { type: "adjust", value: `0${next}/` }
    }

    if (!isValidMonth(month) && next !== "0") {
      // if it is invalid month date then block the input, for example "56" is not a valid month
      return { type: "invalid" }
      // means that we just added a new char
    } else if (next.length === 2 && prev.length < 2) {
      // means that just finished filling out the month
      return { type: "adjust", value: next + "/" }
    }
  }

  return { type: "keep" }
}

// yeah, I know the type `baseYear: "2000"` is silly, but please be kind to me (Simon)
// I do mean well
/**
 * Converts date to a string representation
 * @param date
 * @param baseYear base year to use in case of "YY" format
 * @param separator separator to use between year and month (use case: YYYY-MM)
 * @returns date formatted as "YYYY\<separator\>MM"
 */
export const formatAsYYYYMM = (date: MonthYear, baseYear: "2000", separator = ""): string =>
  `${date.type === "mmyy" ? Number(baseYear) + date.year : date.year}${separator}${formatAsMM(
    date.month,
  )}`

const formatAsMM = (month: MonthIndex): string => (month < 10 ? `0${month}` : month.toString())

/**
 * Takes in a date string and returns the array-ed form of it.
 * @param date - date string in format mm/dd/yyyy or mm-dd-yyyy
 * @returns
 */
export const formatDateFromStringToArray = (date: string) => {
  let dateParts: number[]
  if (date.includes("-")) {
    // follows the format [yyyy, mm, dd]
    dateParts = date.split("-").map((numberStr) => Number(numberStr))
  } else {
    // follows the format [mm, dd, yyyy]
    const [mm, dd, yyyy] = date.split("/").map((numberStr) => Number(numberStr))
    // reorder to follow [yyyy, mm, dd] format
    dateParts = [yyyy, mm, dd]
  }

  return dateParts
}

export const formatDate = (dateStr: string, opts: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
}) => {
  const date = new Date(dateStr)
  const formatter = new Intl.DateTimeFormat("en-US", opts)
  return formatter.format(date)
}
