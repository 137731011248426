import { FC, MutableRefObject, useEffect } from "react"
import type { FormStatus } from "./useForm"
import {
  Button,
  ButtonProps,
  ButtonState,
  ButtonVariant,
  HStack,
  Txt,
  VStack,
} from "@pomebile/primitives"
import { SubmitStatus } from "../../hooks/useSubmit"
import { match } from "@pomebile/shared/tagged-union"
import { ExclamationIcon } from "@pomebile/design-system"

const formStatusToButtonStateMapping: Record<FormStatus, ButtonState> = {
  done: "active",
  idle: "active",
  submitErr: "active",
  submitting: "loading",
}

export interface FormSubmitButtonProps {
  status: FormStatus
  children: string
  disabled?: boolean
  innerRef?: MutableRefObject<HTMLButtonElement | null>
}

export const FormSubmitButton: FC<FormSubmitButtonProps> = ({
  status,
  children,
  disabled,
  innerRef,
}) => {
  useEffect(() => {
    if (status === "submitErr") {
      // show 🍞
    }
  }, [status])

  const sharedButtonProps: ButtonProps = {
    "web-type": "submit",
    state: (disabled && "disabled") || formStatusToButtonStateMapping[status],
    children,
    innerRef,
  }

  return match(
    { tag: status, data: undefined },
    {
      submitErr: () => (
        <VStack alignItems="center" gap="sm">
          <HStack gap="xs2" justifyContent="center">
            <ExclamationIcon width={14} height={14} fill="icon-error" />
            <Txt as="span" variant="caption" color="text-error">
              Something went wrong. Please try again.
            </Txt>
          </HStack>
          <Button {...sharedButtonProps} />
        </VStack>
      ),
    },
    () => <Button {...sharedButtonProps} />,
  )
}

const submitStatusToButtonStateMapping: Record<SubmitStatus, ButtonState> = {
  done: "active",
  idle: "active",
  submitErr: "active",
  submitting: "loading",
}

export interface SubmitButtonProps {
  status: SubmitStatus
  children: string
  disabled?: boolean
  submit: () => void
  variant?: ButtonVariant
  innerRef?: MutableRefObject<HTMLButtonElement | null>
}

export const SubmitButton: FC<SubmitButtonProps> = ({
  status,
  children,
  disabled,
  variant,
  innerRef,
  submit,
}) => {
  const sharedButtonProps: ButtonProps = {
    state: (disabled && "disabled") || submitStatusToButtonStateMapping[status],
    onClick: submit,
    children,
    variant,
    innerRef,
  }

  return match(
    { tag: status, data: undefined },
    {
      submitting: () => (
        <Button state="loading" onClick={() => {}}>
          {children}
        </Button>
      ),
      idle: () => <Button {...sharedButtonProps} />,
      submitErr: () => (
        <VStack alignItems="center" gap="sm">
          <HStack gap="xs2" justifyContent="center">
            <ExclamationIcon width={14} height={14} fill="icon-error" />
            <Txt as="span" variant="caption" color="text-error">
              Something went wrong. Please try again.
            </Txt>
          </HStack>
          <Button {...sharedButtonProps} />
        </VStack>
      ),
      done: () => <Button {...sharedButtonProps} />,
    },
    () => <Button {...sharedButtonProps} />,
  )
}
