import { isGenericApiError } from "./handleErrorReponse"
import { ResponseStatusError } from "./issueRequest"

export type Tokens = {
  token: string
  refreshToken: string
}

export type AuthData = {
  tokens: Tokens
  readonly userIdent: string
  readonly email: string
}

export type AuthContext = {
  refreshPromise: Promise<void> | undefined
  doRefreshToken: (refreshToken: string) => Promise<Tokens>
  getCachedAuthTokens: () => Tokens | undefined
}

export type AuthHeaders = {
  // format->  Authorization: "Bearer token"
  Authorization: string
  userIdent: string
}

export const parseJwt = (
  jwtToken: string,
): { valid: true; userIdent: string; email: string } | { valid: false; error: "unknown" } => {
  // jwt is base64 encoded like this: "header.claims.signature", we want the claims only
  // https://openid.net/specs/draft-jones-json-web-token-07.html#ExampleJWT

  try {
    const splitClaims = jwtToken.split(".")
    if (splitClaims.length < 2) {
      return { valid: false, error: "unknown" }
    }

    const encodedClaims = splitClaims[1]
    const parsedJwt = JSON.parse(atob(encodedClaims))

    return { valid: true, userIdent: parsedJwt.ident, email: parsedJwt.sub }
  } catch (e) {
    return { valid: false, error: "unknown" }
  }
}

export function withAuth<TRes>(
  issueReq: (headers: AuthHeaders) => Promise<TRes>,
  auth: AuthData,
  ctx: AuthContext,
): Promise<TRes> {
  // WARNING
  // ctx is a designed to be used as a mutable object
  // IT IS NOT SAFE TO DESTRUCTURE IT
  // so please leave code like "ctx.tokens.refreshToken" as is
  // specifically due to the function async nature

  // TODO
  // potentially convert this code to a safer and explicit state machine
  // I (SK) think that the "magic" is probably acceptable due to its small size
  if (ctx.refreshPromise) {
    // means that we are already refreshing the tokens, thus append to the queue
    return ctx.refreshPromise.then(() => issueReq(getHeaders(auth)))
  } else {
    return issueReq(getHeaders(auth)).catch(async (reason: ResponseStatusError | unknown) => {
      if (
        reason instanceof ResponseStatusError &&
        reason.response.status === 401 &&
        isGenericApiError(reason.payload) &&
        reason.payload.cause === "ExpiredJwtException"
      ) {
        const refreshPromise = ctx
          .doRefreshToken(auth.tokens.refreshToken)
          .then((tokens) => {
            ctx.refreshPromise = undefined
            auth.tokens = tokens
          })
          // If refreshing failed we want to surface original 401 error
          .catch(async (_refetchReason) => Promise.reject(reason))

        // we need to assign right away so other requests can be added to the queue
        // with .then on the promise
        ctx.refreshPromise = refreshPromise

        // so once the refresh requests completes
        // reissue the request
        // NOTE that currently it only retries once
        // TODO: maybe replace "issueReq" call with `withAuth` call instead?
        return refreshPromise.then(() => issueReq(getHeaders(auth)))
      } else {
        // in case of not "401" errors we can fail with the same error
        // TODO: double check the callstack
        return Promise.reject(reason)
      }
    })
  }
}

function getHeaders(auth: AuthData): AuthHeaders {
  return { Authorization: `Bearer ${auth.tokens.token}`, userIdent: auth.userIdent }
}
