// type AnyObject = { [key: string]: any }

import { useRef } from "react"
import { useSyncExternalStore } from "../hooks/useExternalSyncStore"

export type FSMInstance<TState, TEv> = {
  getState: () => TState
  send: (ev: TEv) => void
  subscribe: (onChange: () => void) => () => void
}

type CmdOutput<TCommands extends AnyCmdObj, Key extends keyof TCommands> = ReturnType<
  TCommands[Key]
>

type OnComplete<TCommands extends AnyCmdObj, Key extends keyof TCommands, TEv> = CmdOutput<
  TCommands,
  Key
> extends void
  ? () => TEv
  : (data: CmdOutput<TCommands, Key>) => TEv

export type Cmd<TEv, TCommands extends AnyCmdObj, Key extends keyof TCommands> = {
  eff: Key
  arg: TCommands[Key] extends (arg: infer Arg) => any ? Arg : undefined
  onComplete?: OnComplete<TCommands, Key, TEv>
}

export type AnyCmd<TEv, TCommands extends AnyCmdObj> = {
  [K in keyof TCommands]: Cmd<TEv, TCommands, K>
}[keyof TCommands]

type CmdParams<
  TCommands extends AnyCmdObj,
  Key extends keyof TCommands,
  TEv,
> = TCommands[Key] extends () => any
  ? {
      onComplete?: OnComplete<TCommands, Key, TEv>
    }
  : TCommands[Key] extends (arg: infer Arg) => any
  ? {
      arg: Arg
      onComplete?: OnComplete<TCommands, Key, TEv>
    }
  : never

type AnyCmdParam = {
  arg: any
  onComplete?: any
}

export type CmdConstructor<Key extends keyof TCommands, TCommands extends AnyCmdObj> = <
  TEv = never,
>(
  params: CmdParams<TCommands, Key, TEv>,
) => Cmd<TEv, TCommands, Key>

export type Commands<TCommands extends AnyCmdObj> = {
  [K in keyof TCommands]: CmdConstructor<K, TCommands>
}

export type CommandHandlers<TCommands extends AnyCmdObj> = {
  [K in keyof TCommands]: TCommands[K] extends (...args: infer Args) => infer Result
    ? (...args: Args) => Promise<Result>
    : never
}

export function commands<TCommands extends AnyCmdObj>(): Commands<TCommands> {
  return new Proxy(
    {},
    {
      get(_target, eff, _receiver) {
        if (typeof eff !== "string") {
          return null
        } else return ({ onComplete, arg }: AnyCmdParam) => ({ eff, onComplete, arg })
      },
    },
  ) as unknown as Commands<TCommands>
}

type AnyCmdObj = { [key: string]: any }

export type UpdateResult<TState, TEv, TCommands extends AnyCmdObj> = readonly [
  newState: TState,
  cmd?: AnyCmd<TEv, TCommands>,
]

export function startMachine<TCommands extends AnyCmdObj, TState, TEv>(
  update: (prev: TState, ev: TEv) => UpdateResult<TState, TEv, TCommands>,
  init: () => UpdateResult<TState, TEv, TCommands>,
  handlers: CommandHandlers<TCommands>,
  onError: (err: any) => UpdateResult<TState, TEv, TCommands>,
): [
  machine: FSMInstance<TState, TEv>,
  updateHandlers: (newHandlers: CommandHandlers<TCommands>) => void,
] {
  const [initialState, startCmd] = init()
  let state = initialState
  let subscribers: (() => void)[] = []

  handleCommand(startCmd, onError)

  function handleCommand(
    cmd: AnyCmd<TEv, TCommands> | undefined,
    onError: (err: any) => UpdateResult<TState, TEv, TCommands>,
  ) {
    if (cmd) {
      handlers[cmd.eff](cmd.arg)
        .then((res) => {
          if (cmd.onComplete) {
            send(cmd.onComplete(res as any))
          }
        })
        .catch((err) => {
          const [updState, cmd] = onError(err)
          const changed = state !== updState
          state = updState

          if (changed) {
            for (const listener of subscribers) {
              listener()
            }
          }
          handleCommand(cmd, onError)
        })
    }
  }

  function send(ev: TEv) {
    const [updState, cmd] = update(state, ev)
    const changed = state !== updState
    state = updState
    if (changed) {
      for (const listener of subscribers) {
        listener()
      }
    }
    handleCommand(cmd, onError)
  }

  function subscribe(listener: () => void) {
    subscribers.push(listener)
    return () => {
      subscribers = subscribers.filter((l) => listener !== l)
    }
  }

  return [
    {
      send,
      subscribe,
      getState: () => state,
    },
    function updateHandlers(newHandlers) {
      handlers = newHandlers
    },
  ]
}

interface ResultBox<T> {
  v: T
}

export function useConstant<T>(fn: () => T): T {
  const ref = useRef<ResultBox<T>>()

  if (!ref.current) {
    ref.current = { v: fn() }
  }

  return ref.current.v
}

export function useMachine<TCommands extends AnyCmdObj, TState, TEv>(
  update: (prev: TState, ev: TEv) => UpdateResult<TState, TEv, TCommands>,
  init: () => UpdateResult<TState, TEv, TCommands>,
  handlers: CommandHandlers<TCommands>,
  onError: (err: unknown) => UpdateResult<TState, TEv, TCommands>,
): [state: TState, send: (ev: TEv) => void] {
  const [{ getState, send, subscribe }, updateHandlers] = useConstant(() =>
    startMachine(update, init, handlers, onError),
  )

  updateHandlers(handlers)

  const state = useSyncExternalStore(subscribe, getState)

  return [state, send]
}
