import { Txt } from "@pomebile/primitives"

export interface TextFieldLabelProps {
  label: string
  hasError: boolean
}

export const TextFieldLabel = ({ label, hasError }: TextFieldLabelProps) => {
  return (
    <Txt as="label" variant="body2" color={hasError ? "text-error" : "text-default"}>
      {label}
    </Txt>
  )
}
