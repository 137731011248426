import { PromoDto } from "@pomebile/pomelo-service-api"
import { Promo } from "../api/webRoutes"

export const mapUserPromos = (userPromos: PromoDto[]): Promo[] => {
  const promos: (Promo | undefined)[] = userPromos.map((promo) => {
    if (
      !promo.params?.promoLimit ||
      !promo.id ||
      !promo.params?.promoRate ||
      !promo.params?.termsVersion ||
      !promo.campaign?.type ||
      typeof promo.acceptedTerms === "undefined" ||
      typeof promo.campaign?.active === "undefined"
    ) {
      // TODO(LOG_ERROR): log the shape of this promo since these fields are required by FE
      console.error("Invalid promo shape")
      return undefined
    }

    return {
      active: promo.campaign.active,
      limit: promo.params.promoLimit,
      promoIdent: promo.id as string,
      rate: promo.params.promoRate,
      termsVersion: promo.params.termsVersion,
      type: promo.campaign.type,
      acceptedTerms: promo.acceptedTerms,
    }
  })

  return promos.filter((promo): promo is Promo => {
    return typeof promo !== "undefined"
  })
}
