import {
  captureMessage,
  showReportDialog,
  captureException,
  init,
  BrowserTracing,
  Replay,
  SeverityLevel,
  setUser,
  Event,
  EventHint,
} from "@sentry/browser"
import * as env from "../envConstants"
import { ErrorEventData, LoggingContext } from "./logging"
import { ResponseStatusError, NetworkError, FetchError } from "../api/issueRequest"
import { ResponseValidationError, ExpectedResponseError } from "../api/webRoutes"
import { GenericApiError, isGenericApiError } from "../api/handleErrorReponse"
import { getLogRocketUrlSync } from "./logrocket"
import * as Sentry from "@sentry/browser"

const sentryDsn = env.SENTRY_DSN
const isSentryEnabled = env.SENTRY_ENABLED
const isProdMode = import.meta.env.MODE === "production"

export type SentryLogOptions = {
  level?: SeverityLevel
  extra?: Record<string, unknown>
  fingerprint?: string[]
  tags?: {
    isApiError?: boolean
    isNetworkError?: boolean
    isFetchError?: boolean
    capturedByLogging?: boolean
  }
  user?: {
    id: string
  }
}

export function filteredApiError(err: unknown): Partial<GenericApiError> {
  if (isGenericApiError(err)) {
    return err
  }

  // At least capture a error message
  const errWithMessage = err as { message?: unknown }
  if ("message" in errWithMessage && typeof errWithMessage.message === "string") {
    return {
      message: errWithMessage.message,
    }
  }

  // Just in case we accidently returned non-error response with an error status code or something
  return { message: "[Filtering enabled due to unknown error type]" }
}

export function useReportDialogTrigger() {
  return () => {
    // TODO we should show our dialog first and then capture that as message instead
    const eventId = captureMessage("User Feedback")
    showReportDialog({ eventId })
  }
}

function loggingConfigForError(err: unknown): SentryLogOptions | undefined {
  if (err instanceof ResponseValidationError) {
    return {
      level: "error",
      fingerprint: [err.route.url, err.route.method, err.message],
      extra: {
        responseData: isProdMode ? undefined : err.response,
        apiRoute: err.route,
        validationMessage: err.message,
      },
      tags: {
        isApiError: true,
      },
    }
  } else if (err instanceof ResponseStatusError) {
    return {
      level: "error",
      fingerprint: [err.route.url, err.route.method, err.response.status.toFixed()],
      extra: {
        requestData: isProdMode ? undefined : err.request,
        apiRoute: err.route,
        errorPayload: isProdMode ? filteredApiError(err.payload) : err.payload,
      },
      tags: {
        isApiError: true,
      },
    }
  } else if (err instanceof ExpectedResponseError) {
    return {
      level: "warning",
      fingerprint: [
        err.route.url,
        err.route.method,
        err.apiError.status.toString(),
        err.apiError.cause,
      ],
      extra: {
        requestData: isProdMode ? undefined : err.request,
        apiRoute: err.route,
        apiError: isProdMode ? filteredApiError(err.apiError) : err.apiError,
      },
      tags: {
        isApiError: true,
      },
    }
  } else if (err instanceof NetworkError) {
    return {
      level: "error", // TODO consider changing this to a warning if we find its mostly intermittent network connection issues
      fingerprint: [err.baseUrl, err.originalError.message],
      extra: {
        innerError: err.originalError,
        apiRoute: err.route,
      },
      tags: {
        isNetworkError: true,
      },
    }
  } else if (err instanceof FetchError) {
    return {
      level: "error",
      extra: {
        innerError: err.originalError,
        apiRoute: err.route,
      },
      tags: {
        isFetchError: true,
      },
    }
  }

  return undefined
}

export const sentryErrorLogger: LoggingContext = {
  logError: (err: unknown) => {
    logToSentry(err, loggingConfigForError(err))
  },

  logUser: (userId: string) => {
    setUser({ id: userId })
  },

  logScreen: (name: string, _data?: object) => {
    Sentry.addBreadcrumb({
      category: "screen",
      message: `Viewed ${name}\n`,
      level: "info",
    })
  },

  logEvent: (name: string, _data?: object) => {
    Sentry.addBreadcrumb({
      category: "event",
      message: `${name}`,
      level: "info",
    })
  },

  logFormEvent: (_name, event) => {
    if (event.tag === "submissionFailed") {
      logToSentry(event.err, loggingConfigForError(event.err))
    }
  },

  logErrorEvent: (name: string, data?: ErrorEventData) => {
    Sentry.addBreadcrumb({
      category: "event",
      message: `Event ${name}` + (data && data.errorType && `\n${data.errorType} Error`),
      level: "error",
    })
  },
}

export function logToSentry(err: unknown, options?: SentryLogOptions) {
  if (!isSentryEnabled) {
    return
  }

  const { level, extra, fingerprint, tags, user } = options || {}

  captureException(err, {
    level,
    extra,
    fingerprint,
    tags: {
      capturedByLogging: true,
      ...tags,
    },
    user: user,
  })
}

export function initSentry() {
  if (!isSentryEnabled) {
    return
  }

  const beforeSend = (ev: Event, hint: EventHint) => {
    if (ev.tags?.capturedByLogging) {
      // If we already marked this as captured, the logging context is already there
      return ev
    }

    const { originalException } = hint

    if (originalException) {
      const loggingProperties = loggingConfigForError(originalException)
      if (loggingProperties) {
        // Capture some of the helpful debugging properties at least
        const { extra, tags } = loggingProperties
        ev.extra = { ...ev.extra, ...extra }
        ev.tags = {
          ...ev.tags,
          ...tags,
          capturedByLogging: false,
        }
      }
    }

    const logRocketUrl = getLogRocketUrlSync()

    if (logRocketUrl && ev.extra) {
      ev.extra.logRocketUrl = logRocketUrl
    }

    return ev
  }

  init({
    dsn: sentryDsn,
    environment: import.meta.env.MODE,
    integrations: [
      // FYI: This adds ~35kB of minified JS to the bundle (Simon: grrr...)
      new BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["https://.*.pomelo.com/.*"],
      }),
      // FYI: This adds ~150kB of minified JS to the bundle (Simon: grrr...)
      new Replay({
        maskAllText: false,

        networkRequestHeaders: ["UserId", "anonymousid"],
        networkResponseHeaders: ["X-correlation-id", ""],
      }),
    ],
    tracesSampleRate: isProdMode ? 0.1 : 1.0, // Capture 100% of the transactions except in production!

    // TODO consider changing prod config after soft launch
    replaysSessionSampleRate: 1.0, // 1.0 means all replays are captured, 0.1 means 10% of replays are captured
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

    beforeSend, // Overriding this will help us append logging info to errors that we missed capturing
  })
}
