import { Avatar, Secure, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { sprinkles } from "@pomebile/primitives-web"
import { HourglassIcon } from "@pomebile/design-system"

export type TimeLimitErrorScreenProps = {
  email?: string
}

export function TimeLimitErrorScreen({ email }: TimeLimitErrorScreenProps) {
  return (
    <ScreenFrame>
      <VStack height="full" alignItems="center" justifyContent="center" gap="xs">
        <VStack alignItems="center" justifyContent="center">
          <VStack justifyContent="center" alignItems="center" padding="md" width="unset">
            <Avatar size="lg" svg={<HourglassIcon width={34} height={34} fill="icon-emphasis" />} />
          </VStack>
          <Txt variant="headline2" as="h1" textAlign="center">
            Sorry, this is taking longer than expected.
          </Txt>
        </VStack>
        {/* Todo: for when we have no email, we need a more generic error */}
        {email ? (
          <Secure>
            <Txt as="p" variant="body1" textAlign="center">
              We will send an email to{" "}
              <span className={sprinkles({ wordBreak: "break-all" })}>{email}</span> when we've
              successfully processed your info.
            </Txt>
          </Secure>
        ) : (
          <Txt textAlign="center">Please check back later for an update.</Txt>
        )}
      </VStack>
    </ScreenFrame>
  )
}
