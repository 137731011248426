import { Svg, Path } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const AlertIcon = ({ width = 24, height = 24, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" customFill="none">
      <Path
        d="M11.25 13C11.25 13.4142 11.5858 13.75 12 13.75C12.4142 13.75 12.75 13.4142 12.75 13L12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7L11.25 13Z"
        fill={fill}
      />
      <Path
        d="M12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15Z"
        fill={fill}
      />
    </Svg>
  )
}
