import { CheckboxComponent } from "@pomebile/primitives"
import { checkboxStyles } from "./Checkbox.css"

export const Checkbox: CheckboxComponent = ({
  /* insert params here */
  checked,
  disabled,
  onChange,
  id,
}) => {
  return (
    <input
      id={id}
      type="checkbox"
      className={checkboxStyles}
      onChange={(e) => onChange?.(e.target.checked)}
      checked={checked}
      disabled={disabled}
    />
  )
}
