import { Avatar, HStack, Txt, VStack } from "@pomebile/primitives"
import { StickyBottom } from "../components/StickyBottom"
import { useSubmit } from "../hooks/useSubmit"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { ScreenForm } from "../components/ScreenForm"

export interface NrauInvitationIntroProps {
  api: {
    acceptInvite: () => void
  }
  onDone: () => void
}

export const NrauInvitationIntroScreen = ({ api, onDone }: NrauInvitationIntroProps) => {
  const [submit, status] = useSubmit(async () => {
    /* TODO: Replace with actual functions here.  */
    api.acceptInvite()
    onDone()
  })

  return (
    <ScreenForm onSubmit={submit}>
      <VStack height="full" justifyContent="space-between">
        <VStack height="full" gap="xl2" padding={{ bottom: "lg" }}>
          <VStack padding={{ bottom: "xl3" }}>
            <Txt variant="headline2">You've been invited to join your loved one on Pomelo</Txt>
            <Txt variant="body1">
              Get your own Pomelo Mastercard® to spend in stores and online anywhere Mastercard®
              is accepted. Here’s how to get started:
            </Txt>
          </VStack>

          <VStack gap="xl">
            <HStack alignItems="center" gap="md">
              <Avatar size="lg" text={<Txt color="text-brand">1</Txt>} />
              <VStack>
                <Txt variant="subtitle2">Set up your account</Txt>
                <Txt>Enter your name, phone number, email and home address.</Txt>
              </VStack>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar size="lg" text={<Txt color="text-brand">2</Txt>} />
              <VStack>
                <Txt variant="subtitle2">Verify your identity</Txt>
                <Txt>
                  Take a photo of your Government ID and a selfie to protect you and your family.
                </Txt>
              </VStack>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar size="lg" text={<Txt color="text-brand">3</Txt>} />
              <VStack>
                <Txt variant="subtitle2">Get your Pomelo Mastercard®</Txt>
                <Txt>
                  Choose your card style. Your loved one will set your monthly spending limit, so
                  you can spend and they pay off the balance.
                </Txt>
              </VStack>
            </HStack>
          </VStack>
        </VStack>

        <StickyBottom>
          <VStack gap="sm">
            <FormSubmitButton status={status}>Accept Invite</FormSubmitButton>
          </VStack>
        </StickyBottom>
      </VStack>
    </ScreenForm>
  )
}
