import { Link } from "@pomebile/primitives-web"
import { CardColors, PomeloCardColorValues, pomeloCardColors } from "@pomebile/primitives/tokens"

export type CardColorSectorProps = {
  cardColor?: CardColors
  isSelected?: Boolean
  onSelect?: () => void
}

const whiteColors: CardColors[] = ["none", "white"]
const cardColorMapping: Record<CardColors, PomeloCardColorValues> = {
  pink: pomeloCardColors.pink,
  green: pomeloCardColors.green,
  none: pomeloCardColors.none,
  white: pomeloCardColors.white,
}

export function CardColorSelector({
  cardColor = "pink",
  onSelect,
  isSelected = false,
}: CardColorSectorProps) {
  const cardColorKey = cardColorMapping[cardColor]

  const toggleSelected = () => {
    onSelect && onSelect()
  }

  return (
    <Link onClick={toggleSelected}>
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Grey border to show white circle */}
        {whiteColors.includes(cardColor) && (
          <circle cx="18" cy="18.1307" r="13.5" fill="white" stroke="#D2D2D2" strokeWidth={3} />
        )}
        {/* The actual filled in circle */}
        <circle cx="18" cy="18.1307" r="14" fill={cardColorKey} />
        {isSelected && !whiteColors.includes(cardColor) && (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.5303 14.6004C23.8232 14.8933 23.8232 15.3682 23.5303 15.6611L16.8839 22.3075C16.3957 22.7957 15.6043 22.7957 15.1161 22.3075L12.4697 19.6611C12.1768 19.3682 12.1768 18.8933 12.4697 18.6004C12.7626 18.3075 13.2374 18.3075 13.5303 18.6004L16 21.0701L22.4697 14.6004C22.7626 14.3075 23.2374 14.3075 23.5303 14.6004Z"
              fill="white"
            />
            <circle cx="18" cy="18.1307" r="17.5" stroke={cardColorKey} />
          </>
        )}

        {isSelected && whiteColors.includes(cardColor) && (
          <>
            {/* Special case for white circle */}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.5303 14.6004C23.8232 14.8933 23.8232 15.3682 23.5303 15.6611L16.8839 22.3075C16.3957 22.7957 15.6043 22.7957 15.1161 22.3075L12.4697 19.6611C12.1768 19.3682 12.1768 18.8933 12.4697 18.6004C12.7626 18.3075 13.2374 18.3075 13.5303 18.6004L16 21.0701L22.4697 14.6004C22.7626 14.3075 23.2374 14.3075 23.5303 14.6004Z"
              fill="#333333"
            />
            <circle cx="18" cy="18.1307" r="17.5" stroke="#333333" />
          </>
        )}
      </svg>
    </Link>
  )
}
