interface DeepLink {
  qrFileName: string
  link: string
}

export const deeplink = {
  CardActivationRPCDownloadLink: {
    qrFileName: "card-activation-rpc-app-download-qr-code.svg",
    link: "https://getpomelo.app/diXcWQHfSGb",
  },
  CardActivationNRAUDownloadLink: {
    qrFileName: "card-activation-nrau-app-download-qr-code.svg",
    link: "https://getpomelo.app/Yv1Hx2IfSGb",
  },
  OnboardingV2DownloadLink: {
    qrFileName: "onboarding-v2-app-download-qr-code.svg",
    link: "https://getpomelo.app/vqFXhqthUCb",
  },
  EmailValidationDownloadLink: {
    qrFileName: "email-validation-app-download-qr-code.svg",
    link: "https://getpomelo.app/X8DtI3xiQJb",
  },
  DeviceValidationLink: {
    qrFileName: "",
    link: "https://getpomelo.app/1v1Hx2IfSGb",
  },
} satisfies Record<string, DeepLink>

export type DeepLinkName = keyof typeof deeplink
