import { Path, Svg } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const ActivityIcon = ({ width = 24, height = 24, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" customFill="none">
      <Path
        d="M26 17H6C5.46957 17 4.96086 17.2107 4.58579 17.5858C4.21071 17.9609 4 18.4696 4 19V24C4 24.5304 4.21071 25.0391 4.58579 25.4142C4.96086 25.7893 5.46957 26 6 26H26C26.5304 26 27.0391 25.7893 27.4142 25.4142C27.7893 25.0391 28 24.5304 28 24V19C28 18.4696 27.7893 17.9609 27.4142 17.5858C27.0391 17.2107 26.5304 17 26 17ZM26 24H6V19H26V24ZM26 6H6C5.46957 6 4.96086 6.21071 4.58579 6.58579C4.21071 6.96086 4 7.46957 4 8V13C4 13.5304 4.21071 14.0391 4.58579 14.4142C4.96086 14.7893 5.46957 15 6 15H26C26.5304 15 27.0391 14.7893 27.4142 14.4142C27.7893 14.0391 28 13.5304 28 13V8C28 7.46957 27.7893 6.96086 27.4142 6.58579C27.0391 6.21071 26.5304 6 26 6ZM26 13H6V8H26V13Z"
        fill={fill}
      />
    </Svg>
  )
}
