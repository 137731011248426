import { createContext, useContext } from "react"
import { SecureComponent, SecureContextHook } from "@pomebile/primitives"

const SecureContext = createContext<{ secure: boolean }>({ secure: false })

export const useSecureContext: SecureContextHook = () => {
  return useContext(SecureContext)
}

export const LogRocketRedactProps = { "data-private": true }

export const Secure: SecureComponent = ({ children }) => {
  return <SecureContext.Provider value={{ secure: true }}>{children}</SecureContext.Provider>
}

export const SecureBlock: SecureComponent = ({ children }) => {
  return <div {...LogRocketRedactProps}>{children}</div>
}
