import {
  MonthYear,
  dateInputMask,
  exhaustive,
  isNotExpired,
  tryParseMMYYDate,
} from "@pomebile/shared/helpers"
import { TextField } from "@pomebile/design-system"
import { HStack, Txt, VStack } from "@pomebile/primitives"
import { CardColors } from "@pomebile/primitives/tokens"
import { ScreenForm } from "../components/ScreenForm"
import { StickyBottom } from "../components/StickyBottom"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import * as Yup from "yup"
import * as V from "../utils/formValidation/validationMessages"
import { CardPreview } from "../components/CardPreview/CardPreview"
import { useForm } from "../components/Form/useForm"
import { CardActivatedDialog } from "./CardActivatedDialog"
import { useState } from "react"

export const ActivateCardSchema = Yup.object().shape({
  expirationDate: Yup.string()
    .test("is-valid-expiration-date", V.MSG_EXPIRATION_DATE, (value) => {
      if (!value) {
        // Let next validation handle this
        return true
      }

      const monthYear = tryParseMMYYDate(value)
      if (!monthYear) {
        return false
      }

      return isNotExpired(new Date(), monthYear)
    })
    .required(V.MSG_REQUIRED),

  cvcNumber: Yup.string().max(3, V.MSG_CVC).min(3, V.MSG_CVC).required(V.MSG_REQUIRED),
})

export interface ActivateCardProps {
  api: {
    activateCard: (cvcNumberVgsToken: string, expirationDate: MonthYear) => Promise<void>
  }
  cardColor: CardColors
  productType: "unsecured" | "secured"
  onDone: () => void
}

export function ActivateCard({ api, cardColor, productType, onDone }: ActivateCardProps) {
  const [isDialogOpen, setDialogOpen] = useState(false)

  const [submit, getFieldProps, status] = useForm({
    name: "Income", // Note: Must match 1.0 name for analytics
    schema: ActivateCardSchema,
    initial: { expirationDate: "", cvcNumber: "" },
    submit: async ({ expirationDate, cvcNumber }) => {
      const monthYear = tryParseMMYYDate(expirationDate)

      if (!monthYear) {
        return {
          status: "validationErr",
          err: {
            errorMessage: V.MSG_EXPIRATION_DATE,
            field: "expirationDate",
          },
        }
      }

      await api.activateCard(cvcNumber, monthYear)
      setDialogOpen(true)
      return undefined
    },
  })

  const {
    value,
    onChange: onExpDateChange,
    ...restOfExpirationDate
  } = getFieldProps("expirationDate")
  const { onChange: onCvcNumberChange, ...restOfCvcNumber } = getFieldProps("cvcNumber")

  const handleExpirationDateChange = (newExpDate: string) => {
    const maskResult = dateInputMask(value, newExpDate)
    switch (maskResult.type) {
      case "adjust": {
        onExpDateChange(maskResult.value)
        break
      }
      case "keep": {
        onExpDateChange(newExpDate)
        break
      }
      case "invalid": {
        // just don't propagate invalid input
        break
      }
      default:
        exhaustive(maskResult)
    }
  }

  const handleCvcNumberChange = (value: string) => {
    if (value.length <= 3 && !isNaN(Number(value))) {
      onCvcNumberChange(value)
    }
  }

  const handleDialogContinue = () => {
    setDialogOpen(false)
    onDone()
  }

  return (
    <ScreenForm onSubmit={submit}>
      <VStack>
        <VStack gap="xl2">
          <Txt textAlign="center" variant="headline2" as="h2">
            Card Activation
          </Txt>
          <HStack alignItems="center" justifyContent="center">
            <CardPreview cardColor={cardColor} />
          </HStack>
        </VStack>

        <VStack gap="xs">
          <HStack gap="lg">
            <TextField
              label="Expiration date"
              type="text"
              placeholder="MM/YY"
              {...restOfExpirationDate}
              value={value}
              onChange={handleExpirationDateChange}
            />
            <TextField
              label="CVC"
              type="number"
              placeholder="000"
              {...getFieldProps("cvcNumber")}
              onChange={handleCvcNumberChange}
            />
          </HStack>
        </VStack>
      </VStack>
      <StickyBottom>
        <FormSubmitButton status={status}>Activate</FormSubmitButton>
      </StickyBottom>

      <CardActivatedDialog
        productType={productType}
        isOpen={isDialogOpen}
        onContinue={handleDialogContinue}
      />
    </ScreenForm>
  )
}
