import { useEffect } from "react"
import { Tokens } from "../api/authContext"
import { ScreenFrame } from "../components/ScreenFrame"
import { Txt, VStack } from "@pomebile/primitives"

export interface ReturningUserLoaderProps {
  api: {
    refreshUserFromTokens: (tokens: Tokens) => Promise<void>
  }
  persistedTokens: Tokens
}

export const ReturningUserLoader = ({
  api: { refreshUserFromTokens },
  persistedTokens,
}: ReturningUserLoaderProps) => {
  useEffect(() => {
    refreshUserFromTokens(persistedTokens)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ScreenFrame>
      <VStack alignItems="center" justifyContent="center" height="full">
        <img src="images/pomelo_spinner_gray.gif" alt="Loading Spinner" width={60} height={60} />
      </VStack>
    </ScreenFrame>
  )
}
