import {
  matchDeferred,
  matchDeferredTuple,
  taggedUnion,
  Variants,
} from "@pomebile/shared/tagged-union"
import { RewardVoucher } from "./api/webRoutes"

interface Screens {
  FetchingRewardVoucher: {
    rewardUniqueId: string
  }
  ClaimRewardScreen: {
    senderName: string
    description: string
    voucherCode: string
    expirationDate: Date
  }
  GeneralError: void
}

export const AppScreen = taggedUnion<Screens>()
export type AppScreen = Variants<Screens>

interface AppStates {
  FetchingRewardVoucher: {
    rewardUniqueId: string
  }
  ClaimingReward: {
    rewardVoucher: RewardVoucher
  }
  GeneralError: void
}

interface AppEvents {
  FetchedRewardVoucher: {
    rewardVoucher: RewardVoucher
  }
  ClaimedReward: void
  EncounteredGeneralError: { errorType: string }
}

export const AppState = taggedUnion<AppStates>()
export type AppState = Variants<AppStates>

export const AppEvent = taggedUnion<AppEvents>()
export type AppEvent = Variants<AppEvents>

export const calculateRewardScreen: (state: AppState) => AppScreen = matchDeferred(AppState, {
  FetchingRewardVoucher: (prev) => AppScreen.FetchingRewardVoucher({ ...prev }),
  ClaimingReward: ({ rewardVoucher }) =>
    AppScreen.ClaimRewardScreen({
      senderName: rewardVoucher.senderName,
      description: rewardVoucher.description,
      voucherCode: rewardVoucher.voucherCode,
      expirationDate: rewardVoucher.expirationDate,
    }),
  GeneralError: () => AppScreen.GeneralError(),
})

const { ClaimingReward, GeneralError } = AppState

export const updateRewardAppState = matchDeferredTuple(AppState, AppEvent, {
  FetchingRewardVoucher: {
    FetchedRewardVoucher: (_, { rewardVoucher }) => {
      return ClaimingReward({ rewardVoucher })
    },
    EncounteredGeneralError: () => GeneralError(),
  },
  default: (prev) => prev,
})
