import { IconProps } from "@pomebile/primitives"
import { Svg, Path } from "../Svg/Svg"

export const CaratDownIcon = ({ height = 7, width = 12 }: IconProps) => (
  <Svg width={width} height={height} viewBox="0 0 12 7">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5303 0.46967C11.8232 0.762563 11.8232 1.23744 11.5303 1.53033L6.88388 6.17678C6.39573 6.66493 5.60427 6.66493 5.11612 6.17678L0.46967 1.53033C0.176777 1.23744 0.176777 0.762563 0.46967 0.46967C0.762564 0.176777 1.23744 0.176777 1.53033 0.46967L6 4.93934L10.4697 0.46967C10.7626 0.176777 11.2374 0.176777 11.5303 0.46967Z"
      customFill="#1D1D1D"
    />
  </Svg>
)
