import * as Yup from "yup"
import * as V from "./validationMessages"

/* TODO: Straight copy from verify identity for now, to be updated if/when we need changes */
const mmddyyyyDatePattern = /^\d{2}\/\d{2}\/\d{4}$/
const yyyymmddDatePattern = /^\d{4}-\d{2}-\d{2}$/

interface DobValidationParams {
  minAge: number
}
// Regexp breaks down as follows:
// start of string + any number of letters, hyphens, apostrophes, or spaces + end of string
export const regexForName = /^[a-zA-Z\-\'\s]+$/

export const validateDateOfBirth = ({ minAge }: DobValidationParams) =>
  Yup.string()
    .test("is-valid-date", V.MSG_DATE, (value) => {
      let month: number
      let day: number
      let year: number

      if (!value) return false

      if (mmddyyyyDatePattern.test(value)) {
        const [mm, dd, yyyy] = value.split("/").map(Number)
        month = mm
        day = dd
        year = yyyy
      } else if (yyyymmddDatePattern.test(value)) {
        const [yyyy, mm, dd] = value.split("-").map(Number)
        month = mm
        day = dd
        year = yyyy
      } else {
        return false
      }
      const outOfRange = month < 1 || month > 12 || day < 1 || day > 31
      const inputDate = new Date(year, month - 1, day)

      return (
        !outOfRange &&
        // Takes leap years into account
        inputDate.getMonth() === month - 1 &&
        inputDate.getDate() === day &&
        inputDate.getFullYear() === year
      )
    })
    .test("is-minimum-age", V.MSG_MIN_AGE(minAge), (value) => {
      if (!value) return false

      const mmddyyyyDate = mmddyyyyDatePattern.test(value)
      const yyyymmddDate = yyyymmddDatePattern.test(value)
      if (!(mmddyyyyDate || yyyymmddDate)) {
        return true // Let other tests validate the format
      }

      let month: number
      let day: number
      let year: number

      if (mmddyyyyDate) {
        const [mm, dd, yyyy] = value.split("/").map(Number)
        month = mm
        day = dd
        year = yyyy
      } else {
        const [yyyy, mm, dd] = value.split("-").map(Number)
        month = mm
        day = dd
        year = yyyy
      }

      const inputDate = new Date(year, month - 1, day)

      const currentDate = new Date()
      const date18YearsAgo = new Date(
        currentDate.getFullYear() - minAge,
        currentDate.getMonth(),
        currentDate.getDate(),
      )

      return inputDate <= date18YearsAgo
    })
    .required(V.MSG_REQUIRED)
