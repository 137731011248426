import { IconProps, Path, Svg } from "@pomebile/primitives"

export const SuccessCheckIcon = ({ width = 15, height = 15 }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 15 15" customFill="none">
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#00B198" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="#F2F2F2" />
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.55241 9.98753L4.59116 8.02589C4.51248 7.94721 4.40577 7.90301 4.29451 7.90301C4.18325 7.90301 4.07655 7.94721 3.99787 8.02588C3.9192 8.10455 3.875 8.21126 3.875 8.32252C3.875 8.43378 3.9192 8.54048 3.99787 8.61916L6.25573 10.877C6.29467 10.916 6.34094 10.9469 6.39183 10.968C6.44273 10.9891 6.4973 11 6.55241 11C6.60751 11 6.66208 10.9891 6.71299 10.968C6.76387 10.9469 6.81011 10.916 6.84904 10.8771L12.01 5.71615C12.0886 5.63748 12.1328 5.53077 12.1328 5.41951C12.1328 5.30825 12.0886 5.20154 12.01 5.12287C11.9313 5.0442 11.8246 5 11.7133 5C11.6021 5 11.4953 5.0442 11.4167 5.12287L6.55241 9.98753Z"
        fill="icon-inverse"
      />
    </Svg>
  )
}
