import { FC, PropsWithChildren, useEffect, useState } from "react"
import { screenContentStyle, screenShellStyle, transformMap } from "./ScreenTransitionShell.css.ts"

export type ScreenStatus = "current" | "past" | "initial"
type Transition = "left" | "center" | "right"

export const ScreenTransitionShell: FC<PropsWithChildren<{ status: ScreenStatus }>> = ({
  children,
  status,
}) => {
  const [rendered, setIsRendered] = useState(false)

  useEffect(() => {
    // to triple check that React yields to the browser
    setTimeout(() => setIsRendered(true), 5)
  }, [])

  const transitionCss: string = transformMap[calcTransitionState(rendered, status)]

  return (
    <div className={`${screenShellStyle} ${transitionCss}`}>
      <div className={screenContentStyle}>{children}</div>
    </div>
  )
}

function calcTransitionState(renderedOnce: boolean, status: ScreenStatus): Transition {
  switch (status) {
    case "past":
      return "left"
    case "initial":
      return "center"
    case "current":
      return renderedOnce ? "center" : "right"
  }
}
