import { HStack, Txt } from "@pomebile/primitives"
import { imageStyles } from "./AppDownloadQRForDesktop.css"
import { onlyShowOnDesktopStyles } from "../sharedStyles.css"
import { deeplink } from "../utils/deeplink"

interface AppDownloadQRForDesktopProps {
  type: keyof typeof deeplink
}

/**
 * Only appears on large screens.
 */
export function AppDownloadQRForDesktop({ type }: AppDownloadQRForDesktopProps) {
  return (
    <div className={onlyShowOnDesktopStyles}>
      <HStack
        border={["thin", "border-main"]}
        borderRadius="md"
        padding="xl"
        alignItems="center"
        gap="xl"
      >
        <img className={imageStyles} src={`images/${deeplink[type].qrFileName}`} />
        <Txt variant="subtitle2">
          Scan the QR code with your mobile phone to download the Pomelo app.
        </Txt>
      </HStack>
    </div>
  )
}
