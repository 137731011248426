import { Button, HStack, Link, Txt, VStack } from "@pomebile/primitives"
import { Dialog } from "../../components/Dialog"
import { CrossIcon } from "@pomebile/design-system"
import { DialogHeader } from "../../components/Dialog/DialogHeader"

export interface DeclineDialogProps {
  isOpen: boolean
  onClose: () => void
  onDecline: () => void
}

export const DeclineDialog = ({ isOpen, onClose, onDecline }: DeclineDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogHeader>
        <HStack gap="xs" alignItems="center">
          <Txt variant="subtitle2">We’re sorry to see you go</Txt>
        </HStack>
        <HStack width="auto" justifyContent="flex-end" alignItems="center">
          <Link onClick={onClose}>
            {/* Link adds unecessary height so we start at flex end */}
            <VStack justifyContent="flex-end">
              <CrossIcon width={24} height={24} />
            </VStack>
          </Link>
        </HStack>
      </DialogHeader>
      <VStack gap="xl" padding="xl" justifyContent="space-between" height="full">
        <Txt variant="body1" color="text-default">
          Your offer will expire in 30 days. If you change your mind during that time, you can sign
          in using your phone number to view and accept the offer.
        </Txt>
        <Button variant="filled" onClick={onDecline}>
          Sign Out
        </Button>
      </VStack>
    </Dialog>
  )
}
