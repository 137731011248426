import { AuthExistingUser, AuthTempToken, Country } from "./api/webRoutes"
import {
  taggedUnion,
  matchDeferred,
  Variants,
  matchDeferredTuple,
} from "@pomebile/shared/tagged-union"
import { CardColors } from "@pomebile/primitives/tokens"
import { AuthData } from "./api/authContext"

export type ScreenKind = keyof Screens

interface ActivateCardState {
  cardIdent: string
  selectedCardColor: CardColors
  productType: "unsecured" | "secured"
  auth: AuthData
}

interface Screens {
  Phone: void
  OTP: { country: Country; phoneNumber: string }
  ActivateCard: ActivateCardState
  DownloadApp: void
  AccountNotFound: { country: Country; phoneNumber: string }
  GeneralError: void
}

export const AppScreen = taggedUnion<Screens>()
export type AppScreen = Variants<Screens>

interface AppStates {
  Initial: {
    country: Country | undefined
    phoneNumber: string | undefined
    activateCardState: ActivateCardState | undefined
    cardActivated: boolean
  }

  AccountNotFound: {
    country: Country
    phoneNumber: string
  }
  GeneralError: void
}

interface AppEvents {
  PhoneNumberSubmitted: {
    country: Country
    phoneNumber: string
  }

  AuthCompleted: {
    authResult: AuthTempToken | AuthExistingUser
  }

  CardActivated: void

  // All errors should have an errorType
  // errorType will be exposed in Sentry breadcrumbs (don't include PII)
  EncounteredGeneralError: { errorType: string }
}

export const AppState = taggedUnion<AppStates>()
export type AppState = Variants<AppStates>

export const AppEvent = taggedUnion<AppEvents>()
export type AppEvent = Variants<AppEvents>

export const calculateScreen: (state: AppState) => AppScreen = matchDeferred(AppState, {
  Initial: ({ country, phoneNumber, activateCardState, cardActivated }) => {
    if (!country || !phoneNumber) {
      return AppScreen.Phone()
    }

    if (!activateCardState) {
      return AppScreen.OTP({ country, phoneNumber })
    }

    if (!cardActivated) {
      return AppScreen.ActivateCard(activateCardState)
    }

    return AppScreen.DownloadApp()
  },
  AccountNotFound: ({ country, phoneNumber }) =>
    AppScreen.AccountNotFound({ country, phoneNumber }),
  GeneralError: () => AppScreen.GeneralError(),
})

const { Initial, AccountNotFound, GeneralError } = AppState

export const updateAppState = matchDeferredTuple(AppState, AppEvent, {
  Initial: {
    PhoneNumberSubmitted: (prev, { country, phoneNumber }) =>
      Initial({ ...prev, country, phoneNumber }),
    EncounteredGeneralError: () => {
      return GeneralError()
    },
    AuthCompleted: (prev, { authResult }) => {
      if (!prev.phoneNumber || !prev.country) {
        return Initial(prev)
      }
      if (authResult.tag === "newUser" || authResult.user.accounts.length === 0) {
        return AccountNotFound({ country: prev.country, phoneNumber: prev.phoneNumber })
      }

      const { user, token, refreshToken } = authResult

      const auth: AuthData = {
        tokens: { token, refreshToken },
        email: user.personalInfo.email,
        userIdent: user.ident,
      }

      const cardIdent = user.accounts[0].activeCard?.ident
      const cardColorByConfigId: { [key: string]: CardColors } = {
        pom_green: "green",
        pom_pink: "pink",
        pom_white: "white",
      } as const
      const cardConfigId = user.accounts[0].cardConfiguration?.configId
      const selectedCardColor = cardConfigId ? cardColorByConfigId[cardConfigId] : undefined
      const productType =
        user.approvedCreditAppDto.productType === "CHARGE_CARD_SECURED" ? "secured" : "unsecured"

      if (!cardIdent || !selectedCardColor) {
        // The user has no selected card yet
        return AccountNotFound({ country: prev.country, phoneNumber: prev.phoneNumber })
      }

      return Initial({
        ...prev,
        activateCardState: {
          cardIdent: cardIdent,
          selectedCardColor,
          productType,
          auth,
        },
      })
    },
    CardActivated: (prev) => Initial({ ...prev, cardActivated: true }),
  },

  default: (prev) => prev,
})
