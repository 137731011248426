import { Avatar, HStack, Link, Txt, VStack } from "@pomebile/primitives"
import { sprinkles } from "@pomebile/primitives-web"
import { StickyBottom } from "../../components/StickyBottom"
import {
  Callout,
  ChevronIcon,
  PHFlagIcon,
  PHFlagRectIcon,
  PomeloCardIcon,
} from "@pomebile/design-system"
import { DeclineDialog } from "./DeclineDialog"
import { useReducer } from "react"
import { RatesAndFeesDialogComponent } from "./RatesAndFeesDialog"
import { TermsAndConditionsDialog } from "../../components/TermsAndConditionsDialog"
import { CardConfiguration, UserResponse } from "@pomebile/pomelo-service-api"
import { SubmitButton } from "../../components/Form/FormSubmitButton"
import { CardColors } from "@pomebile/primitives/tokens"
import { NewAccountResponse, Promo } from "../../api/webRoutes"
import { useSubmit } from "../../hooks/useSubmit"
import { ScreenFrame } from "../../components/ScreenFrame"
import { DeclineReasonDialog } from "../DeclineReasonDialog"

interface ItemProps {
  icon: JSX.Element
  title: string
  subtitle: string
  bottomElement?: JSX.Element
}

type CardVariants = "pink" | "green" | "white"
export type Card = {
  cardConfigId: string
  color: CardColors
}

const DEFAULT_CARD_OPTIONS: Readonly<Record<CardVariants, Card>> = {
  pink: {
    cardConfigId: "pom_pink",
    color: "pink",
  },
  green: {
    cardConfigId: "pom_green",
    color: "green",
  },
  white: {
    cardConfigId: "pom_white",
    color: "white",
  },
}

type AcceptResult =
  | {
      tag: "accepted"
      productGroupIdent: string
      cards: Card[]
      updatedPromos: Promo[]
    }
  | {
      tag: "declined"
    }
  | {
      tag: "noProductGroupIdent"
    }

export interface UnsecuredOfferProps {
  api: {
    openAccount: () => Promise<NewAccountResponse>
    fetchCardConfigs: () => Promise<CardConfiguration[]>
    declineReason: (reason: string) => Promise<UserResponse>
  }
  promos: Promo[]
  onDone: (result: AcceptResult) => void
}

type DialogKind =
  | "declineDialog"
  | "ratesAndFeesDialog"
  | "termsAndConditionsDialog"
  | "declineReasonDialog"
type Ev = {
  action: "close" | "open"
  dialog: DialogKind
}
type DialogState = {
  dialog: DialogKind | undefined // undefined expresses that no dialog is opened
  prevDialog: DialogKind | undefined
}

const updateDialogState = (prevState: DialogState, ev: Ev): DialogState => {
  const { action, dialog } = ev

  if (action === "open") {
    return { prevDialog: prevState.dialog, dialog }
  }

  if (prevState.dialog !== undefined && action === "close" && dialog === prevState.dialog) {
    if (dialog === "termsAndConditionsDialog") {
      return { ...prevState, dialog: prevState.prevDialog }
    }

    return { ...prevState, dialog: undefined }
  }

  return prevState
}

export const UnsecuredOffer = ({
  api: { fetchCardConfigs, openAccount, declineReason },
  promos,
  onDone,
}: UnsecuredOfferProps) => {
  const [{ dialog }, send] = useReducer(updateDialogState, {
    dialog: undefined,
    prevDialog: undefined,
  })
  const [submit, status] = useSubmit(async () => {
    try {
      const { productGroupIdent, updatedPromos } = await openAccount()

      const cardConfigs = await fetchCardConfigs() // This request depends on the above request to be successful
      const userCardConfigIds = cardConfigs.map((config) => config.configId)
      // Filter out possible card color options based on the card configurations response we get from backend
      const filteredCardOptions = Object.values(DEFAULT_CARD_OPTIONS).filter((card) =>
        userCardConfigIds.includes(card.cardConfigId),
      )
      const cards: Card[] = filteredCardOptions.length
        ? filteredCardOptions
        : Object.values(DEFAULT_CARD_OPTIONS)

      onDone({
        tag: "accepted",
        cards,
        productGroupIdent,
        updatedPromos,
      })
    } catch (ResponseValidationError) {
      onDone({
        tag: "noProductGroupIdent",
      })
    }
  })

  const openDialog = (dialog: DialogKind) => {
    send({ action: "open", dialog })
  }

  const closeDialog = (dialog: DialogKind) => {
    send({ action: "close", dialog })
  }

  const ratesAndFeesLink = (
    <Link as="div" onClick={() => openDialog("ratesAndFeesDialog")}>
      <HStack justifyContent="center" alignItems="center">
        <Txt variant="button2" color="text-brand">
          View Rates and Fees
        </Txt>
        <ChevronIcon fill="icon-brand" direction="right" />
      </HStack>
    </Link>
  )

  const items: ItemProps[] = []

  const moneyTransferPromoRate = promos.find((promo) => promo.type === "MTP_RATE" && promo.active)
  if (moneyTransferPromoRate) {
    items.push(
      {
        icon: <PHFlagIcon width={24} height={24} />,
        title: "Send money to the Philippines",
        subtitle: "Take advantage of zero transfer fees when you send to GCash or Bank Accounts.",
        bottomElement: (
          <VStack width="full">
            <Callout variant="success">
              <VStack alignItems="center">
                <HStack alignItems="center" width="unset">
                  <Txt variant="subtitle2" color="text-default">
                    $1 =&nbsp;
                  </Txt>
                  <div className={sprinkles({ overflow: "hidden", borderRadius: "xs" })}>
                    <PHFlagRectIcon width={16} height={12} />
                  </div>
                  <Txt color="text-default" variant="subtitle2">
                    &nbsp;₱{moneyTransferPromoRate.rate}
                  </Txt>
                </HStack>
                <Txt color="text-default" variant="caption">
                  For your first transfer up to ${moneyTransferPromoRate.limit}
                </Txt>
                <Link
                  color="neutral"
                  decoration="underline"
                  onClick={() => openDialog("termsAndConditionsDialog")}
                >
                  <Txt color="text-default" variant="caption">
                    See Terms and Conditions
                  </Txt>
                </Link>
              </VStack>
            </Callout>
          </VStack>
        ),
      },
      {
        icon: <PomeloCardIcon />,
        title: "Pay with Pomelo Mastercard®",
        subtitle:
          "Pay for money transfers later with zero interest, and make purchases anywhere Mastercard® is accepted.",
        bottomElement: ratesAndFeesLink,
      },
    )
  }

  return (
    <ScreenFrame>
      <VStack justifyContent="space-between">
        <VStack height="full" gap="xl3" padding={{ bottom: "xl" }}>
          <VStack>
            <Txt variant="subtitle2" color="text-emphasis" textAlign="center">
              You're Approved
            </Txt>
            <Txt variant="headline2" textAlign="center">
              Pay Later Plan
            </Txt>
            <Txt variant="body2" textAlign="center">
              Send money to your loved ones in the Philippines and pay later with Pomelo
              Mastercard®
            </Txt>
          </VStack>

          <HStack>
            <VStack gap="xs2" alignItems="center">
              <Txt variant="caption" color="text-caption">
                Credit Limit
              </Txt>
              <Txt variant="headline3" color="text-default">
                $1,000
              </Txt>
            </VStack>
            <VStack gap="xs2" alignItems="center">
              <Txt variant="caption" color="text-caption">
                APR
              </Txt>
              <Txt variant="headline3" color="text-default">
                None
              </Txt>
            </VStack>
            <VStack gap="xs2" alignItems="center">
              <Txt variant="caption" color="text-caption">
                Annual Fees
              </Txt>
              <Txt variant="headline3" color="text-default">
                None
              </Txt>
            </VStack>
          </HStack>

          <VStack gap="lg">
            {items.map(({ icon, title, subtitle, bottomElement }, index) => (
              <VStack
                key={index}
                padding="lg"
                alignItems="center"
                gap="sm"
                borderRadius="xs"
                border={{
                  color: "border-main",
                  style: "solid",
                  widths: {
                    top: "thin",
                    right: "thin",
                    bottom: "thin",
                    left: "thin",
                  },
                }}
              >
                <Avatar size="lg" svg={icon} />
                <VStack gap="xs2">
                  <Txt variant="subtitle2" color="text-default" textAlign="center">
                    {title}
                  </Txt>
                  <Txt variant="body2" color="text-default" textAlign="center">
                    {subtitle}
                  </Txt>
                </VStack>
                {bottomElement}
              </VStack>
            ))}
            <Txt variant="body2" color="text-caption" textAlign="center">
              By accepting the offer you authorize Pomelo to do a full credit inquiry which may
              affect your credit score.
            </Txt>
          </VStack>
        </VStack>

        <StickyBottom>
          <VStack gap="sm">
            <SubmitButton submit={submit} status={status}>
              Accept Plan
            </SubmitButton>
            <Link onClick={status === "idle" ? () => openDialog("declineReasonDialog") : undefined}>
              <Txt textAlign="center" color="text-brand" as="p">
                Decline
              </Txt>
            </Link>
          </VStack>
        </StickyBottom>

        <DeclineReasonDialog
          isOpen={dialog === "declineReasonDialog"}
          onClose={() => closeDialog("declineReasonDialog")}
          onDecline={() => openDialog("declineDialog")}
          api={declineReason}
        />

        <DeclineDialog
          isOpen={dialog === "declineDialog"}
          onClose={() => closeDialog("declineDialog")}
          onDecline={() => {
            onDone({ tag: "declined" })
          }}
        />
        <RatesAndFeesDialogComponent
          isOpen={dialog === "ratesAndFeesDialog"}
          onClose={() => closeDialog("ratesAndFeesDialog")}
          onOpenTermsAndConditions={() => openDialog("termsAndConditionsDialog")}
        />

        <TermsAndConditionsDialog
          open={dialog === "termsAndConditionsDialog"}
          showMoneyTransferPromo={!!moneyTransferPromoRate}
          onClose={() => closeDialog("termsAndConditionsDialog")}
        />
      </VStack>
    </ScreenFrame>
  )
}
