import { ArrowIcon, BankIcon, SuccessCheckIcon } from "@pomebile/design-system"
import { HStack, Link, Txt, VStack } from "@pomebile/primitives"
import { FC } from "react"

interface Props {
  isComplete: boolean
  openConfirmationDialog: () => void
}

export const LinkBankCallout: FC<Props> = ({ isComplete, openConfirmationDialog }) => {
  if (isComplete) {
    return (
      <HStack
        alignItems="center"
        fill="background-avatar-default"
        borderRadius="md"
        padding={{ x: "lg", y: "md" }}
        gap="sm"
      >
        <VStack justifyContent="center" alignItems="center" flex={1}>
          <div style={{ position: "relative" }}>
            <BankIcon width={24} height={24} fill="icon-emphasis" />
            <div style={{ position: "absolute", top: 6, left: 12 }}>
              <SuccessCheckIcon width={12} height={12} />
            </div>
          </div>
        </VStack>
        <VStack>
          <Txt variant="body2">Program enrollment complete</Txt>
        </VStack>
      </HStack>
    )
  }
  return (
    <Link onClick={openConfirmationDialog}>
      <HStack
        alignItems="center"
        fill="background-avatar-default"
        borderRadius="md"
        padding={{ x: "lg", y: "md" }}
        gap="sm"
      >
        <VStack justifyContent="center" flex={1}>
          <BankIcon width={24} height={24} fill="icon-emphasis" />
        </VStack>
        <VStack>
          <Txt variant="body2">
            Link your bank account to help us determine your future eligibility for a credit
            upgrade.
          </Txt>
        </VStack>
        <VStack justifyContent="center" flex={1}>
          <ArrowIcon width={24} height={24} fill="icon-emphasis" />
        </VStack>
      </HStack>
    </Link>
  )
}
