import {
  DEVICE_INTEL_API_KEY,
  DEVICE_INTEL_DEBUG_MODE,
  DEVICE_INTEL_ENABLED,
} from "../envConstants"

// API Specs https://www.notion.so/pomelons/Effectiv-Monitoring-API-specs-f73735b29df845129cd97e751e1a2854
interface EffectivDeviceRequest {
  customer_id: string
  api_key: string
  user_consent: boolean
  debug?: boolean
}

interface EffectivDeviceResponse {
  request_id: string
  device_id: string
  session_id: string
  success: boolean
}

type EffectivClientResponse =
  | {
      ok: true
      effectivSessionId: string
    }
  | {
      ok: false
      type?: "InvalidEffectivSessionId"
    }

interface EffectivDevice {
  execute: (req: EffectivDeviceRequest) => Promise<EffectivDeviceResponse>
}

export async function initializeEffectivSession(): Promise<EffectivClientResponse> {
  if (!DEVICE_INTEL_ENABLED) return { ok: false }

  const Effectiv = (window as any).EffectivDevice as EffectivDevice

  console.log("Initializing Effectiv session...")

  const response = await Effectiv.execute({
    customer_id: "pomelo",
    api_key: DEVICE_INTEL_API_KEY,
    user_consent: true, // if we want to support gdpr compliance, we might need to gather consent instead of hardcoding to true
    debug: DEVICE_INTEL_DEBUG_MODE,
  })

  if (response.success && response.session_id) {
    return { ok: true, effectivSessionId: response.session_id }
  }

  return { ok: false, type: "InvalidEffectivSessionId" }
}
