import { FC, createContext, useContext } from "react"

export type FormLoggingEvent =
  | {
      tag: "submitted"
    }
  | {
      tag: "submissionRejected"
      reason: string
    }
  | {
      tag: "validationRejected"
      reasons: object
    }
  | {
      tag: "submissionFailed"
      err: unknown
    }

export type ErrorEventData = { errorType: string }

export type LoggingContext = {
  logUser: (userId: string, traits?: { logRocketUrl: string }) => void
  logError: (err: unknown) => void
  logScreen: (screenName: string, data?: object) => void
  logEvent: (eventName: string, data?: object) => void
  logFormEvent: (formName: string, event: FormLoggingEvent) => void
  logErrorEvent: (eventName: string, data: ErrorEventData) => void
}

// default console logging implementation
export const devLogging: LoggingContext = {
  logUser: function (userId: string): void {
    console.log("User: ", userId)
  },

  logError: function (err: unknown): void {
    console.error("Error: ", err)
  },

  logScreen: function (screenName: string, data?: object): void {
    console.log("Screen Viewed: ", screenName, data)
  },

  logEvent: function (eventName: string, data?: object): void {
    console.log("Event: ", eventName, data)
  },

  logFormEvent: function (formName: string, event): void {
    console.log(`Form ${formName}: ${event}`, formName, event)
  },

  logErrorEvent: function (eventName: string, data?: ErrorEventData): void {
    console.warn("Error event: ", eventName, data)
  },
}

const LoggingCtx = createContext<LoggingContext>(devLogging)

export const LoggingProvider: FC<{ children?: React.ReactNode; logger: LoggingContext }> = ({
  children,
  logger,
}) => <LoggingCtx.Provider value={logger}>{children}</LoggingCtx.Provider>

export function useLogging(): LoggingContext {
  return useContext(LoggingCtx)
}
