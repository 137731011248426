import { Avatar, AvatarColorVariants, Button, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { CrossIcon, EnvelopeIcon } from "@pomebile/design-system"
import { useEffect, useState } from "react"
// import { StickyBottom } from "../components/StickyBottom"
// import { deeplink } from "../utils/deeplink"
import { screenShellStyle } from "./EmailValidation.css"
import { screenContentStyle } from "./ScreenTransitionShell.css"
import { emptyHeaderStyle } from "../AppShell.css"
import { issueRequest } from "../api/issueRequest"
import {
  EmailConfirmationRequest,
  EmailConfirmationResponse,
  EmailConfirmationResponseStatusEnum,
  attemptValidation,
} from "@pomebile/pomelo-service-api"
// import { AppDownloadQRForDesktop } from "../components/AppDownloadQRForDesktop"
// import { onlyShowOnMobileStyles } from "../sharedStyles.css"
import { createLoggingContext } from "../sharedShellLogic"
import { ScreenHeader } from "../components/ScreenHeader/ScreenHeader"
import { IntercomState } from "../utils/intercom"

type EmailValidationStateType = "loading" | "verified" | "invalid" | "supportRequired"
type EmailValidationResultType = Exclude<EmailValidationStateType, "loading">

export type EmailValidationProps = {
  jwt: string
  baseUrl: string
}

export function EmailValidationScreen({ jwt, baseUrl }: EmailValidationProps) {
  const { logEvent, logError } = createLoggingContext()
  const [emailValidationState, setEmailValidationState] =
    useState<EmailValidationStateType>("loading")

  useEffect(() => {
    setEmailValidationState("loading")

    const validateEmail = async ({ jwt }: { jwt: string }): Promise<EmailConfirmationResponse> => {
      const data: Required<EmailConfirmationRequest> = { token: jwt }
      const headers: { [header: string]: string } = {}
      headers["client-type"] = "WEB-ONBOARDING"
      const res = await issueRequest(
        attemptValidation,
        { req: data },
        {
          baseUrl,
          headers: headers,
        },
      )
      return res
    }

    const changeEmailState = async (jwt: string) => {
      logEvent("Entered Email Validation Flow")
      try {
        const res = await validateEmail({ jwt })
        if (res?.status) {
          switch (res.status) {
            case EmailConfirmationResponseStatusEnum.Success:
              setEmailValidationState("verified")
              logEvent("Email Validation Result: Verified")
              break
            case EmailConfirmationResponseStatusEnum.CodeMismatch:
            case EmailConfirmationResponseStatusEnum.TokenExpired:
              setEmailValidationState("invalid")
              logEvent("Email Validation Result: Invalid")
              break
            case EmailConfirmationResponseStatusEnum.AlreadyValidated:
            default:
              setEmailValidationState("supportRequired")
              logEvent("Email Validation Result: Support Required")
              break
          }
        }
      } catch (error) {
        setEmailValidationState("supportRequired")
        logError("Email Validation Flow Error")
      }
    }

    changeEmailState(jwt)
    // eslint-disable-next-line
  }, [])

  const icon: {
    [key in EmailValidationResultType]: { variant: AvatarColorVariants; svg: JSX.Element }
  } = {
    verified: {
      variant: "success",
      svg: <EnvelopeIcon width={34} height={34} fill="icon-success" />,
    },
    invalid: {
      variant: "error",
      svg: <CrossIcon width={34} height={34} fill="icon-error" />,
    },
    supportRequired: {
      variant: "error",
      svg: <CrossIcon width={34} height={34} fill="icon-error" />,
    },
  }

  const header: {
    [key in EmailValidationResultType]: string
  } = {
    verified: "Email verified!",
    invalid: "Invalid link",
    supportRequired: "We couldn't verify your email",
  }

  const copy: {
    [key in EmailValidationResultType]: string
  } = {
    verified: "Head back to the Pomelo app to continue.",
    invalid:
      "The link to verify your email is either invalid or expired. Please continue to the Pomelo app to try again.",
    supportRequired: "",
  }

  // const handleDownloadPress = () => {
  //   window.open(deeplink.EmailValidationDownloadLink.link, "_blank")
  // }

  const handleSupportPress = () => {
    window.open("https://help.pomelo.com/", "_blank")
  }

  return (
    <>
      <div className={emptyHeaderStyle} />
      <div className={screenShellStyle}>
        <div className={screenContentStyle}>
          <ScreenHeader activeSection="none" intercomState={IntercomState.Idle()} />
          <ScreenFrame>
            <VStack justifyContent="space-between" height="full" padding={{ top: "sm" }}>
              {emailValidationState !== "loading" && (
                <VStack gap="xl3" justifyContent="center" height="full">
                  <VStack gap="xl" alignItems="center" justifyContent="center">
                    <Avatar
                      size="lg"
                      variant={icon[emailValidationState]?.variant}
                      svg={icon[emailValidationState]?.svg}
                    />
                    <VStack gap="xs">
                      <Txt variant="headline2" as="h1" textAlign="center">
                        {header[emailValidationState]}
                      </Txt>
                      {emailValidationState !== "supportRequired" && (
                        <Txt variant="body1" textAlign="center">
                          {copy[emailValidationState]}
                        </Txt>
                      )}
                    </VStack>
                    {emailValidationState === "supportRequired" && (
                      <Button web-type="button" variant="text" onClick={handleSupportPress}>
                        Contact support
                      </Button>
                    )}
                  </VStack>
                  {/* {["verified", "invalid"].includes(emailValidationState) && (
                    <AppDownloadQRForDesktop type="OnboardingV2DownloadLink" />
                  )} */}
                </VStack>
              )}
              {/* {["verified", "invalid"].includes(emailValidationState) && (
                <div className={onlyShowOnMobileStyles}>
                  <StickyBottom>
                    <Button onClick={handleDownloadPress}>Continue to Pomelo app</Button>
                  </StickyBottom>
                </div>
              )} */}
            </VStack>
          </ScreenFrame>
        </div>
      </div>
    </>
  )
}
