/* eslint-disable react/react-in-jsx-scope */
import { DropdownMenu, DropdownMenuProps } from "@pomebile/primitives"
import { InputField, InputFieldProps } from "./InputField"

export interface DropdownMenuFieldProps
  extends Omit<InputFieldProps, "inputComponent">,
    DropdownMenuProps {}

export const DropdownMenuField = ({
  label,
  value,
  options,
  disabled,
  placeholder,
  onChange,
  supportingText,
  errorText,
  infoText,
  infoTextVariant,
  borderStyle,
  onBlur,
}: DropdownMenuFieldProps) => {
  return (
    <InputField
      inputComponent={
        <DropdownMenu
          value={value}
          disabled={disabled}
          options={options}
          placeholder={placeholder}
          onChange={onChange}
          borderStyle={borderStyle}
          onBlur={onBlur}
        />
      }
      label={label}
      supportingText={supportingText}
      errorText={errorText}
      infoText={infoText}
      infoTextVariant={infoTextVariant}
      borderStyle={borderStyle}
    />
  )
}
