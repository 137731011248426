// Global resets etc
import "./globalStyles.css"

import { initSentry } from "./utils/sentry.ts"
import { IntercomProvider } from "react-use-intercom"
import * as env from "./envConstants.ts"
import { generateUUID } from "./utils/uuid.ts"
import { AppShell } from "./sharedShellLogic.tsx"
import * as rpcUI from "./RPCOnboarding.tsx"
import * as rpcModel from "./onboardingModel.ts"
import * as nrauUI from "./NRAUOnboarding.tsx"
import * as nrauModel from "./nrauOnboardingModel.ts"
import * as cardActivationUI from "./CardActivation.tsx"
import * as cardActivationModel from "./cardActivationModel.ts"
import * as recipientVerificationModel from "./recipientVerificationModel.ts"
import * as recipientVerificationUI from "./RecipientVerification.tsx"
import * as rewardModel from "./rewardModel.ts"
import * as rewardUI from "./Reward.tsx"
import { getLogRocketUrlSync, initLogRocket } from "./utils/logrocket.ts"
import { Country, isSupportedCountry } from "./api/webRoutes.tsx"
import { createRoot } from "react-dom/client"
import { EmailValidationScreen } from "./screens/EmailValidation.tsx"
import { DeviceValidation } from "./screens/DeviceValidation.tsx"
import { Tokens } from "./api/authContext.ts"

// Initialize LogRocket as early as possible otherwise, some recording data may be lost if it is called too late.
initLogRocket()

initSentry()

const searchParams = new URLSearchParams(document.location.search)
// eslint-disable-next-line prefer-const
let phoneNumber: string = searchParams.get("phone") || ""
const inviteCode: string = searchParams.get("invite") || ""
const countryParam: string = searchParams.get("country") || ""
const accountType: string = searchParams.get("type") || ""
const referralCode: string | undefined = searchParams.get("referral") || undefined

// Use PH as a default, since that is the primary use-case here.
const country: Country = isSupportedCountry(countryParam) ? countryParam : "PH"
const isDevToolsEnabled = searchParams.get("debug") === "1" // || isDevBuild || isStagingSite

const recipientVerificationAttemptId = searchParams.get("uniqueId") ?? undefined
const rewardUniqueId = searchParams.get("uniqueId") ?? undefined

// validation
const emailJwtLink: string | undefined = searchParams.get("email_token") || undefined
const isEmailValidation = emailJwtLink !== undefined
const deviceJwtLink: string | undefined = searchParams.get("device_token") || undefined
const isDeviceValidation = deviceJwtLink !== undefined

const baseUrl = env.BASE_API_URL
const vgsUrl = env.VGS_PROXY_API_URL
const deviceIdent = generateUUID()

function getLogRocketUrl() {
  return env.LOGROCKET_ENABLED ? getLogRocketUrlSync() : null
}

function CardActivationAppShell() {
  return (
    <AppShell<
      cardActivationModel.AppState,
      cardActivationModel.AppEvent,
      cardActivationModel.AppScreen
    >
      isDevToolsEnabled={isDevToolsEnabled}
      init={() =>
        cardActivationUI.initCardActivation(
          {
            deviceIdent,
            baseUrl,
            vgsUrl,
            phoneNumber,
            country,
            inviteCode,
            accountType: accountType === "sender" ? "RPC" : "NRAU",
            getLogRocketUrl,
          },
          isDevToolsEnabled,
        )
      }
      getAuth={cardActivationUI.getAuth}
      getError={cardActivationUI.getError}
      updateState={cardActivationModel.updateAppState}
      calculateScreen={cardActivationModel.calculateScreen}
      renderScreen={cardActivationUI.renderScreen}
      calculateProgression={cardActivationUI.calculateProgression}
    />
  )
}

function NRAUOnboardingAppShell() {
  return (
    <AppShell<nrauModel.NrauAppState, nrauModel.NrauAppEvent, nrauModel.NrauAppScreen>
      isDevToolsEnabled={isDevToolsEnabled}
      init={() =>
        nrauUI.initNRAUOnboarding(
          {
            deviceIdent,
            baseUrl,
            vgsUrl,
            phoneNumber,
            country,
            inviteCode,
            accountType: "NRAU",
            getLogRocketUrl,
          },
          isDevToolsEnabled,
        )
      }
      getAuth={nrauUI.getNRAUAuth}
      getError={nrauUI.getNRAUError}
      updateState={nrauModel.updateNrauAppState}
      calculateScreen={nrauModel.calculateNrauScreen}
      renderScreen={nrauUI.renderNrauScreen}
      calculateProgression={nrauUI.calculateNrauProgression}
    />
  )
}

function RPCOnboardingAppShell() {
  return (
    <AppShell<rpcModel.AppState, rpcModel.AppEvent, rpcModel.AppScreen>
      isDevToolsEnabled={isDevToolsEnabled}
      init={() =>
        rpcUI.initRPCOnboarding(
          {
            deviceIdent,
            baseUrl,
            vgsUrl,
            phoneNumber,
            country,
            accountType: "RPC",
            getLogRocketUrl,
            referralCode,
          },
          isDevToolsEnabled,
        )
      }
      getAuth={rpcUI.getRPCAuth}
      getError={rpcUI.getRPCError}
      updateState={rpcModel.updateRPCAppState}
      calculateScreen={rpcModel.calculateRPCScreen}
      renderScreen={rpcUI.renderRPCScreen}
      calculateProgression={rpcUI.calculateRPCProgression}
      sessionPersistenceEnabled
    />
  )
}

function RecipientVerificationAppShell() {
  return (
    <AppShell<
      recipientVerificationModel.AppState,
      recipientVerificationModel.AppEvent,
      recipientVerificationModel.AppScreen
    >
      isDevToolsEnabled={isDevToolsEnabled}
      init={() =>
        recipientVerificationUI.initRecipientVerification(
          {
            deviceIdent,
            baseUrl,
            vgsUrl,
            country,
            accountType: "NRAU", // TODO: Verify if new account type is needed for BT
            recipientVerificationAttemptId,
            getLogRocketUrl,
          },
          isDevToolsEnabled,
        )
      }
      getAuth={() => undefined}
      getError={() => undefined}
      updateState={recipientVerificationModel.updateRecipientVerificationAppState}
      calculateScreen={recipientVerificationModel.calculateRecipientVerificationScreen}
      renderScreen={recipientVerificationUI.renderScreen}
      calculateProgression={recipientVerificationUI.calculateProgression}
    />
  )
}

function RewardsAppShell() {
  return (
    <AppShell<rewardModel.AppState, rewardModel.AppEvent, rewardModel.AppScreen>
      isDevToolsEnabled={isDevToolsEnabled}
      init={() =>
        rewardUI.initReward(
          {
            deviceIdent,
            baseUrl,
            vgsUrl,
            country,
            accountType: "NRAU", // Hard coding for now since it's required
            rewardUniqueId,
            getLogRocketUrl,
          },
          isDevToolsEnabled,
        )
      }
      getAuth={() => undefined}
      getError={() => undefined}
      updateState={rewardModel.updateRewardAppState}
      calculateScreen={rewardModel.calculateRewardScreen}
      renderScreen={rewardUI.renderScreen}
      calculateProgression={recipientVerificationUI.calculateProgression}
    />
  )
}

const container = document.getElementById("root") as HTMLElement
const root = createRoot(container)

root.render(
  <IntercomProvider appId={env.INTERCOM_APP_ID}>
    {env.APP_BUILD === "card-activation" ? (
      <CardActivationAppShell />
    ) : inviteCode ? (
      // todo dynamically import bundles for NRAU or AppShell (or change to ENV variables + different deployments)
      <NRAUOnboardingAppShell />
    ) : isEmailValidation && emailJwtLink ? (
      <EmailValidationScreen jwt={emailJwtLink} baseUrl={baseUrl} />
    ) : isDeviceValidation && deviceJwtLink ? (
      <DeviceValidation jwt={deviceJwtLink} baseUrl={baseUrl} />
    ) : env.APP_BUILD === "recipient-verification" ? (
      <RecipientVerificationAppShell />
    ) : env.APP_BUILD === "reward" ? (
      <RewardsAppShell />
    ) : (
      <RPCOnboardingAppShell />
    )}
  </IntercomProvider>,
)
