import { extractDigits } from "./string"

export const formatPhoneNumber = (phoneNumber: string, separator = " ") => {
  const phoneDigits = extractDigits(phoneNumber)
  const match = phoneDigits.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/)

  if (match) {
    return [match[1], match[2], match[3]].filter(Boolean).join(separator)
  }
  return phoneNumber
}
