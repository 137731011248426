import { Dialog } from "@headlessui/react"
import { PHFlagRectIcon } from "@pomebile/design-system"
import { HStack, Txt, VStack } from "@pomebile/primitives"
import { Link, sprinkles } from "@pomebile/primitives-web"
import { FC, useState } from "react"
import { TermsAndConditionsDialog } from "./TermsAndConditionsDialog"

interface FxPromoBannerProps {
  caption: string
  promoRate: number
}

// This should probably be promoted to a shared space at some point
export const FxPromoBanner: FC<FxPromoBannerProps> = ({ caption, promoRate }) => {
  const [isTermsOpen, setIsTermsOpen] = useState<boolean>(false)

  const toggleTermsAndConditions = () => {
    setIsTermsOpen((isTermsOpen) => !isTermsOpen)
  }
  return (
    <VStack
      gap="xs"
      fill="background-default"
      borderRadius="md"
      padding={{ x: "xl", y: "lg" }}
      alignItems="center"
      border={{
        widths: { top: "thin", bottom: "thin", left: "thin", right: "thin" },
        color: "border-main",
      }}
    >
      <Txt as="caption" variant="overline2">
        new customer offer
      </Txt>
      <HStack justifyContent="center" alignItems="center">
        <Txt as="h2" variant="headline3">
          $1 =&nbsp;
        </Txt>
        <div className={sprinkles({ overflow: "hidden", borderRadius: "xs" })}>
          <PHFlagRectIcon width={23} height={16} viewBox="0 0 23 16" />
        </div>
        <Txt as="h2" variant="headline3">
          &nbsp;₱{promoRate}
        </Txt>
      </HStack>
      <Txt variant="caption" textAlign="center">
        {caption}
      </Txt>
      <Txt as="caption" variant="caption" color="text-caption">
        <Link color="neutral" decoration="underline" onClick={() => toggleTermsAndConditions()}>
          See Terms and Conditions
        </Link>
      </Txt>
      <Dialog open={isTermsOpen} onClose={toggleTermsAndConditions}>
        <TermsAndConditionsDialog
          open={isTermsOpen}
          showMoneyTransferPromo={false}
          onClose={toggleTermsAndConditions}
        />
      </Dialog>
    </VStack>
  )
}
