import { Svg, Path, G } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

type ArrowIconProps = IconProps & {
  direction?: "left" | "right" | "down" | "up"
}

export const ArrowIcon = ({
  height = 24,
  width = 24,
  fill,
  direction = "right",
}: ArrowIconProps) => {
  let rotation
  switch (direction) {
    case "left":
      rotation = "180"
      break
    case "right":
      rotation = "0"
      break
    case "up":
      rotation = "-90"
      break
    case "down":
    default:
      rotation = "90"
      break
  }
  return (
    <Svg width={width} height={height} customFill="none">
      <G transform={`rotate(${rotation}, ${width / 2}, ${width / 2})`}>
        <Path
          d="M13.5303 5.46967C13.2374 5.17678 12.7626 5.17678 12.4697 5.46967C12.1768 5.76256 12.1768 6.23744 12.4697 6.53033L17.1893 11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H17.1893L12.4697 17.4697C12.1768 17.7626 12.1768 18.2374 12.4697 18.5303C12.7626 18.8232 13.2374 18.8232 13.5303 18.5303L19.1768 12.8839C19.6649 12.3957 19.6649 11.6043 19.1768 11.1161L13.5303 5.46967Z"
          fill={fill}
        />
      </G>
    </Svg>
  )
}
