import { useState } from "react"
import {
  textInputStyles,
  nativeTextStyle,
  textInputBorderStyle,
  disabledTextFieldStyles,
  enabledTextFieldStyles,
  multilineInputStyles,
} from "./TextInput.css"
import { type TextInputBorderStyle, type TextInputComponent } from "@pomebile/primitives"
import { LogRocketRedactProps, useSecureContext } from "../Secure/Secure"

export const TextInput: TextInputComponent = ({
  value,
  type,
  disabled,
  focused: focusedOverride,
  readonly = false,
  placeholder,
  inputAdornment,
  inputAdornmentEnd,
  borderStyle,
  autoCapitalize,
  maxLength,
  onChange,
  onFocus,
  onBlur,
  multiline,
  numberOfLines,
}) => {
  const [focused, setFocused] = useState(false)

  const secureContext = useSecureContext()
  const logRocketProps = secureContext?.secure ? LogRocketRedactProps : {}

  const disabledStyles: string = disabled ? disabledTextFieldStyles : enabledTextFieldStyles

  return (
    <div
      className={`
      ${textInputStyles}
      ${selectBorderStyle(borderStyle, focusedOverride ?? focused)}
      ${disabledStyles}
      `}
    >
      {multiline ? (
        <textarea
          rows={numberOfLines}
          className={`
            ${multilineInputStyles}
            ${nativeTextStyle}
          `}
          readOnly={readonly}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={onChange && ((e) => onChange(e.target.value))}
          onFocus={(event) => {
            setFocused(true)
            onFocus?.(event)
          }}
          onBlur={(_event) => {
            setFocused(false)
            onBlur?.()
          }}
          autoCapitalize={autoCapitalize}
          maxLength={maxLength}
          {...logRocketProps}
        />
      ) : (
        <>
          {inputAdornment && <span>{inputAdornment}</span>}
          <input
            readOnly={readonly}
            disabled={disabled}
            className={`${nativeTextStyle} ${disabledStyles}`}
            value={value}
            type={type}
            placeholder={placeholder}
            onChange={onChange && ((e) => onChange(e.target.value))}
            onFocus={(event) => {
              setFocused(true)
              onFocus?.(event)
            }}
            onBlur={(_event) => {
              setFocused(false)
              onBlur?.()
            }}
            autoCapitalize={autoCapitalize}
            maxLength={maxLength}
            {...logRocketProps}
          />
          {inputAdornmentEnd && <span>{inputAdornmentEnd}</span>}
        </>
      )}
    </div>
  )
}

const selectBorderStyle = (
  borderStyle: TextInputBorderStyle | "normalFocused" = "normal",
  isFocused: boolean,
): string =>
  isFocused && borderStyle === "normal"
    ? textInputBorderStyle.normalFocused
    : textInputBorderStyle[borderStyle]
