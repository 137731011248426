import { Svg, Path } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const BankIcon = ({ width = 24, height = 24, fill = "icon-default" }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" customFill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4481 3.45567C11.7869 3.25237 12.2102 3.25236 12.5491 3.45567L19.3938 7.56249C20.3244 8.12084 19.9285 9.55001 18.8433 9.55001H5.15388C4.06866 9.55001 3.67281 8.12083 4.60337 7.56249L11.4481 3.45567ZM11.9986 4.87465L6.70632 8.05001H17.2908L11.9986 4.87465Z"
        fill={fill}
      />
      <Path
        d="M11.9985 11.25C12.4127 11.25 12.7485 11.5858 12.7485 12V16.8C12.7485 17.2142 12.4127 17.55 11.9985 17.55C11.5843 17.55 11.2485 17.2142 11.2485 16.8V12C11.2485 11.5858 11.5843 11.25 11.9985 11.25Z"
        fill={fill}
      />
      <Path
        d="M17.5488 12C17.5488 11.5858 17.213 11.25 16.7988 11.25C16.3846 11.25 16.0488 11.5858 16.0488 12V16.8C16.0488 17.2142 16.3846 17.55 16.7988 17.55C17.213 17.55 17.5488 17.2142 17.5488 16.8V12Z"
        fill={fill}
      />
      <Path
        d="M7.19824 11.25C7.61246 11.25 7.94824 11.5858 7.94824 12V16.8C7.94824 17.2142 7.61246 17.55 7.19824 17.55C6.78403 17.55 6.44824 17.2142 6.44824 16.8V12C6.44824 11.5858 6.78403 11.25 7.19824 11.25Z"
        fill={fill}
      />
      <Path
        d="M18.3979 20.75C18.8122 20.75 19.1479 20.4142 19.1479 20C19.1479 19.5858 18.8122 19.25 18.3979 19.25H5.59795C5.18373 19.25 4.84795 19.5858 4.84795 20C4.84795 20.4142 5.18373 20.75 5.59795 20.75H18.3979Z"
        fill={fill}
      />
    </Svg>
  )
}

export const BankIconCallout = () => {
  return (
    <Svg width={45} height={45} viewBox="0 0 45 44">
      <Path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4917 6.33546C22.1129 5.96273 22.889 5.96272 23.5102 6.33546L36.0589 13.8646C37.7649 14.8883 37.0392 17.5084 35.0496 17.5084H9.95237C7.9628 17.5084 7.23706 14.8883 8.9431 13.8646L21.4917 6.33546ZM22.501 8.93692L12.7985 14.7584H32.2035L22.501 8.93692Z"
        fill="icon-inverse"
      />
      <Path
        d="M22.5009 20.6251C23.2603 20.6251 23.8759 21.2407 23.8759 22.0001V30.8001C23.8759 31.5595 23.2603 32.1751 22.5009 32.1751C21.7415 32.1751 21.1259 31.5595 21.1259 30.8001V22.0001C21.1259 21.2407 21.7415 20.6251 22.5009 20.6251Z"
        fill="icon-inverse"
      />
      <Path
        d="M32.6764 22.0001C32.6764 21.2407 32.0608 20.6251 31.3014 20.6251C30.542 20.6251 29.9264 21.2407 29.9264 22.0001V30.8001C29.9264 31.5595 30.542 32.1751 31.3014 32.1751C32.0608 32.1751 32.6764 31.5595 32.6764 30.8001V22.0001Z"
        fill="icon-inverse"
      />
      <Path
        d="M13.7004 20.6251C14.4597 20.6251 15.0754 21.2407 15.0754 22.0001V30.8001C15.0754 31.5595 14.4597 32.1751 13.7004 32.1751C12.941 32.1751 12.3254 31.5595 12.3254 30.8001V22.0001C12.3254 21.2407 12.941 20.6251 13.7004 20.6251Z"
        fill="icon-inverse"
      />
      <Path
        d="M34.2332 38.0417C34.9925 38.0417 35.6082 37.4261 35.6082 36.6667C35.6082 35.9073 34.9925 35.2917 34.2332 35.2917H10.7665C10.0071 35.2917 9.39149 35.9073 9.39149 36.6667C9.39149 37.4261 10.0071 38.0417 10.7665 38.0417H34.2332Z"
        fill="icon-inverse"
      />
    </Svg>
  )
}
