/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  PlaidAccessRequest,
  PlaidAccessResponse,
  PlaidCheckLinkResponse,
  PlaidCheckLinkTokenRequest,
  PlaidCreateUpdateLinkTokenRequest,
  PlaidCreateUpdateLinkTokenResponse,
  PlaidLinkRequest,
  PlaidLinkResponse,
  PlaidRefreshItemLoginRequired,
  PlaidResetRequest,
  PlaidSessionStatusResponse,
  PlaidSessionStatusUpdate,
  StatusBooleanResponse,
} from "../models"

export const createUpdateLinkToken = {
  url: "/v1/createUpdateLinkToken",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: PlaidCreateUpdateLinkTokenRequest
    res: PlaidCreateUpdateLinkTokenResponse
  },
} as const

export const getLinkToken = {
  url: "/v1/getLinkToken",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: PlaidLinkRequest
    res: PlaidLinkResponse
  },
} as const

export const getPlaidCheckLinkToken = {
  url: "/v2/plaidCheckLinkToken",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: PlaidCheckLinkTokenRequest
    res: PlaidCheckLinkResponse
  },
} as const

export const getPlaidSessionStatus = {
  url: "/v1/plaidSessionStatus",
  method: "GET",
  meta: {} as {
    res: PlaidSessionStatusResponse
  },
} as const

export const refreshItemLoginRequired = {
  url: "/v1/refreshItemLoginRequired",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: PlaidRefreshItemLoginRequired
    res: StatusBooleanResponse
  },
} as const

export const resetPlaid = {
  url: "/v1/resetPlaid",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: PlaidResetRequest
    res: void
  },
} as const

export const setAccessToken = {
  url: "/v1/setAccessToken",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: PlaidAccessRequest
    res: PlaidAccessResponse
  },
} as const

export const setPlaidSessionStatus = {
  url: "/v1/plaidSessionStatus",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: PlaidSessionStatusUpdate
    res: void
  },
} as const
