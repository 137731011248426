import { Avatar, Txt, VStack, Button, HStack, Secure } from "@pomebile/primitives"
import { DollarIcon, FilledCardIcon, GCashMiniIcon } from "@pomebile/design-system"
import { StickyBottom } from "../components/StickyBottom"
import { ScreenFrame } from "../components/ScreenFrame"
import { AppDownloadQRForDesktop } from "../components/AppDownloadQRForDesktop"
import { onlyShowOnMobileStyles } from "../sharedStyles.css"
import { deeplink } from "../utils/deeplink"

export type PostDownloadScreenProps = {
  recipientFirstName: string
}

export function PostDownloadScreen({ recipientFirstName }: PostDownloadScreenProps) {
  const handleDownloadPress = () => {
    window.open(deeplink.OnboardingV2DownloadLink.link, "_blank")
  }

  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="space-between">
        <VStack height="full" gap="xl2" padding={{ bottom: "lg" }}>
          <VStack>
            <Txt variant="headline2">Download the Pomelo app to continue</Txt>
          </VStack>

          <VStack gap="xl2">
            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<DollarIcon width={32} height={32} fill="icon-brand" />} />
              <Secure>
                <Txt variant="subtitle2">
                  See your GCash transfer to {recipientFirstName} and card transactions
                </Txt>
              </Secure>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<GCashMiniIcon width={29} height={24} fill="icon-brand" />} />
              <Txt variant="subtitle2">Send money to GCash</Txt>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar
                size="lg"
                svg={<FilledCardIcon width={26.18} height={19.64} fill="icon-brand" />}
              />
              <Txt variant="subtitle2">Activate your Pomelo Mastercard® when you receive it</Txt>
            </HStack>
          </VStack>
          <AppDownloadQRForDesktop type="OnboardingV2DownloadLink" />
        </VStack>

        <div className={onlyShowOnMobileStyles}>
          <StickyBottom>
            <VStack gap="sm">
              <Button onClick={handleDownloadPress}>Download the Pomelo App</Button>
            </VStack>
          </StickyBottom>
        </div>
      </VStack>
    </ScreenFrame>
  )
}
