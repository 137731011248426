type AnyObj = Record<string, any>

const isObject = (value: unknown): value is AnyObj => {
  return !!(value && typeof value === "object" && !Array.isArray(value))
}

const blackList: string[] = [
  "authToken",
  "firstName",
  "lastName",
  "email",
  "password",
  "ssn",
  "refreshToken",
  "birth", // dateOfBirth, birthDate, etc...
  "lineOne",
  "lineTwo",
  "city",
  "zip",
  "region",
  "phone", // phoneNumber, mobilePhone, etc...
  "income",
  "expiryDate",
  "expiration",
  "cvv",
  "last4",
  "lastFour",
  "ssn",
].map((k) => k.toLowerCase())

const containsBlackListedField = (key: string): boolean => {
  return blackList.some((k) => key.toLowerCase().includes(k))
}

/**
 * Recursively redacts all unspecified primitive properties with "****"
 * @param data object with properties to redact
 * @param allowlist an optional list of properties not to redact
 * @returns data with redacted properties
 */
export function redactSensitiveValues(data: AnyObj): AnyObj {
  const entries = Object.entries(data)

  const entriesWithRedactedValues: typeof entries = entries.map(([key, value]) => {
    if (isObject(value)) {
      return [key, redactSensitiveValues(value)]
    }

    // include objects nested in arrays
    if (Array.isArray(value)) {
      const arrayValues = value.map((v) => {
        if (isObject(v)) {
          return redactSensitiveValues(v)
        }

        return containsBlackListedField(key) ? "****" : v
      })

      return [key, arrayValues]
    }

    return [key, containsBlackListedField(key) ? "****" : value]
  })

  // convert back to an object
  return entriesWithRedactedValues.reduce<AnyObj>((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})
}
