import { Txt, Stack, HStack, TextInputBorderStyle } from "@pomebile/primitives"
import { CheckIcon } from "../icons/check.icon"
import { TextFieldLabel } from "./TextFieldLabel"
import { TextFieldErrorText } from "./TextFieldErrorText"
import { Callout, CalloutVariants } from "./Callout"

export interface InputFieldProps {
  inputComponent: JSX.Element
  label: string
  supportingText?: string
  errorText?: string
  infoText?: string
  infoTextVariant?: CalloutVariants
  borderStyle?: TextInputBorderStyle
  multiline?: boolean
  numberOfLines?: number
}

export const InputField = ({
  inputComponent,
  label,
  supportingText,
  errorText,
  infoText,
  infoTextVariant,
  borderStyle,
}: InputFieldProps) => {
  return (
    <Stack gap="xs2">
      <Stack gap="xs">
        <TextFieldLabel label={label} hasError={borderStyle === "error"} />

        {inputComponent}

        {infoText && (
          // TODO fix implementation for callout based on design
          <Callout
            variant={infoTextVariant}
            // TODO double check implementation once callout is fixed
            icon={<CheckIcon width={21} height={21} fill="icon-error" />}
          >
            {infoText}
          </Callout>
        )}
        {supportingText && (
          <HStack gap="xs2" alignItems="center">
            <Txt as="span" variant="caption" color="text-caption">
              {supportingText}
            </Txt>
          </HStack>
        )}
      </Stack>
      {errorText ? <TextFieldErrorText error={errorText} /> : <Stack padding="xs" />}
    </Stack>
  )
}
