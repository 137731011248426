import { useIntercom } from "react-use-intercom"
import { useLogging } from "./logging"
import {
  Variants,
  matchDeferredTuple,
  taggedUnion,
  useMachine,
  UpdateResult,
  commands,
} from "@pomebile/shared/tagged-union"
import { AuthData } from "../api/authContext"
import { ENABLE_INTERCOM } from "../envConstants"

export type IntercomArgs = {
  userIdent: string
}

export interface IntercomCommands {
  fetchIntercomAuthHash: (auth: AuthData) => string
  bootIntercom: (auth: { userHash: string; userIdent: string }) => void
}

const Cmd = commands<IntercomCommands>()

interface IntercomEvents {
  UserLoggedIn: AuthData
  UserHashFetched: { userHash: string }
  Authenticated: void
  Hide: void
}
export type IntercomEvent = Variants<IntercomEvents>
const Ev = taggedUnion<IntercomEvents>()
export const IntercomEvent = Ev

interface IntercomStates {
  Idle: void
  LoadingUserHash: { auth: AuthData }
  SendingUserHash: { auth: AuthData; userHash: string }
  Ready: void
  NotAllowed: void
}
export type IntercomState = Variants<IntercomStates>
const State = taggedUnion<IntercomStates>()
export const IntercomState = State

type UpdateFunc = (
  prev: IntercomState,
  ev: IntercomEvent,
) => UpdateResult<IntercomState, IntercomEvent, IntercomCommands>

export const updateIntercomState: UpdateFunc = matchDeferredTuple(State, Ev, {
  Idle: {
    UserLoggedIn: (_, auth) =>
      [
        State.LoadingUserHash({ auth }),
        Cmd.fetchIntercomAuthHash({
          arg: auth,
          onComplete: (data) => Ev.UserHashFetched({ userHash: data }),
        }),
      ] as const,
  },
  LoadingUserHash: {
    UserHashFetched: ({ auth }, { userHash }) =>
      [
        State.SendingUserHash({ auth, userHash }),
        Cmd.bootIntercom({
          arg: { userIdent: auth.userIdent, userHash },
          onComplete: () => Ev.Authenticated(),
        }),
      ] as const,
  },
  SendingUserHash: {
    Authenticated: () => [State.Ready()] as const,
  },

  default: (prev, ev) => (ev.tag === "Hide" ? ([State.NotAllowed()] as const) : ([prev] as const)),
})

export function useIntercomMachine(
  fetchIntercomAuthHash: (auth: AuthData) => Promise<string>,
): [IntercomState, (ev: IntercomEvent) => void] {
  const { boot } = useIntercom()
  const logging = useLogging()
  const [state, sendToIntercom] = useMachine(
    updateIntercomState,
    () => [IntercomState.Idle()],
    {
      fetchIntercomAuthHash,
      bootIntercom: async (auth: { userHash: string; userIdent: string }) => {
        boot({ userHash: auth.userHash, userId: auth.userIdent, hideDefaultLauncher: true })
      },
    },
    (err: unknown) => {
      logging.logError(err)
      return [IntercomState.Idle()]
    },
  )
  return [state, sendToIntercom]
}

export function useIntercomSevice() {
  // TODO Ask Simon to help figure out a better way to do this.
  // Can't seem to provide custom env variable settings for storybook.
  if (ENABLE_INTERCOM && !import.meta.env.STORYBOOK) {
    // This should be fine since env variable should not change
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useIntercom()
  }

  return undefined
}
