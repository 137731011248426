import * as Yup from "yup"
import { HStack, Secure, Txt, VStack } from "@pomebile/primitives"
import { PHFlagIcon, TextField, USFlagIcon } from "@pomebile/design-system"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { useForm } from "../components/Form/useForm"
import * as V from "../utils/formValidation/validationMessages"
import { ScreenForm } from "../components/ScreenForm"
import { StickyBottom } from "../components/StickyBottom"
import { NrauPersonalInfo, Country, AddNrauSignUpOutcome } from "../api/webRoutes"
import { validateDateOfBirth } from "../utils/formValidation/validators"
import { useRef } from "react"
import { formatPhoneNumber } from "../utils/format"
import { email, nameSchema } from "../utils/schema"
import { NrauSignUpResponse } from "@pomebile/pomelo-service-api"

export type NrauSignUpInfo = Pick<
  NrauPersonalInfo,
  "firstName" | "lastName" | "email" | "dateOfBirth"
>

export type NrauSignUpOutcome =
  | {
      tag: "success"
      collectedDetails: NrauSignUpInfo
      signUpResult: NrauSignUpResponse
    }
  | {
      tag: "invalidInvite"
    }

export type NrauUserInfoProps = {
  country: Country
  phoneNumber: string
  inviteCode: string
  api: {
    nrauSignup: (details: NrauSignUpInfo) => Promise<AddNrauSignUpOutcome>
  }
  onDone: (outcome: NrauSignUpOutcome) => void
}

const NrauUserInfoSchema = Yup.object({
  firstName: nameSchema,
  lastName: nameSchema,
  email,

  /* TODO: Straight copy from verify identity for now, to be updated if/when we need changes  */
  dateOfBirth: validateDateOfBirth({ minAge: 13 }),

  phoneNumber: Yup.string().required(V.MSG_REQUIRED),
})

export function NrauUserInfoScreen({ country, phoneNumber, api, onDone }: NrauUserInfoProps) {
  const prevDateOfBirthValue = useRef("")

  const [submit, getFieldProps, status] = useForm({
    name: "NrauSignUp", // TODO: Work with Mirza to finalise these names. Note: Must match 1.0 name for analytics
    schema: NrauUserInfoSchema,
    initial: {
      firstName: "",
      lastName: "",
      email: "",
      dateOfBirth: "",
      phoneNumber: formatPhoneNumber(phoneNumber, "-"),
    },
    submit: async (details) => {
      // First, sign up user to create a user account.
      const res = await api.nrauSignup(details)

      if (res.ok) {
        const signUpResponse = res.response
        onDone({ tag: "success", collectedDetails: details, signUpResult: signUpResponse })
      } else if (res.error === "userAlreadyExists") {
        return {
          status: "validationErr",
          err: { field: "email", errorMessage: "Email already exists" }, // TODO: can only email field be the source of issues?
        }
      } else if (res.error === "invalidInvite") {
        onDone({ tag: "invalidInvite" })
      }

      return undefined
    },
  })

  /* TODO: handleDateOfBirthChange is the same as the one used in Verify Identity */
  const { onChange: onChangeDateOfBirth, ...restOfDateOfBirthProps } = getFieldProps("dateOfBirth")
  const handleDateOfBirthChange = (value: string) => {
    prevDateOfBirthValue.current = restOfDateOfBirthProps.value

    if (value === "") onChangeDateOfBirth("")

    // Regex that covers all possible formating combinations while users type in their date of birth
    const datePattern =
      /^(0|1|0[1-9]|1[0-2])(\/(0|1|2|3|0[1-9]|[12][0-9]|3[01])?(\/(1|2|19|20|\d{3}|\d{4})?)?)?$/

    let inputDate = value

    if (!datePattern.test(inputDate)) {
      return
    }

    const isExpectedLength = inputDate.length === 2 || inputDate.length === 5 // expects the formats MM and MM/DD
    const userTypedInCharacter = prevDateOfBirthValue.current.length <= inputDate.length // user typed in a new character

    // Auto-format adding a slash in between month, day, and year
    if (isExpectedLength && userTypedInCharacter) {
      inputDate = `${inputDate}/`
    }

    onChangeDateOfBirth(inputDate)
  }

  const disabled = status !== "idle" && status !== "submitErr"

  return (
    <ScreenForm onSubmit={submit}>
      <div>
        <VStack gap="xs">
          <Txt variant="headline2" as="h1">
            Your personal details
          </Txt>

          <Txt variant="body1" as="p">
            Please enter your name as it appears on your official government identification.
          </Txt>
        </VStack>

        <VStack gap="xl2" padding={{ bottom: "xl2", top: "xl2" }}>
          <VStack gap="xs">
            <HStack gap="sm">
              <TextField
                label="First Name"
                disabled={disabled}
                type="text"
                {...getFieldProps("firstName")}
              />
              <Secure>
                <TextField
                  label="Last Name"
                  disabled={disabled}
                  type="text"
                  {...getFieldProps("lastName")}
                />
              </Secure>
            </HStack>

            <Secure>
              <TextField
                label="Date of birth"
                placeholder="MM/DD/YYYY"
                type="text"
                onChange={handleDateOfBirthChange}
                {...restOfDateOfBirthProps}
              />
              <TextField
                label="Mobile Phone Number"
                type="text"
                supportingText="We'll send you a one-time 6-digit code to this number"
                inputAdornment={
                  <HStack gap="xs">
                    {country === "US" ? <USFlagIcon /> : <PHFlagIcon width={24} height={24} />}
                    <Txt>{country === "US" ? "+1" : "+63"}&nbsp;</Txt>
                  </HStack>
                }
                disabled
                {...getFieldProps("phoneNumber")}
              />

              <TextField
                label="Email Address"
                type="email"
                disabled={disabled}
                {...getFieldProps("email")}
              />
            </Secure>
          </VStack>
        </VStack>
      </div>
      <StickyBottom>
        <FormSubmitButton status={status}>Continue</FormSubmitButton>
      </StickyBottom>
    </ScreenForm>
  )
}
