import { Avatar, Txt, VStack } from "@pomebile/primitives"
import { CrossIcon } from "@pomebile/design-system"

export function RejectedScreen() {
  return (
    <VStack>
      <VStack justifyContent="center" alignItems="center" height="full" width="full" padding="xl2">
        <VStack justifyContent="center" alignItems="center" padding="md" width="unset">
          <Avatar svg={<CrossIcon fill="icon-emphasis" width={24} height={24} />} />
        </VStack>
        <Txt variant="headline2" as="h1" textAlign="center">
          We are unable to proceed with your application at this time
        </Txt>
      </VStack>
    </VStack>
  )
}
