import type { ENV_VARS } from "./envSchema.ts"

export const APP_DOWNLOAD_URL: ENV_VARS["PUBLIC_APP_DOWNLOAD_URL"] = import.meta.env
  .PUBLIC_APP_DOWNLOAD_URL

export const BASE_API_URL: ENV_VARS["PUBLIC_BASE_API_URL"] = import.meta.env.PUBLIC_BASE_API_URL

export const CARDINAL_SCRIPT_URL: ENV_VARS["PUBLIC_CARDINAL_SCRIPT_URL"] = import.meta.env
  .PUBLIC_CARDINAL_SCRIPT_URL

export const DEVICE_INTEL_SCRIPT_URL: ENV_VARS["PUBLIC_DEVICE_INTEL_SCRIPT_URL"] = import.meta.env
  .PUBLIC_DEVICE_INTEL_SCRIPT_URL

export const DEVICE_INTEL_API_KEY: ENV_VARS["PUBLIC_DEVICE_INTEL_API_KEY"] = import.meta.env
  .PUBLIC_DEVICE_INTEL_API_KEY

export const DEVICE_INTEL_DEBUG_MODE: ENV_VARS["PUBLIC_DEVICE_INTEL_DEBUG_MODE"] = import.meta.env
  .PUBLIC_DEVICE_INTEL_DEBUG_MODE

export const ENABLE_INTERCOM: ENV_VARS["PUBLIC_ENABLE_INTERCOM"] = import.meta.env
  .PUBLIC_ENABLE_INTERCOM

export const DEVICE_INTEL_ENABLED: ENV_VARS["PUBLIC_DEVICE_INTEL_ENABLED"] = import.meta.env
  .PUBLIC_DEVICE_INTEL_ENABLED

export const INTERCOM_APP_ID: ENV_VARS["PUBLIC_INTERCOM_APP_ID"] = import.meta.env
  .PUBLIC_INTERCOM_APP_ID

export const INTERCOM_INVALID_RECIPIENT_ARTICLE: ENV_VARS["PUBLIC_INTERCOM_INVALID_RECIPIENT_ARTICLE"] =
  import.meta.env.PUBLIC_INTERCOM_INVALID_RECIPIENT_ARTICLE

export const MARKETING_WEBSITE: ENV_VARS["PUBLIC_MARKETING_WEBSITE"] = import.meta.env
  .PUBLIC_MARKETING_WEBSITE
export const CARD_ACTIVATION_RPC_WEBSITE: ENV_VARS["PUBLIC_CARD_ACTIVATION_RPC_WEBSITE"] =
  import.meta.env.PUBLIC_CARD_ACTIVATION_RPC_WEBSITE
export const CARD_ACTIVATION_NRAU_WEBSITE: ENV_VARS["PUBLIC_CARD_ACTIVATION_NRAU_WEBSITE"] =
  import.meta.env.PUBLIC_CARD_ACTIVATION_NRAU_WEBSITE

export const SEGMENT_ENABLED: ENV_VARS["PUBLIC_SEGMENT_ENABLED"] = import.meta.env
  .PUBLIC_SEGMENT_ENABLED

export const SENTRY_DSN: ENV_VARS["PUBLIC_SENTRY_DSN"] = import.meta.env.PUBLIC_SENTRY_DSN

export const SEGMENT_WRITE_KEY: ENV_VARS["PUBLIC_SEGMENT_WRITE_KEY"] = import.meta.env
  .PUBLIC_SEGMENT_WRITE_KEY

export const SENTRY_ENABLED: ENV_VARS["PUBLIC_SENTRY_ENABLED"] = import.meta.env
  .PUBLIC_SENTRY_ENABLED

export const SMARTYSTREETS_KEY: ENV_VARS["PUBLIC_SMARTYSTREETS_KEY"] = import.meta.env
  .PUBLIC_SMARTYSTREETS_KEY

export const VGS_ENV: ENV_VARS["PUBLIC_VGS_ENV"] = import.meta.env.PUBLIC_VGS_ENV

export const VGS_PROXY_API_URL: ENV_VARS["PUBLIC_VGS_PROXY_API_URL"] = import.meta.env
  .PUBLIC_VGS_PROXY_API_URL

export const VGS_VAULT: ENV_VARS["PUBLIC_VGS_VAULT"] = import.meta.env.PUBLIC_VGS_VAULT

const untypedMode = import.meta.env.MODE
export const BUILD_MODE: "staging" | "production" | "development" =
  untypedMode === "staging"
    ? "staging"
    : untypedMode === "production"
    ? "production"
    : "development"

export const APP_BUILD: ENV_VARS["PUBLIC_APP_BUILD"] = import.meta.env.PUBLIC_APP_BUILD

export const LOGROCKET_ENABLED: ENV_VARS["PUBLIC_LOGROCKET_ENABLED"] = import.meta.env
  .PUBLIC_LOGROCKET_ENABLED
export const LOGROCKET_ID: ENV_VARS["PUBLIC_LOGROCKET_ID"] = import.meta.env.PUBLIC_LOGROCKET_ID
