import { Svg, Path, Defs, ClipPath, Rect, G } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const USFlagIcon = ({ width = 24, height = 24 }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" customFill="none">
      <G clipPath="url(#clip0_259_7061)">
        <G clipPath="url(#clip1_259_7061)">
          <Path
            d="M12 23.9999C18.6274 23.9999 24 18.6273 24 11.9999C24 5.37246 18.6274 -0.00012207 12 -0.00012207C5.37258 -0.00012207 0 5.37246 0 11.9999C0 18.6273 5.37258 23.9999 12 23.9999Z"
            customFill="#F0F0F0"
          />
          <Path
            d="M11.4785 12H24.0002C24.0002 10.9169 23.8559 9.86762 23.5868 8.86951H11.4785V12Z"
            customFill="#D80027"
          />
          <Path
            d="M11.4785 5.7391H22.239C21.5044 4.54041 20.5651 3.48089 19.47 2.60864H11.4785V5.7391Z"
            customFill="#D80027"
          />
          <Path
            d="M12.0001 23.9999C14.8243 23.9999 17.4201 23.0238 19.4699 21.3912H4.53027C6.58012 23.0238 9.17591 23.9999 12.0001 23.9999Z"
            customFill="#D80027"
          />
          <Path
            d="M1.76146 18.2608H22.2389C22.8286 17.2985 23.286 16.2467 23.5868 15.1304H0.413574C0.714371 16.2467 1.17173 17.2985 1.76146 18.2608V18.2608Z"
            customFill="#D80027"
          />
          <Path
            d="M5.55862 1.87385H6.65217L5.63498 2.61283L6.02353 3.80857L5.00639 3.06958L3.98925 3.80857L4.32487 2.77558C3.42928 3.5216 2.64431 4.39563 1.99744 5.3695H2.34783L1.70034 5.83989C1.59947 6.00817 1.50272 6.17913 1.41 6.35261L1.71919 7.30422L1.14234 6.88511C0.998953 7.18891 0.867797 7.49955 0.749906 7.81666L1.09055 8.86516H2.34783L1.33064 9.60414L1.71919 10.7999L0.702047 10.0609L0.0927656 10.5036C0.0317812 10.9938 0 11.4931 0 11.9999H12C12 5.3725 12 4.59119 12 -0.00012207C9.62944 -0.00012207 7.41961 0.687534 5.55862 1.87385V1.87385ZM6.02353 10.7999L5.00639 10.0609L3.98925 10.7999L4.3778 9.60414L3.36061 8.86516H4.61789L5.00639 7.66943L5.39489 8.86516H6.65217L5.63498 9.60414L6.02353 10.7999ZM5.63498 6.10849L6.02353 7.30422L5.00639 6.56524L3.98925 7.30422L4.3778 6.10849L3.36061 5.3695H4.61789L5.00639 4.17377L5.39489 5.3695H6.65217L5.63498 6.10849ZM10.3279 10.7999L9.31073 10.0609L8.29359 10.7999L8.68214 9.60414L7.66495 8.86516H8.92223L9.31073 7.66943L9.69923 8.86516H10.9565L9.93933 9.60414L10.3279 10.7999ZM9.93933 6.10849L10.3279 7.30422L9.31073 6.56524L8.29359 7.30422L8.68214 6.10849L7.66495 5.3695H8.92223L9.31073 4.17377L9.69923 5.3695H10.9565L9.93933 6.10849ZM9.93933 2.61283L10.3279 3.80857L9.31073 3.06958L8.29359 3.80857L8.68214 2.61283L7.66495 1.87385H8.92223L9.31073 0.678112L9.69923 1.87385H10.9565L9.93933 2.61283Z"
            customFill="#0052B4"
          />
        </G>
      </G>
      <Defs>
        <ClipPath id="clip0_259_7061">
          <Rect width={24} height={24} customFill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
