import {
  Variants,
  matchDeferredTuple,
  taggedUnion,
  UpdateResult,
  commands,
} from "@pomebile/shared/tagged-union"

import { ActiveVeriffSessionResponse, GenerateVeriffResponse, TerminalKycOutcome } from "../../api/webRoutes"

const MAX_TIME_LIMIT = 30000 // 30 seconds, max time limit we will continue to re-poll
const RETRY_INTERVAL = 5000 // 5 seconds, how often to re-poll

export type VeriffSdkResponse =
  | {
      type: "reload_required"
    }
  | {
      type: "finished"
    }
  | {
      type: "user_cancelled"
    }

const Cmd = commands<VeriffCommands>()

interface VeriffCommands {
  queryDecision: () => VeriffOutcome
  getActiveVeriffSession: () => ActiveVeriffSessionResponse
  generateVeriffSession: () => GenerateVeriffResponse
  startVeriffSdk: (url: string) => VeriffSdkResponse
  reportOutcome: (outcome: VeriffOutcome) => void
  pollingDelay: (time: number) => void
}

interface VeriffEvs {
  UserPressedContinue: void
  NoVeriffSessionFound: void
  VeriffUrlFetched: string
  VeriffSdkStarted: void
  VeriffSdkClosed: VeriffSdkResponse
  VeriffDecisionFetched: VeriffOutcome
  VeriffPollingDelayed: void
  VeriffLimitReached: void
}

export type VeriffEv = Variants<VeriffEvs>
const Ev = taggedUnion<VeriffEvs>()
export const VeriffEv = Ev

type VeriffSdkError = "sdk_needs_reload" | "user_cancelled" | "cannot_open" | "network_error"

export type VeriffOutcome =
  | { tag: TerminalKycOutcome }
  | { tag: "requires_resubmission"; reason?: string }
  | { tag: "requires_support" }
  | { tag: "pending" }

interface VeriffStates {
  Idle: {
    veriffUrl?: string
  }
  SettingUpVeriffSession: void
  WaitingForVeriffSdk: {
    veriffUrl: string
  }
  FetchingVeriffDecision: {
    veriffUrl: string
    retryCount: number
  }
  VeriffResubmissionRequired: {
    veriffUrl: string
    reason?: string
  }
  VeriffSdkError: {
    veriffUrl?: string
    error: VeriffSdkError
  }
  ReportingOutcome: {
    outcome: VeriffOutcome
  }
  Complete: void
}

export type VeriffState = Variants<VeriffStates>
const State = taggedUnion<VeriffStates>()
export const VeriffState = taggedUnion<VeriffStates>()

type UpdateFunc = (
  prev: VeriffState,
  ev: VeriffEv,
) => UpdateResult<VeriffState, VeriffEv, VeriffCommands>

export const updateVeriffState: UpdateFunc = matchDeferredTuple(VeriffState, VeriffEv, {
  Idle: {
    UserPressedContinue: () =>
      [
        State.SettingUpVeriffSession(),
        Cmd.getActiveVeriffSession({
          onComplete: (data) =>
            data.hasSession ? Ev.VeriffUrlFetched(data.url) : Ev.NoVeriffSessionFound(),
        }),
      ] as const,
  },
  SettingUpVeriffSession: {
    NoVeriffSessionFound: () => {
      return [
        State.SettingUpVeriffSession(),
        Cmd.generateVeriffSession({
          onComplete: (data) => (data.ok ? Ev.VeriffUrlFetched(data.url) : Ev.VeriffLimitReached()),
        }),
      ] as const
    },
    VeriffUrlFetched: (_, veriffUrl) => {
      return [
        State.WaitingForVeriffSdk({ veriffUrl: veriffUrl }),
        Cmd.startVeriffSdk({
          arg: veriffUrl,
          onComplete: (result) => Ev.VeriffSdkClosed(result),
        }),
      ] as const
    },
    VeriffLimitReached: () => {
      return [
        State.ReportingOutcome({ outcome: { tag: "requires_support" } }),
        Cmd.reportOutcome({ arg: { tag: "requires_support" } }),
      ] as const
    },
  },
  WaitingForVeriffSdk: {
    VeriffSdkClosed: (state, result) => {
      switch (result.type) {
        case "reload_required":
          return [
            State.VeriffSdkError({
              ...state,
              error: "sdk_needs_reload",
            }),
          ] as const
        case "user_cancelled":
          return [State.VeriffSdkError({ ...state, error: "user_cancelled" })] as const
        case "finished":
          return [
            State.FetchingVeriffDecision({ ...state, retryCount: 0 }),
            Cmd.queryDecision({
              onComplete: (outcome) => Ev.VeriffDecisionFetched(outcome),
            }),
          ] as const
      }
    },
  },
  FetchingVeriffDecision: {
    VeriffDecisionFetched: ({ retryCount, ...state }, outcome) => {
      const { tag } = outcome

      switch (tag) {
        case "pending":
          return MAX_TIME_LIMIT > retryCount * RETRY_INTERVAL
            ? ([
                State.FetchingVeriffDecision({ ...state, retryCount: retryCount + 1 }),
                Cmd.pollingDelay({
                  arg: RETRY_INTERVAL,
                  onComplete: () => Ev.VeriffPollingDelayed(),
                }),
              ] as const)
            : ([
                State.ReportingOutcome({ outcome: { tag } }),
                Cmd.reportOutcome({ arg: { tag } }),
              ] as const)
        case "requires_resubmission":
          return [
            State.VeriffResubmissionRequired({
              ...state,
              reason: outcome.reason,
            }),
          ] as const
        case "requires_support":
        case "approved":
        case "rejected":
        case "manualReview":
          return [State.ReportingOutcome({ outcome }), Cmd.reportOutcome({ arg: outcome })] as const
      }
    },
    VeriffPollingDelayed: (state) =>
      [
        State.FetchingVeriffDecision(state),
        Cmd.queryDecision({
          onComplete: (outcome) => Ev.VeriffDecisionFetched(outcome),
        }),
      ] as const,
  },

  VeriffResubmissionRequired: {
    UserPressedContinue: (state) =>
      [
        State.WaitingForVeriffSdk(state),
        Cmd.startVeriffSdk({
          arg: state.veriffUrl,
          onComplete: (result) => Ev.VeriffSdkClosed(result),
        }),
      ] as const,
  },

  VeriffSdkError: {
    UserPressedContinue: (state) => {
      if (state.veriffUrl) {
        return [
          State.WaitingForVeriffSdk({ veriffUrl: state.veriffUrl }),
          Cmd.startVeriffSdk({
            arg: state.veriffUrl,
            onComplete: (result) => Ev.VeriffSdkClosed(result),
          }),
        ] as const
      } else {
        // if there is no veriff URL, get a URL and restart
        return [
          State.SettingUpVeriffSession(),
          Cmd.getActiveVeriffSession({
            onComplete: (data) =>
              data.hasSession ? Ev.VeriffUrlFetched(data.url) : Ev.NoVeriffSessionFound(),
          }),
        ] as const
      }
    },
  },

  ReportingOutcome: {
    UserPressedContinue: () => [State.Complete()] as const,
  },

  default: (prev) => [prev] as const,
})
