import { ClientFeatureFlags, FlagConfiguration } from "@pomebile/pomelo-service-api"
import { createContext, FC, useContext, useEffect, useState } from "react"
import { AuthData } from "../api/authContext"
import { useLogging } from "./logging"

type FeatureFlagContext = {
  isLoading: boolean
  enabledFlags: ClientFeatureFlags[]
}

const FeatureFlagCtx = createContext<FeatureFlagContext>({ isLoading: false, enabledFlags: [] })

export const FeatureFlagProvider: FC<{
  auth?: AuthData
  fetchFlags: (auth: AuthData) => Promise<FlagConfiguration>
  children: React.ReactNode
}> = ({ auth, fetchFlags, children }) => {
  const [enabledFlags, setEnabledFlags] = useState<ClientFeatureFlags[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { logError } = useLogging()

  async function fetchAndSetFlags(auth: AuthData) {
    try {
      setIsLoading(true)
      const flags = await fetchFlags(auth)
      setEnabledFlags(flags.enabledFlags)
    } catch (err) {
      logError(err)
      setEnabledFlags([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (auth) {
      fetchAndSetFlags(auth)
    } else {
      setEnabledFlags([])
    }

    // Dependency is `userIdent` here to avoid rerenders on auth obj changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.userIdent])

  return (
    <FeatureFlagCtx.Provider value={{ isLoading, enabledFlags }}>
      {children}
    </FeatureFlagCtx.Provider>
  )
}

// This method returns the subset of BE flags that have been configured
// to be passed to the client, and are evaluating to true. Those flags are
// configured in the `backend` LD environment (not `pomelo`). It currently
// returns user-specific flag evaluations, and will return an empty array if
// not authed.
export function useFeatureFlag(): FeatureFlagContext {
  return useContext(FeatureFlagCtx)
}
