import { useMachine } from "@pomebile/shared/tagged-union"
import { useLogging } from "../utils/logging"
import { MESSAGES, createVeriffFrame } from "@veriff/incontext-sdk"
import { NetworkError } from "../api/issueRequest"
import { VeriffOutcome, VeriffSdkResponse, VeriffState, updateVeriffState } from "../screens/Veriff/rpcVeriffStateMachine"
import { ActiveVeriffSessionResponse, GenerateVeriffResponse } from "../api/webRoutes"

export function startVeriffSdk(url: string): Promise<VeriffSdkResponse> {
  const veriffResultProm = new Promise<VeriffSdkResponse>((resolve) => {
    createVeriffFrame({
      url,
      onEvent: (ev) => {
        switch (ev) {
          case MESSAGES.FINISHED:
            resolve({ type: "finished" })
            break
          case MESSAGES.RELOAD_REQUEST:
            resolve({ type: "reload_required" })
            break
          case MESSAGES.CANCELED:
            resolve({ type: "user_cancelled" })
        }
      },
      onReload: () => {
        resolve({ type: "reload_required" })
      },
    })
  })
  return veriffResultProm
}

export function useRpcVeriff(api: {
  queryDecision: () => Promise<VeriffOutcome>
  getActiveVeriffSession: () => Promise<ActiveVeriffSessionResponse>
  generateVeriffSession: () => Promise<GenerateVeriffResponse>
  startVeriffSdk: (url: string) => Promise<VeriffSdkResponse>
  reportOutcome: (outcome: VeriffOutcome) => Promise<void>
}) {
  const { logError } = useLogging()
  const {
    generateVeriffSession,
    getActiveVeriffSession,
    queryDecision,
    startVeriffSdk,
    reportOutcome,
  } = api
  const [state, send] = useMachine(
    updateVeriffState,
    () => [VeriffState.Idle({})],
    {
      generateVeriffSession,
      getActiveVeriffSession,
      queryDecision,
      reportOutcome,
      startVeriffSdk,
      pollingDelay: (time) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve()
          }, time)
        })
      },
    },
    (err: unknown) => {
      logError(err)
      if (!(err instanceof NetworkError)) {
        return [VeriffState.VeriffSdkError({ veriffUrl: "", error: "network_error" })] as const
      } else {
        return [VeriffState.VeriffSdkError({ veriffUrl: "", error: "cannot_open" })] as const
      }
    },
  )
  return [state, send] as const
}
