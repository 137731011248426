import { Svg, Path } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const CheckIcon = ({ width = 24, height = 24, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.47201 16.7251L5.5495 12.8018C5.39215 12.6445 5.17873 12.5561 4.95621 12.5561C4.73369 12.5561 4.52028 12.6445 4.36293 12.8018C4.20558 12.9592 4.11719 13.1726 4.11719 13.3951C4.11719 13.6176 4.20558 13.831 4.36293 13.9884L8.87865 18.5041C8.95652 18.582 9.04906 18.6439 9.15084 18.6861C9.25266 18.7283 9.36179 18.7501 9.472 18.7501C9.58221 18.7501 9.69134 18.7283 9.79316 18.6861C9.89494 18.6439 9.9874 18.5821 10.0653 18.5042L20.3871 8.18236C20.5444 8.02501 20.6328 7.8116 20.6328 7.58908C20.6328 7.36656 20.5444 7.15315 20.3871 6.9958C20.2297 6.83846 20.0163 6.75006 19.7938 6.75006C19.5713 6.75006 19.3579 6.83846 19.2005 6.9958L9.47201 16.7251Z"
        fill={fill}
      />
    </Svg>
  )
}
