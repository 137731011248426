import { useCallback, useState } from "react"
import { usAutocompletePro } from "smartystreets-javascript-sdk"

import { debounce } from "../helpers/timing"
import { findAddress } from "../helpers/address"

export type Suggestion = usAutocompletePro.Suggestion

type UseAddressSuggestionsHook = () => {
  suggestions: Suggestion[]
  fetchSuggestions: (address: string) => void
  clearSuggestions: () => void
}

export const useAddressSuggestions: UseAddressSuggestionsHook = () => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchSuggestions = useCallback(
    debounce(async (address: string) => {
      try {
        // We are only supporting US addresses for now
        const response = await findAddress(address)

        if (response?.result?.length > 0) {
          setSuggestions(response.result)
        } else {
          setSuggestions([])
        }
      } catch (err) {
        console.log(err)
      }
    }, 300),
    [],
  )

  const clearSuggestions = useCallback(() => {
    setSuggestions([])
  }, [])

  return { suggestions, clearSuggestions, fetchSuggestions }
}
