import { ResponseStatusError } from "./issueRequest"

// matches common ApiError coming from BE
export type GenericApiError = {
  cause: string
  message: string
  status: number
  errorType?: unknown
}

export const isGenericApiError = (err: unknown): err is GenericApiError => {
  if (typeof err !== "object") {
    return false
  }

  const typedErr = err as {
    cause?: unknown
    message?: unknown
    status?: unknown
    errorType?: unknown
  }
  return (
    "cause" in typedErr &&
    typeof typedErr.cause === "string" &&
    "message" in typedErr &&
    typeof typedErr.message === "string" &&
    "status" in typedErr &&
    typeof typedErr.status === "number"
  )
}

export const ANY_CODE = "*"
export const ANY_CAUSE = { cause: "*" } as const

type ExpectedApiErrorPattern<TRes> = {
  codes: typeof ANY_CODE | number[]
  cause: typeof ANY_CAUSE | string
  handler: (err: ResponseStatusError, apiError: GenericApiError) => TRes
}

export const handlerApiError = <TRes>(
  codes: typeof ANY_CODE | number[],
  cause: typeof ANY_CAUSE | string,
  map: (err: ResponseStatusError, apiError: GenericApiError) => TRes,
): ExpectedApiErrorPattern<TRes> => ({ codes, cause, handler: map })

export async function mapResponse<TResponse, TRes>(
  request: Promise<TResponse>,
  handlers: [(okResp: TResponse) => TRes, ...ExpectedApiErrorPattern<TRes>[]],
): Promise<TRes> {
  const [okHandler, ...errorHandlers] = handlers

  try {
    return okHandler(await request)
  } catch (err) {
    if (err instanceof ResponseStatusError && isGenericApiError(err.payload)) {
      const { response, payload } = err
      for (const { cause, codes, handler } of errorHandlers) {
        if (codes === ANY_CODE || codes.indexOf(response.status) !== -1) {
          if (typeof cause === "object" || cause === payload.cause) {
            return handler(err, payload)
          }
        }
      }
      // rethrow if we couldn't handle it
      throw err
    } else {
      // if it is not a reponse error just pass it along, for example validation errors
      throw err
    }
  }
}
