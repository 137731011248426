import { useReducer } from "react"
import { useLogging } from "../../utils/logging"
import * as Yup from "yup"

// that contraint essentially forbids nested objects for now
// like "{a: b: {c}}" for forms
// 1. we don't have to have them yet? Or maybe ever
export type ShallowObject = {
  [k: string]: string | boolean | undefined
}

type SubmitResponse<ApiResponse, TValues extends ShallowObject> =
  | {
      status: "ok"
      data: ApiResponse
    }
  | {
      status: "validationErr"
      err: {
        field: keyof TValues
        errorMessage: string
      }
    }
  | void

export type UseFormArgs<TValues extends ShallowObject, ApiResponse> = {
  name: string
  schema: Yup.ObjectSchema<TValues>
  initial: TValues
  submit: (
    values: TValues,
    // extra: { setError: (field: keyof TValues, errorMessage: string) => void },
  ) => Promise<SubmitResponse<ApiResponse, TValues>>
}

type TextFieldProps<TValues, K extends keyof TValues> = {
  value: TValues[K]
  onChange: (v: TValues[K]) => void
  onBlur: () => void
  errorText?: string
}

export type FormStatus = "done" | "submitErr" | "submitting" | "idle"

export type UseFormResult<TValues, ApiResponse> = [
  handleSubmit: () => Promise<ApiResponse | void>,
  getProps: <K extends keyof TValues>(field: K) => TextFieldProps<TValues, K>,
  status: FormStatus,
  extra: {
    values: TValues
    isFormValid: boolean
    setError: (field: keyof TValues, errorMessage: string) => void
    setTouched: (field: keyof TValues, bool: boolean) => void
  },
]

type ValidationResult<TValues extends ShallowObject> =
  | {
      ok: true
    }
  | {
      ok: false
      errors: Partial<Record<keyof TValues, string>>
    }

type FormState<TValues extends ShallowObject> = {
  status: FormStatus
  touched: Partial<Record<keyof TValues, boolean>>
  validation: ValidationResult<TValues>
  values: TValues
  schema: Yup.ObjectSchema<TValues>
}

type Ev<TValues> =
  | {
      tag: "onBlur"
      field: keyof TValues
    }
  | {
      tag: "onChange"
      field: keyof TValues
      value: string | boolean
    }
  | {
      tag: "onSubmit"
    }
  | {
      tag: "submitted"
    }
  | {
      tag: "submissionFailed"
    }
  | {
      tag: "markAllTouched"
    }
  | {
      tag: "onSetError"
      field: keyof TValues
      errorMessage: string
    }
  | {
      tag: "onSetTouched"
      field: keyof TValues
      bool: boolean
    }

const update = <TValues extends ShallowObject>(
  prev: FormState<TValues>,
  ev: Ev<TValues>,
): FormState<TValues> => {
  switch (ev.tag) {
    case "onSubmit":
      const submitTouched: Partial<Record<keyof TValues, true>> = {}
      for (const k in prev.schema.fields) {
        submitTouched[k] = true
      }
      return { ...prev, touched: submitTouched, status: "submitting" }

    case "submitted":
      return { ...prev, status: "done" }

    case "submissionFailed":
      return { ...prev, status: "submitErr" }

    case "onBlur": {
      const { field } = ev
      if (field in prev.touched && prev.touched[field]) {
        return prev
      } else {
        return {
          ...prev,
          touched: { ...prev.touched, [field]: true },
          validation: validate<TValues>(prev.schema, prev.values),
        }
      }
    }

    case "markAllTouched":
      const touched: Partial<Record<keyof TValues, true>> = {}
      for (const k in prev.schema.fields) {
        touched[k] = true
      }
      return { ...prev, touched, validation: validate<TValues>(prev.schema, prev.values) }

    case "onChange":
      const values = { ...prev.values, [ev.field]: ev.value }
      return {
        ...prev,
        touched: { ...prev.touched, [ev.field]: false },
        validation: validate<TValues>(prev.schema, values),
        values,
      }
    case "onSetError":
      if (prev.validation.ok) {
        const errors: Partial<Record<keyof TValues, string>> = {}
        errors[ev.field] = ev.errorMessage

        return {
          ...prev,
          status: "idle",
          validation: { ok: false, errors },
        }
      }

      return {
        ...prev,
        status: "idle",
        validation: {
          ok: false,
          errors: { ...prev.validation.errors, [ev.field]: ev.errorMessage },
        },
      }

    case "onSetTouched":
      return {
        ...prev,
        touched: { ...prev.touched, [ev.field]: ev.bool },
        validation: validate<TValues>(prev.schema, prev.values),
      }
  }
}

export function useForm<TValues extends ShallowObject, ApiResponse>({
  name,
  initial,
  schema,
  submit,
}: UseFormArgs<TValues, ApiResponse>): UseFormResult<TValues, ApiResponse> {
  const [{ values, validation, status, touched }, send] = useReducer(update<TValues>, {
    status: "idle",
    touched: {},
    validation: validate<TValues>(schema, initial),
    values: initial,
    schema,
  })

  const { logFormEvent } = useLogging()

  const setTouched = (field: keyof TValues, bool: boolean) => {
    send({ tag: "onSetTouched", field, bool })
  }

  const setError = (field: keyof TValues, errorMessage: string) =>
    send({ tag: "onSetError", field, errorMessage })
  return [
    function handleSubmit() {
      if (status === "submitting") {
        // no double submit
        return Promise.resolve()
      }

      if (validation.ok) {
        send({ tag: "onSubmit" })

        return submit(values)
          .then((response) => {
            if (response === undefined || response === null || response.status === "ok") {
              logFormEvent(name, { tag: "submitted" })
              send({ tag: "submitted" })
              return response?.status === "ok" ? response.data : undefined
            } else {
              const {
                err: { errorMessage, field },
              } = response
              logFormEvent(name, {
                tag: "submissionRejected",
                reason: errorMessage,
              })
              send({ tag: "onSetError", field, errorMessage })
              return undefined
            }
          })
          .catch((err: unknown) => {
            logFormEvent(name, {
              tag: "submissionFailed",
              err,
            })
            send({ tag: "submissionFailed" })
          })
      } else {
        // that will surface all errors all at once
        logFormEvent(name, {
          tag: "validationRejected",
          reasons: validation.errors,
        })
        send({ tag: "markAllTouched" })
      }

      return Promise.resolve()
    },
    function getFieldProps(field) {
      return {
        value: values[field],
        onChange: (value) => value !== undefined && send({ tag: "onChange", field, value }),
        onBlur: () => send({ tag: "onBlur", field }),
        errorText: touched[field] && (validation.ok ? undefined : validation.errors[field]),
      }
    },
    status,
    {
      values,
      isFormValid: validation.ok,
      setError,
      setTouched,
    },
  ]
}

const validate = <TValues extends ShallowObject>(
  schema: Yup.ObjectSchema<TValues>,
  values: TValues,
): ValidationResult<TValues> => {
  try {
    schema.validateSync(values, { strict: true, abortEarly: false })
    return { ok: true }
  } catch (e) {
    if (Yup.ValidationError.isError(e)) {
      // because we only support shallow objects for now
      // we just can iterate through inner errors non recursively
      const errors: Partial<Record<keyof TValues, string>> = {}

      for (const err of e.inner) {
        const field = (err.path || "unknown") as keyof TValues
        errors[field] = err.message
      }
      return { ok: false, errors }
    } else {
      return { ok: true }
    }
  }
}
