import { ActivityIcon, CardIcon, DollarIcon } from "@pomebile/design-system"
import { Button, HStack, Txt, VStack, Avatar } from "@pomebile/primitives"
import { StickyBottom } from "../components/StickyBottom"
import { ScreenFrame } from "../components/ScreenFrame"
import { APP_DOWNLOAD_URL } from "../envConstants"
import { deeplink } from "../utils/deeplink"

export const NrauCompleteScreen = () => {
  const handleDownloadPress = () => {
    window.open(deeplink.OnboardingV2DownloadLink.link, "_blank")
  }
  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="space-between">
        <VStack height="full" gap="xl2" padding={{ bottom: "lg" }}>
          <VStack padding={{ bottom: "lg" }}>
            <Txt variant="headline2">Download the Pomelo App to continue</Txt>
          </VStack>

          <VStack gap="xl">
            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<CardIcon width={32} height={32} fill="icon-brand" />} />
              <Txt variant="subtitle2">Activate your Pomelo Mastercard®</Txt>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<DollarIcon width={32} height={32} fill="icon-brand" />} />
              <Txt variant="subtitle2">See how much you can spend</Txt>
            </HStack>

            <HStack alignItems="center" gap="md">
              <Avatar size="lg" svg={<ActivityIcon width={32} height={32} fill="icon-brand" />} />
              <Txt variant="subtitle2">
                See all your Pomelo Mastercard® transactions in one place
              </Txt>
            </HStack>
          </VStack>
        </VStack>

        <StickyBottom>
          <VStack gap="sm">
            <Button onClick={handleDownloadPress}>Download App</Button>
          </VStack>
        </StickyBottom>
      </VStack>
    </ScreenFrame>
  )
}
