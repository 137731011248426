import { Dialog } from "../components/Dialog"
import { CrossIcon, TextField } from "@pomebile/design-system"
import { Button, HStack, Txt, Link, VStack } from "@pomebile/primitives"
import { DialogHeader } from "../components/Dialog/DialogHeader"
import { useState } from "react"
import { radioStyles, labelSprinkles } from "./DeclineReasonDialog.css"
import * as Yup from "yup"

import * as V from "../utils/formValidation/validationMessages"
import { useForm } from "../components/Form/useForm"
import { UserResponse } from "@pomebile/pomelo-service-api"

const ReasonSchema = Yup.object({
  reason: Yup.string().max(2000, V.MSG_MAX_CHARACTERS(2000)),
})

export interface ReasonInfo extends Yup.InferType<typeof ReasonSchema> {}

const options: string[] = [
  "Credit limit is too low",
  "I want to use Pomelo without credit",
  "I’m worried my credit will be affected",
  "Preferred delivery method not supported",
  "Technical issue",
  "Other",
]

const initialFormValues: ReasonInfo = {
  reason: "",
}

export interface DeclineReasonDialogProps {
  isOpen: boolean
  onClose: () => void
  onDecline: () => void
  api: (reason: string) => Promise<UserResponse>
}

export const DeclineReasonDialog = ({
  isOpen,
  onClose,
  onDecline,
  api,
}: DeclineReasonDialogProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const [submit, getFieldProps, status] = useForm({
    name: "Reason for declining", // Note: Must match 1.0 name for analytics
    schema: ReasonSchema,
    initial: initialFormValues,
    submit: async (details) => {
      if (details.reason) {
        // implies that reason is other
        await api(details.reason)
      } else if (selectedOption) {
        await api(selectedOption)
      }
      onDecline()
    },
  })

  const { onChange, value, ...restOfReasonProps } = getFieldProps("reason")

  const handleReasonChange = (inputValue: string) => {
    onChange(inputValue)
  }

  const handleOptionChange = (option: string) => {
    setSelectedOption(option)
    if (option === "Other") {
      onChange("")
    } else {
      onChange(option)
    }
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogHeader>
        <HStack gap="xs" alignItems="center">
          <Txt variant="subtitle2">Is there a reason for declining?</Txt>
        </HStack>
        <HStack width="auto" justifyContent="flex-end" alignItems="center">
          <Link onClick={onClose}>
            {/* Link adds unecessary height so we start at flex end */}
            <VStack justifyContent="flex-end">
              <CrossIcon width={24} height={24} />
            </VStack>
          </Link>
        </HStack>
      </DialogHeader>
      <VStack gap="xl" padding="xl" justifyContent="space-between" height="full">
        <VStack gap="md">
          {options.map((option) => (
            <div key={option}>
              {/* TODO - Move to radio option primitive */}
              {/* Linear ticket: https://linear.app/pomelo/issue/FE-752/create-radio-option-multiple-choice-primitive */}
              <label className={labelSprinkles}>
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={() => handleOptionChange(option)}
                  className={radioStyles}
                />
                {option}
              </label>
            </div>
          ))}
          {selectedOption === "Other" && (
            <TextField
              label="Reason for declining"
              type="text"
              {...restOfReasonProps}
              value={value ?? ""}
              placeholder="Type your reason here"
              onChange={handleReasonChange}
              multiline={true}
              numberOfLines={5}
            />
          )}
        </VStack>
        <VStack gap="xs">
          <Button
            variant="filled"
            onClick={submit}
            state={selectedOption === null ? "disabled" : "active"}
          >
            Continue
          </Button>
          <Button variant="text" onClick={onDecline}>
            Skip
          </Button>
        </VStack>
      </VStack>
    </Dialog>
  )
}
