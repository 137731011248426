import { Stack, Txt } from "@pomebile/primitives"
import { BackgroundColors, TxtColors } from "@pomebile/primitives/tokens"

export interface IdentifierTagProps {
  children: string
  variant?: "primary" | "secondary" | "default"
  textVariant?: "body2" | "caption" | "caption-emphasis"
}

export const IdentifierTag = ({
  children,
  variant = "default",
  textVariant = "body2",
}: IdentifierTagProps) => {
  let fill: BackgroundColors
  let color: TxtColors
  if (variant === "primary") {
    fill = "background-callout-primary"
    color = "text-brand"
  } else if (variant === "secondary") {
    fill = "background-callout-success"
    color = "text-emphasis"
  } else {
    fill = "background-callout-default"
    color = "text-default"
  }

  return (
    <Stack
      fill={fill}
      width="fitContent"
      borderRadius="xs"
      padding={{ x: "xs2" }}
      justifyContent="center"
    >
      <Txt color={color} textAlign="center" variant={textVariant}>
        {children}
      </Txt>
    </Stack>
  )
}
