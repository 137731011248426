import { HStack } from "@pomebile/primitives"
import { stickyTop } from "./Dialog.css"
import { BorderProp } from "../../../../packages/primitives/tokens/border"

type DialogHeaderVariant = "default" | "noBorder"

type DialogHeaderProps = {
  children?: React.ReactNode
  variant?: DialogHeaderVariant
}

export const DialogHeader = ({ children, variant = "default" }: DialogHeaderProps) => {
  return (
    <div className={stickyTop}>
      <HStack
        justifyContent="space-between"
        border={
          variant === "noBorder" ? undefined : { widths: { bottom: "thin" }, color: "border-main" }
        }
        padding={{ x: "lg", y: "md" }}
      >
        {children}
      </HStack>
    </div>
  )
}
