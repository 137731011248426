import type { StackComponent, DStackComponent } from "@pomebile/primitives"
import { sprinkles } from "../../styles/sprinkles.css"
import {
  BorderLongForm,
  BorderWidth,
  BorderWidthSide,
  BorderWidths,
} from "@pomebile/primitives/tokens"

/**
 * Removes `readonly` and adds `?` to all properties
 */
type MutablePartial<T> = { -readonly [P in keyof T]?: T[P] }
type PaddingSprinkles = MutablePartial<
  Pick<
    Parameters<typeof sprinkles>[0],
    | "padding"
    | "paddingBottom"
    | "paddingLeft"
    | "paddingRight"
    | "paddingTop"
    | "paddingX"
    | "paddingY"
  >
>

export const Stack: StackComponent = ({
  direction = "column",
  alignItems,
  justifyContent,
  borderRadius,
  fill,
  children,
  gap,
  padding,
  border,
  width = "full",
  height,
  flex,
}) => {
  let borderStyle, borderColor, borderWidth
  const isBorderLongForm = border && !Array.isArray(border)
  const borderWidths: Record<BorderWidthSide, BorderWidth> = {
    borderBottomWidth: "none",
    borderLeftWidth: "none",
    borderRightWidth: "none",
    borderTopWidth: "none",
  }

  // Dev supplied short-form pattern
  if (Array.isArray(border)) {
    // Shortform implies adding borders to all sides
    borderWidth = border[0]
    borderColor = border[1]
    borderStyle = border[2]
  }

  if (undefined === borderColor) borderColor = (border as BorderLongForm)?.color
  if (isBorderLongForm) {
    const widths: BorderWidths | undefined = (border as BorderLongForm)?.widths
    if (widths?.top) borderWidths.borderTopWidth = widths?.top
    if (widths?.right) borderWidths.borderRightWidth = widths?.right
    if (widths?.bottom) borderWidths.borderBottomWidth = widths?.bottom
    if (widths?.left) borderWidths.borderLeftWidth = widths?.left
  }

  const paddings: PaddingSprinkles = {}
  if (typeof padding === "string") {
    paddings.padding = padding
  } else if (typeof padding === "object") {
    paddings.paddingTop = padding.top
    paddings.paddingRight = padding.right
    paddings.paddingBottom = padding.bottom
    paddings.paddingLeft = padding.left
    paddings.paddingX = padding.x
    paddings.paddingY = padding.y
  }

  return (
    <div
      className={`${sprinkles({
        display: "flex",
        flexDirection: direction,
        alignItems,
        borderRadius,
        borderStyle: borderStyle || "solid",
        borderColor: borderColor || "border-main",
        borderWidth: borderWidth || "none",
        ...(isBorderLongForm ? borderWidths : undefined),
        background: fill,
        width,
        height,
        gap,
        justifyContent,
        flex,
      })} ${sprinkles(paddings)}`}
    >
      {children}
    </div>
  )
}

export const VStack: DStackComponent = (props) => {
  return <Stack {...props} direction="column" />
}

export const HStack: DStackComponent = (props) => {
  return <Stack {...props} direction="row" />
}
