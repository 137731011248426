import * as Yup from "yup"
import * as V from "./formValidation/validationMessages"
import { regexForName } from "./formValidation/validators"

export const phoneNumber = Yup.string()
  .required(V.MSG_REQUIRED)
  .max(12, V.MSG_INVALID_MOBILE_NUMBER)
  .min(12, V.MSG_INVALID_MOBILE_NUMBER)
  .matches(/^\d{3}-\d{3}-\d{4}$/, V.MSG_INVALID_MOBILE_NUMBER)

// 63 chars limit - 2 extra chars = 61 char max
// since + is %2B and @ is %40 in URL-encoding (1 char --> 3 chars)
// currently just assuming no '+' and one '@' for simplicity!
export const email = Yup.string()
  .email(V.MSG_EMAIL)
  .matches(/^.+@.+\..+$/, V.MSG_EMAIL) // Make sure there's a TLD
  .max(61, V.MSG_MAX_CHARACTERS(61))
  .required(V.MSG_REQUIRED)

export const nameSchema = Yup.string()
  .min(2, V.MSG_MIN_CHARACTERS(2))
  .max(40, V.MSG_MAX_CHARACTERS(40))
  .matches(regexForName, V.MSG_INVALID_CHARACTERS)
  .required(V.MSG_REQUIRED)
