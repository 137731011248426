import { Svg, Path, G } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

type ChevronIconProps = IconProps & {
  direction?: "left" | "right" | "down" | "up"
}

export const ChevronIcon = ({
  height = 24,
  width = 24,
  fill,
  direction = "right",
}: ChevronIconProps) => {
  let rotation
  switch (direction) {
    case "left":
      rotation = "90"
      break
    case "right":
      rotation = "-90"
      break
    case "up":
      rotation = "180"
      break
    case "down":
    default:
      rotation = "0"
      break
  }
  return (
    <Svg width={width} height={height} customFill="none" viewBox="0 0 24 24">
      <G transform={`rotate(${rotation}, 12, 12)`}>
        <Path
          fill={fill}
          d="M17.5303 10.4697C17.8232 10.7626 17.8232 11.2374 17.5303 11.5303L12.8839 16.1768C12.3957 16.6649 11.6043 16.6649 11.1161 16.1768L6.46967 11.5303C6.17678 11.2374 6.17678 10.7626 6.46967 10.4697C6.76256 10.1768 7.23744 10.1768 7.53033 10.4697L12 14.9393L16.4697 10.4697C16.7626 10.1768 17.2374 10.1768 17.5303 10.4697Z"
        />
      </G>
    </Svg>
  )
}
