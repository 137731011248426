/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { UserSignupMetadataDto } from "./UserSignupMetadataDto"

export interface SignUpRequest {
  /**
   * Workaround to control sign up flow
   */
  /**
   * @deprecated
   */
  accountType?: SignUpRequestAccountTypeEnum
  /**
   * Client type which was used to request the signup. If it is null, the 'client-type' header will be used if it exists
   */
  /**
   * @deprecated
   */
  clientType?: SignUpRequestClientTypeEnum
  /**
   * Session ID from web onboarding
   */
  deviceId?: string
  /**
   * Email entered in sign up request
   */
  email?: string
  /**
   * Does not require explicit opt-in
   */
  emailSubscriptionStatus?: SignUpRequestEmailSubscriptionStatusEnum
  /**
   * First name of user
   */
  firstName?: string
  /**
   * Unique transaction ID
   */
  id?: string
  /**
   * Last name of user
   */
  lastName?: string
  /**
   * Link ID (optional)
   */
  /**
   * @deprecated
   */
  linkIdent?: string
  metadata?: UserSignupMetadataDto
  /**
   * Middle name of user
   */
  middleName?: string
  /**
   * Optional referrer code
   */
  referralCode?: string
  /**
   * Requires explicit opt-in
   */
  smsSubscriptionStatus?: SignUpRequestSmsSubscriptionStatusEnum
  /**
   * If a user validates a phone number through SMS OTP, they will receive a temporary token that when passed in, will allow the user to bypass phone verification again
   */
  smsTempToken: string
}

export const SignUpRequestAccountTypeEnum = {
  Remitter: "REMITTER",
  Recipient: "RECIPIENT",
} as const
export type SignUpRequestAccountTypeEnum =
  (typeof SignUpRequestAccountTypeEnum)[keyof typeof SignUpRequestAccountTypeEnum]

export const SignUpRequestClientTypeEnum = {
  Web: "WEB",
  App: "APP",
  WebOnboarding: "WEB-ONBOARDING",
  Undefined: "UNDEFINED",
} as const
export type SignUpRequestClientTypeEnum =
  (typeof SignUpRequestClientTypeEnum)[keyof typeof SignUpRequestClientTypeEnum]

export const SignUpRequestEmailSubscriptionStatusEnum = {
  Subscribed: "Subscribed",
  Unsubscribed: "Unsubscribed",
  Unknown: "Unknown",
} as const
export type SignUpRequestEmailSubscriptionStatusEnum =
  (typeof SignUpRequestEmailSubscriptionStatusEnum)[keyof typeof SignUpRequestEmailSubscriptionStatusEnum]

export const SignUpRequestSmsSubscriptionStatusEnum = {
  Subscribed: "Subscribed",
  Unsubscribed: "Unsubscribed",
  Unknown: "Unknown",
} as const
export type SignUpRequestSmsSubscriptionStatusEnum =
  (typeof SignUpRequestSmsSubscriptionStatusEnum)[keyof typeof SignUpRequestSmsSubscriptionStatusEnum]
