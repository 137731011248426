/* eslint-disable */
import { Button, HStack, Link, Txt, VStack } from "@pomebile/primitives"
import { MutableRefObject, useRef, useState } from "react"
import { devMenuButtonStyle } from "./DevTools.css"
// TODO uncomment once sentry PR is merged
import { useReportDialogTrigger } from "../utils/sentry"
import { Dialog } from "./Dialog"
// import { ChevronIcon } from "design-system"

export const DEBUG_STORAGE_KEY = "_app_state"
export const DEBUG_RESTORE_KEY = "_app_state_restore"

export function DevTools({ state }: { state: object }) {
  const [open, setOpen] = useState(false)
  const [envExpanded, setEnvExpanded] = useState(false)
  const [current, setCurrent] = useState<object | null>(() => {
    const saved = localStorage.getItem(DEBUG_STORAGE_KEY)
    return saved && JSON.parse(saved)
  })

  const triggerReportDialog = useReportDialogTrigger()

  const el: MutableRefObject<HTMLTextAreaElement | null> = useRef<HTMLTextAreaElement>(null)
  return (
    <div className={devMenuButtonStyle}>
      <VStack
        borderRadius="md"
        fill={open ? "background-default" : undefined}
        padding={open ? "sm" : "unset"}
        gap="md"
      >
        <Button key={"open/close"} onClick={() => setOpen((e) => !e)}>
          {open ? "Close" : "Dev"}
        </Button>
        <Dialog open={open} onClose={() => setOpen((e) => !e)}>
          <div style={{ overflow: "auto" }}>
            <VStack padding="sm" gap="sm">
              <HStack gap="md">
                <Button
                  onClick={() => {
                    const content = JSON.stringify(state, null, 2)
                    localStorage.setItem(DEBUG_STORAGE_KEY, content)
                    setCurrent(state)
                    if (el.current) {
                      el.current.value = content
                    }
                  }}
                >
                  Save State
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    localStorage.removeItem(DEBUG_STORAGE_KEY)
                    setCurrent(null)
                    if (el.current) {
                      el.current.value = ""
                    }
                  }}
                >
                  Clear
                </Button>
              </HStack>
              <hr />
              {
                <textarea
                  ref={el}
                  onChange={(e) => {
                    const serialized = e.target.value
                    try {
                      setCurrent(JSON.parse(serialized))
                      localStorage.setItem(DEBUG_STORAGE_KEY, serialized)
                    } catch (e) {
                      console.log("DevTools: saving failed")
                    }
                  }}
                  style={{ width: "100%", height: "200px" }}
                  rows={20}
                >
                  {current ? JSON.stringify(current, null, 2) : ""}
                </textarea>
              }
              <HStack gap="md">
                <Button
                  state={current ? "active" : "disabled"}
                  onClick={() => {
                    localStorage.setItem(DEBUG_RESTORE_KEY, "yes")
                    window.location.reload()
                  }}
                >
                  Restore State
                </Button>
                <Button
                  variant="outline"
                  state={current ? "active" : "disabled"}
                  onClick={() => {
                    if (current) {
                      navigator.clipboard.writeText(JSON.stringify(current, null, 2))
                    }
                  }}
                >
                  Copy
                </Button>
              </HStack>
              <hr />
              <VStack gap="xs3">
                <HStack gap="md" alignItems="flex-start">
                  <Txt>ENV MODE="{import.meta.env.MODE}"</Txt>
                  <Link onClick={() => setEnvExpanded(!envExpanded)}>
                    {envExpanded ? "hide all" : "show all"}
                  </Link>
                </HStack>
                <div style={{ overflowX: "auto" }}>
                  <VStack gap="xs3">
                    {envExpanded &&
                      Object.entries(import.meta.env).map(([key, value]) => (
                        <Txt key={key}>{`${key}="${value}"`}</Txt>
                      ))}
                  </VStack>
                </div>
              </VStack>
              <HStack gap="md">
                <Button
                  onClick={() => {
                    triggerReportDialog()
                  }}
                >
                  Report Bug
                </Button>

                <Button key={"close"} onClick={() => setOpen((e) => !e)}>
                  {open ? "Close" : "Dev"}
                </Button>
              </HStack>
            </VStack>
          </div>
        </Dialog>
      </VStack>
    </div>
  )
}
