// Route elements
import { Phone } from "./screens/Phone.tsx"
import { OTPScreen } from "./screens/OTP.tsx"
import {
  NrauAppEvent,
  NrauAppScreen,
  NrauAppState,
  calculateNrauScreen,
} from "./nrauOnboardingModel.ts"
import {
  AppApiContext,
  authenticateSmsOtp,
  generateSmsOtp,
  addNrauSignUp,
  generateVeriffSession,
  getActiveVeriffSessionUsingPost,
  queryVeriffDecision,
  updatePHAddress,
  submitCardConfiguration,
  updateUSAddress,
} from "./api/webRoutes.tsx"
import { match } from "@pomebile/shared/tagged-union"

import { AuthContext, AuthData } from "./api/authContext.ts"
import { DEBUG_RESTORE_KEY, DEBUG_STORAGE_KEY } from "./components/DevTools.tsx"
import { LoggingContext } from "./utils/logging.tsx"
import { NrauInvitationIntroScreen } from "./screens/NrauInvitationIntro.tsx"
import { NrauUserInfoScreen } from "./screens/NrauUserInfo.tsx"
import { PHHomeAddress } from "./screens/NrauHomeAddress.tsx"
import { Veriff } from "./screens/Veriff.tsx"
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk"
import { CardSelector } from "./screens/CardSelector/CardSelector.tsx"
import { NrauCompleteScreen } from "./screens/NrauComplete.tsx"
import {
  InitialData,
  Progression,
  createAuthContext,
  createLoggingContext,
} from "./sharedShellLogic.tsx"
import { NrauInvalidInviteScreen } from "./screens/NrauInvalidInvite.tsx"
import { USHomeAddress } from "./screens/HomeAddress.tsx"
import { TimeLimitErrorScreen } from "./screens/TimeLimitError.tsx"
import { GeneralErrorScreen } from "./screens/GeneralError.tsx"
import { ErrorData } from "./api/errorContext.ts"
import { VeriffSdkResponse } from "./screens/Veriff/rpcVeriffStateMachine.ts"

type ShellState = {
  state: NrauAppState
  screens: NrauAppScreen[]
}

// export type Progression = "join" | "verify" | "get-card" | "none"

// export const screenProgressionMap: Record<NrauScreenKind, Progression> = {
//   NrauInvitationIntro: "none",
//   NrauUserInfo: "join",
//   OTP: "none",
//   NrauVerifyIdentity: "verify",
//   NrauPostVerificationIntro: "get-card",
//   NrauHomeAddress: "get-card",
//   CardSelector: "get-card",
//   NrauComplete: "none",
//   InvalidInvite: "none",
//   GeneralError: "none",
// }

// TODO Replace with above once we can support a different progression on the AppShell component
export const calculateNrauProgression = (_screen: NrauAppScreen): Progression => "none"

export const renderNrauScreen = (
  screen: NrauAppScreen,
  send: (ev: NrauAppEvent) => void,
  apiCx: AppApiContext,
  authCx: AuthContext,
): JSX.Element =>
  match(
    screen,
    {
      NrauInvitationIntro: () => (
        <NrauInvitationIntroScreen
          api={{
            acceptInvite: () => {},
          }}
          onDone={() => {
            send(NrauAppEvent.InvitationAccepted())
          }}
        />
      ),

      Phone: ({ country }) => (
        <Phone
          country={country}
          onDone={(_country, phoneNumber) => send(NrauAppEvent.EnteredPhoneNumber({ phoneNumber }))}
        />
      ),

      OTP: ({ country, phoneNumber }) => (
        <OTPScreen
          api={{
            requestOTPCode: () => generateSmsOtp(apiCx, country, phoneNumber),
            verifyOTPCode: (code) => authenticateSmsOtp(code, country, phoneNumber, apiCx),
          }}
          phoneNumber={phoneNumber}
          country={country}
          onDone={(authResult) => {
            const { tag } = authResult
            if (tag === "disabledUser") {
              send(NrauAppEvent.AuthCompleted({ authResult: { tag: "disabledUser" }, phoneNumber }))
            } else {
              send(NrauAppEvent.AuthCompleted({ authResult, phoneNumber }))
            }
          }}
        />
      ),

      NrauUserInfo: ({ inviteCode, smsTempToken, phoneNumber, country }) => (
        <NrauUserInfoScreen
          country={country}
          phoneNumber={phoneNumber}
          inviteCode={inviteCode}
          api={{
            nrauSignup: (details) => addNrauSignUp(smsTempToken, details, inviteCode, apiCx),
          }}
          onDone={(outcome) => {
            if (outcome.tag === "success") {
              const { collectedDetails, signUpResult } = outcome
              send(
                NrauAppEvent.SignedUp({
                  personalInfo: { ...collectedDetails, phoneNumber, country },
                  signUpResult: signUpResult,
                }),
              )
            } else if (outcome.tag === "invalidInvite") {
              send(NrauAppEvent.EncounteredInvalidInvite())
            }
          }}
        />
      ),

      NrauVerifyIdentity: ({ auth }) => (
        <Veriff
          api={{
            generateVeriffSession: () => generateVeriffSession(auth.userIdent, apiCx, auth, authCx),
            getActiveVeriffSession: () =>
              getActiveVeriffSessionUsingPost(auth.userIdent, apiCx, auth, authCx),
            queryDecision: async () => {
              // Fetch the result of the user's Veriff session
              const veriffResponse = await queryVeriffDecision(auth.userIdent, apiCx, auth, authCx)

              if (veriffResponse.tag === "requires_resubmission") {
                return {
                  tag: "requires_resubmission",
                  reason: veriffResponse.reason,
                }
              }

              return { tag: "approved" }
            },
            startVeriffSdk: (url) => {
              const veriffResultProm = new Promise<VeriffSdkResponse>((resolve) => {
                createVeriffFrame({
                  url,
                  onEvent: (ev) => {
                    switch (ev) {
                      case MESSAGES.FINISHED:
                        resolve({ type: "finished" })
                        break
                      case MESSAGES.RELOAD_REQUEST:
                        resolve({ type: "reload_required" })
                        break
                      case MESSAGES.CANCELED:
                        resolve({ type: "user_cancelled" })
                    }
                  },
                  onReload: () => {
                    resolve({ type: "reload_required" })
                  },
                })
              })
              return veriffResultProm
            },
          }}
          onDone={(_outcome) => {
            // For now, we don't need to validate an NRAU's Veriff session. At this point, regardless of the result, we are pushing NRAUs through the flow and prompting them to download the app. They can log in, but when they do, they'll be prompted by a callout telling them to contact support.
            // When the need arises, we can expand on this by adding logic to handle their Veriff session result.
            send(NrauAppEvent.VerifiedIdentity())
          }}
        />
      ),
      NrauHomeAddress: ({ country, auth }) =>
        country === "PH" ? (
          <PHHomeAddress
            api={{
              addPHAddress: (address) => {
                return updatePHAddress(auth.userIdent, address, apiCx, auth, authCx)
              },
            }}
            onDone={(address) => {
              // send(NrauAppEvent.VerifiedIdentity({ personalInfo: collectedDetails, signUpResult: signUpResult }))
              // Where do we send here?
              send(NrauAppEvent.AddedAddress(address))
            }}
          />
        ) : (
          <USHomeAddress
            api={{
              addUSAddress: (address) => {
                return updateUSAddress(auth.userIdent, address, apiCx, auth, authCx)
              },
            }}
            onDone={(address) => {
              // send(NrauAppEvent.VerifiedIdentity({ personalInfo: collectedDetails, signUpResult: signUpResult }))
              // Where do we send here?
              send(NrauAppEvent.AddedAddress(address))
            }}
          />
        ),

      CardSelector: ({ personalInfo, auth, address }) => (
        <CardSelector
          api={{
            submitCardConfiguration: async (cardId: string) =>
              submitCardConfiguration(
                {
                  cardId,
                  userIdent: auth.userIdent,
                },
                apiCx,
                auth,
                authCx,
              ),
          }}
          nameOnCard={`${personalInfo.firstName} ${personalInfo.lastName}`}
          address={address}
          cardOptions={[
            { cardConfigId: "pom_pink", color: "pink" },
            { cardConfigId: "pom_green", color: "green" },
            { cardConfigId: "pom_white", color: "white" },
          ]}
          onDone={(cardColor) => send(NrauAppEvent.SelectedCard(cardColor))}
        />
      ),

      NrauComplete: () => <NrauCompleteScreen />,

      InvalidInvite: () => <NrauInvalidInviteScreen />,

      TimeLimitError: ({ email }) => <TimeLimitErrorScreen email={email} />,

      GeneralError: () => <GeneralErrorScreen />,
    },

    // There shouldn't ever be a case here, but just in case...
    (_) => <GeneralErrorScreen />,
  )

export type NrauAppApiContext = Omit<AppApiContext, "inviteIdent"> & {
  inviteCode: string
}

export function initNRAUOnboarding(
  apiCx: NrauAppApiContext,
  isDevToolsEnabled: boolean,
): InitialData<NrauAppState, NrauAppScreen> {
  // TODO next person to add a logger must convert this to an array
  const logger: LoggingContext = createLoggingContext()

  if (isDevToolsEnabled) {
    const restore = localStorage.getItem(DEBUG_RESTORE_KEY) === "yes"
    localStorage.removeItem(DEBUG_RESTORE_KEY)

    if (restore) {
      const serialized = localStorage.getItem(DEBUG_STORAGE_KEY)
      if (serialized) {
        const { state, apiCx }: { state: NrauAppState; apiCx: AppApiContext } =
          JSON.parse(serialized)

        return {
          initialState: { state, screens: [calculateNrauScreen(state)] },
          apiCx,
          authCx: createAuthContext(apiCx),
          logging: logger,
        }
      }
    }
  }

  const introState = NrauAppState.Intro({
    inviteCode: apiCx.inviteCode,
    phoneNumber: apiCx.phoneNumber,
    country: apiCx.country,
  })

  const initialState: ShellState = {
    state: introState,
    screens: [calculateNrauScreen(introState)],
  }

  return {
    initialState,
    apiCx,
    authCx: createAuthContext(apiCx),
    logging: logger,
  }
}

export const getNRAUAuth = (state: NrauAppState): AuthData | undefined => {
  // TODO Some of these states are auth'ed, but unlikely to not go through an auth'ed state first
  return match(
    state,
    {
      Intro: () => undefined,
      TimeoutError: (): AuthData | undefined => undefined,
      GeneralError: () => undefined,
      Initial: () => undefined,
      Authenticating: () => undefined,
      InviteError: () => undefined,
      Complete: () => undefined,
      Joining: () => undefined,
    },
    ({ data: { auth } }) => auth,
  )
}

export const getNRAUError = (event: NrauAppEvent): ErrorData | undefined => {
  return match(
    event,
    {
      TimeLimitExceeded: ({ errorType }): ErrorData | undefined => ({ errorType }),
      EncounteredGeneralError: ({ errorType }): ErrorData | undefined => ({ errorType }),
    },
    () => undefined,
  )
}
