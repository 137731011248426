import * as Yup from "yup"
import { HStack, Link, Secure, Txt, VStack } from "@pomebile/primitives"
import { ChevronIcon, PHFlagIcon, TextField, USFlagIcon } from "@pomebile/design-system"
import { Listbox } from "@headlessui/react"
import { ScreenForm } from "../components/ScreenForm"
import { useForm } from "../components/Form/useForm"
import { StickyBottom } from "../components/StickyBottom"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { phoneNumber } from "../utils/schema"
import { extractDigits } from "../utils/string"
import { Country } from "../api/webRoutes"
import { handlePhoneNumberChange } from "../utils/callback"
import * as V from "../utils/formValidation/validationMessages"
import {
  buttonStyles,
  countryNameStyles,
  listboxStyles,
  optionStyles,
  optionsStyles,
} from "./Phone.css"

interface CountryItem {
  name: string
  phoneCode: number
  icon: JSX.Element
}

const countryOptions: Record<Country, CountryItem> = {
  US: { name: "United States", phoneCode: 1, icon: <USFlagIcon /> },
  PH: { name: "Philippines", phoneCode: 63, icon: <PHFlagIcon width={24} height={24} /> },
}

export const PhoneSchema = Yup.object({
  country: Yup.string().oneOf(Object.keys(countryOptions)).required(V.MSG_REQUIRED),
  phoneNumber,
})

interface PhoneProps {
  country?: Country
  onDone: (country: Country, phoneNumber: string) => void
}

export function Phone({ country, onDone }: PhoneProps) {
  const [submit, getFieldProps, status, { isFormValid }] = useForm({
    name: "Login Phone Input", // Note: Must match 1.0 name for analytics
    schema: PhoneSchema,
    initial: { country: "PH", phoneNumber: "" },
    submit: async ({ country: countryValue, phoneNumber }) => {
      onDone(country ?? (countryValue as Country), extractDigits(phoneNumber))
    },
  })

  const countryValue = getFieldProps("country").value as Country
  const { onChange: onChangePhoneNumber, ...restOfPhoneNumberProps } = getFieldProps("phoneNumber")
  const listboxChildren = (({ open }: { open?: boolean }) => (
    <>
      <Listbox.Button className={buttonStyles}>
        <CountryCodeDisplay country={countryValue} showDropdown isDropdownOpen={open} />
      </Listbox.Button>
      <Listbox.Options className={optionsStyles}>
        {Object.keys(countryOptions).map((country) => (
          <Listbox.Option key={country} value={country} className={optionStyles}>
            <CountryCodeOption country={country as Country} />
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </>
  )) as never

  return (
    <ScreenForm onSubmit={submit}>
      <VStack gap="xl2">
        <VStack gap="xs">
          <Txt variant="headline2" as="h1">
            Enter your mobile number
          </Txt>
          <Txt>We’ll send you a one-time 6-digit code to this number.</Txt>
        </VStack>
        <Secure>
          <TextField
            label="Mobile Number"
            type="tel"
            placeholder="000-000-0000"
            inputAdornment={
              country ? (
                <CountryCodeDisplay country={country} />
              ) : (
                <Listbox
                  as={"div" as never}
                  value={countryValue as never}
                  onChange={getFieldProps("country").onChange as never}
                  className={listboxStyles as never}
                >
                  {listboxChildren}
                </Listbox>
              )
            }
            onChange={handlePhoneNumberChange(onChangePhoneNumber, "-")}
            {...restOfPhoneNumberProps}
          />
        </Secure>
        <Txt variant="caption" color="text-caption">
          By tapping continue, you consent to receiving one-time passcodes via text. Msg & data
          rates may apply. See our&nbsp;
          <Link
            url="https://www.pomelo.com/terms-of-service"
            target="_blank"
            decoration="underline"
            color="neutral"
          >
            Terms of Service
          </Link>
          &nbsp;and&nbsp;
          <Link
            url="https://www.pomelo.com/privacy"
            target="_blank"
            decoration="underline"
            color="neutral"
          >
            Privacy Policy
          </Link>
          .
        </Txt>
      </VStack>

      <StickyBottom>
        <FormSubmitButton disabled={!isFormValid} status={status}>
          Continue
        </FormSubmitButton>
      </StickyBottom>
    </ScreenForm>
  )
}

function CountryCodeDisplay({
  country,
  showDropdown = false,
  isDropdownOpen = false,
}: {
  country: Country
  showDropdown?: boolean
  isDropdownOpen?: boolean
}) {
  const { phoneCode, icon } = countryOptions[country]
  return (
    <HStack gap="xs" alignItems="center">
      <HStack alignItems="center">
        {icon}
        {showDropdown && (
          <ChevronIcon height={18} width={18} direction={isDropdownOpen ? "up" : "down"} />
        )}
      </HStack>
      <Txt>+{phoneCode}&nbsp;</Txt>
    </HStack>
  )
}

function CountryCodeOption({ country }: { country: Country }) {
  const { name, phoneCode, icon } = countryOptions[country]
  return (
    <HStack gap="xs" alignItems="center">
      {icon}
      <Txt>
        <span className={countryNameStyles}>{name}</span>
      </Txt>
      <Txt color="text-caption">+{phoneCode}</Txt>
    </HStack>
  )
}
