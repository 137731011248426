import {
  ChevronIcon,
  PHFlagRectIcon,
  PomeloCardIcon,
  Callout,
  PHFlagIcon,
} from "@pomebile/design-system"
import { Avatar, HStack, Link, Txt, VStack } from "@pomebile/primitives"
import { sprinkles } from "@pomebile/primitives-web"
import { StickyBottom } from "../../components/StickyBottom"
import { RatesAndTermsDialog } from "./RatesAndTermsDialog"
import { useReducer } from "react"
import { NewAccountResponse, Promo } from "../../api/webRoutes"
import { useSubmit } from "../../hooks/useSubmit"
import { SubmitButton } from "../../components/Form/FormSubmitButton"
import { ScreenFrame } from "../../components/ScreenFrame"
import { TermsAndConditionsDialog } from "../../components/TermsAndConditionsDialog"
import { DeclineDialog } from "../UnsecuredOffer/DeclineDialog"
import { DeclineReasonDialog } from "../DeclineReasonDialog"
import { UserResponse } from "@pomebile/pomelo-service-api"

interface ItemProps {
  icon: JSX.Element
  title: string
  subtitle: string
  bottomElement?: JSX.Element
}

type DialogKind =
  | "ratesAndTermsDialog"
  | "termsAndConditionsDialog"
  | "declineDialog"
  | "declineReasonDialog"

type Ev = {
  action: "open" | "close"
  dialog: DialogKind
}
type DialogState = {
  dialog: DialogKind | undefined // undefined expresses that no dialog is opened
  prevDialog: DialogKind | undefined
}

const updateDialogState = (prevState: DialogState, ev: Ev): DialogState => {
  const { action, dialog } = ev

  if (action === "open") {
    return { prevDialog: prevState.dialog, dialog }
  }

  if (prevState.dialog !== undefined && action === "close" && dialog === prevState.dialog) {
    if (dialog === "termsAndConditionsDialog") {
      return { ...prevState, dialog: prevState.prevDialog }
    }

    return { ...prevState, dialog: undefined }
  }

  return prevState
}

type AcceptResult =
  | {
      tag: "accepted"
      productGroupIdent: string
      updatedPromos: Promo[]
    }
  | {
      tag: "declined"
    }
  | {
      tag: "noProductGroupIdent"
    }

export interface SecuredOfferProps {
  api: {
    openAccount: () => Promise<NewAccountResponse>
    declineReason: (reason: string) => Promise<UserResponse>
  }
  promos: Promo[]
  onDone: (result: AcceptResult) => void
}

export const SecuredOffer = ({ api, promos, onDone }: SecuredOfferProps) => {
  const [{ dialog }, send] = useReducer(updateDialogState, {
    dialog: undefined,
    prevDialog: undefined,
  })
  const [submit, status] = useSubmit(async () => {
    try {
      const { productGroupIdent, updatedPromos } = await api.openAccount()
      onDone({
        tag: "accepted",
        productGroupIdent,
        updatedPromos,
      })
    } catch (ResponseValidationError) {
      onDone({
        tag: "noProductGroupIdent",
      })
      return
    }
  })

  const openDialog = (dialog: DialogKind) => {
    send({ action: "open", dialog })
  }

  const closeDialog = (dialog: DialogKind) => {
    send({ action: "close", dialog })
  }

  const pomeloSecuredMastercardItem: ItemProps = {
    icon: <PomeloCardIcon />,
    title: "Get a Pomelo Secured Mastercard® to help build credit",
    subtitle:
      "Based on your application and credit history, you’ve been approved for a Pomelo Secured Mastercard®️. Get your card by accepting this plan and downloading the Pomelo app to make a security deposit.",
    bottomElement: (
      <Link as="div" onClick={() => openDialog("ratesAndTermsDialog")}>
        <HStack justifyContent="center" alignItems="center">
          <Txt variant="button2" color="text-brand">
            View Rates and Terms
          </Txt>
          <ChevronIcon fill="icon-brand" direction="right" />
        </HStack>
      </Link>
    ),
  }

  const itemData: ItemProps[] = []

  const moneyTransferPromo = promos.find((promo) => promo.type === "MTP_RATE" && promo.active)
  if (moneyTransferPromo) {
    itemData.push({
      icon: <PHFlagIcon width={24} height={24} />,
      title: "Send money to the Philippines",
      subtitle: "Take advantage of zero transfer fees when you send to GCash or Bank Accounts.",
      bottomElement: (
        <VStack width="full">
          <Callout variant="success">
            <VStack alignItems="center">
              <HStack alignItems="center" width="unset">
                <Txt color="text-default" variant="body2">
                  $1 =&nbsp;
                </Txt>
                <div className={sprinkles({ overflow: "hidden", borderRadius: "xs" })}>
                  <PHFlagRectIcon width={16} height={12} />
                </div>
                <Txt color="text-default" variant="body2">
                  &nbsp;₱{moneyTransferPromo.rate}
                </Txt>
              </HStack>
              <Txt color="text-default" variant="caption">
                For your first transfer up to ${moneyTransferPromo.limit}
              </Txt>{" "}
              <Link
                color="neutral"
                decoration="underline"
                onClick={() => openDialog("termsAndConditionsDialog")}
              >
                <Txt color="text-default" variant="caption">
                  See Terms and Conditions
                </Txt>
              </Link>
            </VStack>
          </Callout>
        </VStack>
      ),
    })
  }

  itemData.push(pomeloSecuredMastercardItem)

  return (
    <ScreenFrame>
      <VStack justifyContent="space-between">
        <VStack height="full" gap="xl2" padding={{ bottom: "xl" }}>
          <VStack>
            <Txt variant="subtitle2" color="text-emphasis" textAlign="center">
              You're Approved
            </Txt>
            <Txt variant="headline2" textAlign="center">
              Pomelo Credit Builder
            </Txt>
            <Txt variant="body2" textAlign="center">
              Pomelo Credit Builder can help you build your credit while sending money to the
              Philippines.
            </Txt>
          </VStack>

          <VStack gap="lg">
            {itemData.map(({ icon, title, subtitle, bottomElement }, index) => (
              <VStack
                key={index}
                padding="lg"
                alignItems="center"
                gap="sm"
                borderRadius="md"
                border={{
                  color: "border-main",
                  style: "solid",
                  widths: {
                    top: "thin",
                    right: "thin",
                    bottom: "thin",
                    left: "thin",
                  },
                }}
              >
                <Avatar size="lg" svg={icon} />
                <VStack gap="xs2">
                  <Txt variant="subtitle2" color="text-default" textAlign="center">
                    {title}
                  </Txt>
                  <Txt variant="body2" color="text-default" textAlign="center">
                    {subtitle}
                  </Txt>
                </VStack>
                {bottomElement}
              </VStack>
            ))}
            <Txt variant="caption" color="text-caption" textAlign="center">
              By accepting the offer you authorize Pomelo to do a full credit inquiry when you make
              your security deposit in the Pomelo app. This may affect your credit score.
            </Txt>
          </VStack>
        </VStack>

        <StickyBottom>
          <VStack gap="sm">
            <SubmitButton submit={submit} status={status}>
              Accept Plan
            </SubmitButton>
            <Link onClick={status === "idle" ? () => openDialog("declineReasonDialog") : undefined}>
              <Txt textAlign="center" color="text-brand" as="p">
                Decline
              </Txt>
            </Link>
          </VStack>
        </StickyBottom>

        <RatesAndTermsDialog
          isOpen={dialog === "ratesAndTermsDialog"}
          onClose={() => closeDialog("ratesAndTermsDialog")}
          onOpenTermsAndConditions={() => openDialog("termsAndConditionsDialog")}
        />

        <TermsAndConditionsDialog
          open={dialog === "termsAndConditionsDialog"}
          showMoneyTransferPromo={!!moneyTransferPromo}
          onClose={() => closeDialog("termsAndConditionsDialog")}
        />

        <DeclineReasonDialog
          isOpen={dialog === "declineReasonDialog"}
          onClose={() => closeDialog("declineReasonDialog")}
          onDecline={() => {
            openDialog("declineDialog")
          }}
          api={api.declineReason}
        />

        <DeclineDialog
          isOpen={dialog === "declineDialog"}
          onClose={() => closeDialog("declineDialog")}
          onDecline={() => {
            onDone({ tag: "declined" })
          }}
        />
      </VStack>
    </ScreenFrame>
  )
}
