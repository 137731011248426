/* eslint-disable */
import {
  slideAndFadeOutStyles,
  slideAndFadeInStyles,
  buttonSmallVariants,
  buttonNormalVariants,
} from "./Button.css"
import { Spinner } from "../Spinner/Spinner"
import { RefObject, useLayoutEffect, useReducer } from "react"
import { HStack } from "../Stack/Stack.tsx"
import type { ButtonComponent, ButtonSize, ButtonState, ButtonVariant } from "@pomebile/primitives"

type animationStates = "idle" | "slideOut" | "slideIn"

// type ButtonState = ElementDescription[]

type ElementDescription = {
  snapshot: ButtonVisuals
  animationState: animationStates
}

type ButtonVisuals = {
  variant: ButtonVariant
  state: ButtonState
  text: string
}

type Ev =
  | {
      tag: "propsChanged"
      snapshot: ButtonVisuals
    }
  | {
      tag: "animationEnded"
    }

const encodeVisual = ({ text, state }: ButtonVisuals): string => {
  switch (state) {
    case "disabled":
    case "active":
      return text
    case "loading":
      return "🔄"
    default:
      return `🔄:${state.loadingText}`
  }
}

const shouldTransition = (a: ButtonVisuals, b: ButtonVisuals): boolean =>
  encodeVisual(a) !== encodeVisual(b)

const update = (prev: ElementDescription[], ev: Ev): ElementDescription[] => {
  // console.log("update", prev, ev)
  switch (ev.tag) {
    case "propsChanged": {
      if (shouldTransition(prev[prev.length - 1].snapshot, ev.snapshot)) {
        // old child (first) slides out, new child (ev.description) slides in
        // note that we remove any intermediate states, e.g. we can just skip straight to the last one
        return [
          { snapshot: prev[0].snapshot, animationState: "slideOut" },
          { snapshot: ev.snapshot, animationState: "slideIn" },
        ]
      } else {
        // that means whatever is the last one we can replace it
        // note that it does include the case when we have just one element as well
        // for example: going from "active" -> "disabled"
        const copy = [...prev]
        const prevAnim = copy.pop()?.animationState
        copy.push({
          snapshot: ev.snapshot,
          animationState: prevAnim || "idle",
        })
        return copy
      }
    }

    case "animationEnded": {
      if (prev.length === 1) {
        const { snapshot } = prev[0]
        // the last animation just ended, so enter idle state
        return [{ snapshot, animationState: "idle" }]
      } else {
        const [{ snapshot, animationState }, ...rest] = prev

        switch (animationState) {
          // just finished animating in, so let's start moving out
          case "slideIn":
            return [{ snapshot, animationState: "slideOut" }, ...rest]

          // more animations lined up, so remove current
          case "slideOut":
            return rest

          default:
            return prev
        }
      }
    }
  }
}

export const Button: ButtonComponent = ({
  onClick,
  children,
  "web-type": type,
  variant = "filled",
  state = "active",
  size = "normal",
  innerRef,
}) => {
  const snapshot: ButtonVisuals = {
    variant,
    state,
    text: children,
  }

  const [elements, send] = useReducer(update, [
    {
      snapshot: snapshot,
      animationState: "idle",
    },
  ])

  // console.log("render", elements)

  useLayoutEffect(() => {
    send({
      tag: "propsChanged",
      snapshot,
    })
  }, [variant, children, typeof state === "object" ? state.loadingText : state])

  const { animationState, snapshot: curSnapshot } = elements[0]

  return (
    <button
      data-public={true}
      disabled={elements.length > 1 || curSnapshot.state !== "active"}
      type={type}
      className={calcButtonStyle(variant, state, size)}
      onClick={elements.length > 1 ? undefined : onClick}
      ref={innerRef as RefObject<HTMLButtonElement>}
    >
      <div
        className={calcAnimationStyle(animationState)}
        onAnimationEnd={() => send({ tag: "animationEnded" })}
        style={{ height: "19px" }}
      >
        <HStack justifyContent="center" gap="sm">
          {renderButtonContent(curSnapshot)}
        </HStack>
      </div>
    </button>
  )
}

const renderButtonContent = ({ state, text }: ButtonVisuals): JSX.Element => {
  switch (state) {
    case "active":
    case "disabled":
      return <>{text}</>

    case "loading":
      return <Spinner />

    default:
      // implies {loadingText: string } variant
      return (
        <>
          <Spinner />
          {state.loadingText}
        </>
      )
  }
}

const calcAnimationStyle = (animation: animationStates): string => {
  switch (animation) {
    case "idle":
      return ""
    case "slideOut":
      return slideAndFadeOutStyles
    case "slideIn":
      return slideAndFadeInStyles
  }
}

const calcButtonStyle = (variant: ButtonVariant, state: ButtonState, size: ButtonSize): string => {
  const sizeDependentButtonVariants = size === "normal" ? buttonNormalVariants : buttonSmallVariants
  switch (variant) {
    case "filled":
      switch (state) {
        case "active":
          return sizeDependentButtonVariants.filledActive
        case "disabled":
          return sizeDependentButtonVariants.filledDisabled
        // includes "loading" and {loadingText: string}
        default:
          return sizeDependentButtonVariants.filledLoading
      }

    case "outline":
      switch (state) {
        case "active":
          return sizeDependentButtonVariants.outlineActive
        case "disabled":
          return sizeDependentButtonVariants.outlineDisabled
        // includes "loading" and {loadingText: string}
        default:
          return sizeDependentButtonVariants.outlineLoading
      }

    case "text": {
      switch (state) {
        case "active":
          return sizeDependentButtonVariants.textActive
        case "disabled":
          return sizeDependentButtonVariants.textDisabled

        // includes "loading" and {loadingText: string}
        // note that we don't support "loading" variants for text buttons
        default:
          return ""
      }
    }
  }
}
