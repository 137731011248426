import { Path, Svg } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const FilledCardIcon = ({ width = 24, height = 24, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 28 20" customFill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.31047 2.19213C0.90918 2.97972 0.90918 4.01072 0.90918 6.07273V13.9273C0.90918 15.9893 0.90918 17.0203 1.31047 17.8079C1.66346 18.5007 2.22671 19.0639 2.91949 19.4169C3.70707 19.8182 4.73808 19.8182 6.80009 19.8182H21.2001C23.2621 19.8182 24.2931 19.8182 25.0807 19.4169C25.7735 19.0639 26.3367 18.5007 26.6897 17.8079C27.091 17.0203 27.091 15.9893 27.091 13.9273V6.07273C27.091 4.01072 27.091 2.97972 26.6897 2.19213C26.3367 1.49935 25.7735 0.936106 25.0807 0.583118C24.2931 0.181824 23.2621 0.181824 21.2001 0.181824H6.80009C4.73808 0.181824 3.70707 0.181824 2.91949 0.583118C2.22671 0.936106 1.66346 1.49935 1.31047 2.19213ZM18.5001 5.09091C17.8223 5.09091 17.2728 5.64038 17.2728 6.31819V7.13637C17.2728 7.81417 17.8223 8.36364 18.5001 8.36364H20.9546C21.6324 8.36364 22.1819 7.81417 22.1819 7.13637V6.31819C22.1819 5.64038 21.6324 5.09091 20.9546 5.09091H18.5001Z"
        fill={fill}
      />
    </Svg>
  )
}
