import { useMemo, useRef, useState } from "react"
import { Listbox } from "@headlessui/react"
import { type DropdownMenuComponent } from "@pomebile/primitives"
import {
  listBoxStyles,
  listBoxButtonStyle,
  optionsStyles,
  optionStyles,
  caratContainerStyle,
  textInputContainerStyle,
  focusedOptionStyles,
  dropupStyles,
} from "./DropdownMenu.css"
import { CaratDownIcon } from "./CaratDown.icon"
import { TextInput } from "../TextInput/TextInput"

export const DropdownMenu: DropdownMenuComponent = ({
  value,
  options,
  disabled,
  placeholder,
  borderStyle,
  onChange,
  onFocus,
  onBlur,
}) => {
  const [dropsDown, setDropsDown] = useState<boolean>(false)
  const listboxRef = useRef<HTMLDivElement | null>(null)

  function checkDropdownDirection() {
    if (!listboxRef.current) return

    const inputRect = listboxRef.current?.getBoundingClientRect()
    setDropsDown(inputRect.bottom < 0.5 * window.innerHeight)
  }

  const label = useMemo(
    () => options.find((option) => option.value === value)?.label ?? "",
    [options, value],
  )

  return (
    <Listbox
      ref={listboxRef}
      value={value}
      onChange={onChange}
      disabled={disabled}
      as="div"
      className={listBoxStyles}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {({ open }) => (
        <>
          <Listbox.Button className={listBoxButtonStyle} onClick={checkDropdownDirection}>
            <div className={textInputContainerStyle}>
              <TextInput
                readonly
                value={label}
                focused={open}
                type="text"
                placeholder={placeholder}
                inputAdornmentEnd={
                  <div className={caratContainerStyle}>
                    <CaratDownIcon />
                  </div>
                }
                borderStyle={borderStyle}
              />
            </div>
          </Listbox.Button>
          {options.length > 0 && (
            <Listbox.Options className={`${optionsStyles} ${dropsDown ? "" : dropupStyles}`}>
              {options.map((option) => (
                <Listbox.Option key={option.value} value={option.value}>
                  {({ active }) => (
                    <div className={active ? focusedOptionStyles : optionStyles}>
                      {option.label}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          )}
        </>
      )}
    </Listbox>
  )
}
