import { Fragment, ReactElement, useRef, useState } from "react"
import { Combobox as ComboboxUI } from "@headlessui/react"
import { TextFieldProps } from "@pomebile/design-system"
import { comboboxStyles, optionStyles, optionsStyles, dropupStyles } from "./Combobox.css"

export interface ComboboxProps<V> {
  children: ReactElement<TextFieldProps>
  options: V[]
  onSelectOption: (option: V) => void
  renderOption: (option: V) => string | JSX.Element
}

export function Combobox<V>({ children, options, onSelectOption, renderOption }: ComboboxProps<V>) {
  const [dropsDown, setDropsDown] = useState<boolean>(false)
  const comboboxRef = useRef<HTMLDivElement | null>(null)

  function checkDropdownDirection() {
    if (!comboboxRef.current) return

    const inputRect = comboboxRef.current?.getBoundingClientRect()
    setDropsDown(inputRect.bottom < 0.5 * window.innerHeight)
  }

  return (
    <ComboboxUI
      as="div"
      className={comboboxStyles}
      onChange={onSelectOption}
      onClick={checkDropdownDirection}
      ref={comboboxRef}
    >
      {({ open }) => (
        <>
          <ComboboxUI.Input as={Fragment}>{children}</ComboboxUI.Input>
          {options.length > 0 && open && (
            <ComboboxUI.Options
              static
              className={`${optionsStyles} ${dropsDown ? "" : dropupStyles}`}
            >
              {options.map((option, index) => (
                <ComboboxUI.Option
                  key={`combobox-option-${index}`}
                  value={option}
                  className={optionStyles}
                >
                  {renderOption(option)}
                </ComboboxUI.Option>
              ))}
            </ComboboxUI.Options>
          )}
        </>
      )}
    </ComboboxUI>
  )
}
