/**
 * Debounce fn (useful for when making API calls while typing)
 *
 * @param fn The callback function
 */
export const debounce = <A extends any[]>(fn: (...args: A) => any, interval = 3000) => {
  let timer: ReturnType<typeof setTimeout>
  return (...args: A) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn.apply(this, args), interval)
  }
}
