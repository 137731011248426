import { useState } from "react"
import * as Yup from "yup"
import { Checkbox, HStack, Link, Secure, Txt, VStack } from "@pomebile/primitives"
import { TextField, USFlagIcon } from "@pomebile/design-system"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { useForm } from "../components/Form/useForm"
import * as V from "../utils/formValidation/validationMessages"
import { ScreenForm } from "../components/ScreenForm"
import { StickyBottom } from "../components/StickyBottom"
import { TermsAndConditionsDialog } from "../components/TermsAndConditionsDialog"
import { AddSignUpOutcome, AddSignUpResponse, UserData } from "../api/webRoutes"
import { email, nameSchema, phoneNumber } from "../utils/schema"
import { formatPhoneNumber } from "../utils/format"
import { extractDigits } from "../utils/string"
import { sprinkles } from "@pomebile/primitives-web"
import { regexForName } from "../utils/formValidation/validators"

export type UserInfoProps = {
  api: {
    signUp: (details: UserData) => Promise<AddSignUpOutcome>
  }

  phoneNumber: string

  onDone: (collectedDetails: UserData, signUpResult: AddSignUpResponse) => void
}

const UserInfoSchema = Yup.object({
  firstName: nameSchema,
  lastName: nameSchema,
  email,
  phoneNumber,
  optInMarketing: Yup.boolean().required(),
})

export function UserInfoScreen({ api, phoneNumber, onDone }: UserInfoProps) {
  const [submit, getFieldProps, status] = useForm({
    name: "Signup", // Note: Must match 1.0 name for analytics
    schema: UserInfoSchema,
    initial: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: formatPhoneNumber(phoneNumber, "-"),
      optInMarketing: false,
    },
    submit: async (details) => {
      const res = await api.signUp({
        ...details,
        phoneNumber: extractDigits(details.phoneNumber),
      })
      if (res.ok) {
        onDone(details, res.response)
      } else if (res.error === "userAlreadyExists") {
        return {
          status: "validationErr",
          err: { field: "email", errorMessage: "Email already exists" },
        }
      }

      return undefined
    },
  })

  const [isTermsOpen, setIsTermsOpen] = useState<boolean>(false)

  const toggleTermsAndConditions = () => {
    setIsTermsOpen((isTermsOpen) => !isTermsOpen)
  }

  const disabled = status !== "idle" && status !== "submitErr"

  const checkboxFieldProps = getFieldProps("optInMarketing")

  return (
    <ScreenForm onSubmit={submit}>
      <div>
        <VStack gap="xs">
          <Txt variant="headline2" as="h1">
            Your personal details
          </Txt>

          <Txt variant="body1" as="p">
            Please enter your name as it appears on your official government identification.
          </Txt>
        </VStack>

        <VStack gap="xl2" padding={{ bottom: "xl2", top: "xl2" }}>
          <VStack gap="xs">
            <HStack gap="sm">
              <TextField
                label="First Name"
                disabled={disabled}
                type="text"
                {...getFieldProps("firstName")}
              />
              <Secure>
                <TextField
                  label="Last Name"
                  disabled={disabled}
                  type="text"
                  {...getFieldProps("lastName")}
                />
              </Secure>
            </HStack>

            <Secure>
              <TextField
                label="Email Address"
                type="email"
                disabled={disabled}
                {...getFieldProps("email")}
              />
            </Secure>

            <Secure>
              <TextField
                label="Mobile Phone Number"
                type="text"
                disabled={true}
                inputAdornment={
                  <HStack gap="xs">
                    <USFlagIcon />
                    <Txt color="text-caption">+1&nbsp;</Txt>
                  </HStack>
                }
                {...getFieldProps("phoneNumber")}
              />
            </Secure>
            <VStack gap="md">
              <HStack gap="md" alignItems="flex-start" justifyContent="center">
                {/* Wrapper here helps a little with style alignment */}
                <div>
                  <Checkbox
                    id="optIn"
                    checked={checkboxFieldProps.value}
                    onChange={checkboxFieldProps.onChange}
                  />
                </div>
                <label className={sprinkles({ lineHeight: "none" })}>
                  <Txt variant="caption">
                    I agree to receive offers and promotions via SMS to your phone number. View our{" "}
                    <Link color="primary" decoration="underline" onClick={toggleTermsAndConditions}>
                      Privacy Policy
                    </Link>
                    {". "}
                    (Optional)
                  </Txt>
                </label>
              </HStack>
              <Txt as="p" variant="caption" color="text-default">
                See important information about the procedures for opening a new account in our{" "}
                <Link color="primary" decoration="underline" onClick={toggleTermsAndConditions}>
                  terms and conditions
                </Link>
                .
              </Txt>
            </VStack>
          </VStack>
        </VStack>
      </div>
      <StickyBottom>
        <FormSubmitButton status={status}>Continue</FormSubmitButton>
      </StickyBottom>
      <TermsAndConditionsDialog
        open={isTermsOpen}
        showMoneyTransferPromo={false} // We haven't shown the GCash promo yet at this point
        onClose={toggleTermsAndConditions}
      />
    </ScreenForm>
  )
}
