/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  ApiError,
  AuthRequest,
  AuthResponse,
  GenerateSmsOtpRequest,
  IntercomAuthResponse,
  RefreshAuthTokenRequest,
  RefreshAuthTokenResponse,
  SmsOtpResponse,
} from "../models"

export const authenticateIntercom = {
  url: "/v1/intercom/authenticate",
  method: "GET",
  meta: {} as {
    queryParams: {
      userIdent: string
      operatingSystem: string
    }
    res: IntercomAuthResponse
  },
} as const

export const authenticateSmsOtp = {
  url: "/v1/authenticateSmsOtp",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: AuthRequest
    res: AuthResponse
  },
} as const

export const generateSmsOtp = {
  url: "/v1/generateSmsOtp",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: GenerateSmsOtpRequest
    res: SmsOtpResponse
  },
} as const

export const generateToken = {
  url: "/v1/authenticate",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: AuthRequest
    res: AuthResponse
  },
} as const

export const logout = {
  url: "/v1/logout",
  method: "POST",
  meta: {} as {
    queryParams: {
      deviceIdent: string
    }
    res: void
  },
} as const

export const refreshAuthToken = {
  url: "/v1/refreshAuthToken",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: RefreshAuthTokenRequest
    res: RefreshAuthTokenResponse
  },
} as const
