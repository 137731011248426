import type { LinkComponent, LinkProps } from "@pomebile/primitives"
import { linkVariants, linkDecorations } from "./Link.css"
import { sprinkles } from "../.."

export const Link: LinkComponent = ({
  id,
  children,
  url,
  type,
  target,
  onClick,
  color = "primary",
  as = "a",
  decoration = "none",
}: LinkProps) => {
  const AsTag = as
  const className =
    as === "a"
      ? `${linkVariants[color]} ${linkDecorations[decoration]}`
      : sprinkles({ width: "full", cursor: "pointer" })

  return (
    <AsTag id={id} href={url} type={type} target={target} onClick={onClick} className={className}>
      {children}
    </AsTag>
  )
}
