type AnyObject = { [key: string]: any }

interface Variant<Tag extends keyof Record, Record> {
  tag: Tag
  data: Record[Tag]
}

type CombinedVariants<
  T extends {
    [k: string]: any
  },
> = {
  [K in keyof T]: K extends string ? Variant<K, T> : never
}

export type Variants<T extends AnyObject> = CombinedVariants<T>[keyof T]

type VariantConstructor<
  Key extends keyof TRecord,
  TRecord extends AnyObject,
> = TRecord[Key] extends void
  ? () => Variant<Key, TRecord>
  : (data: TRecord[Key]) => Variant<Key, TRecord>

type Union<TRecord extends AnyObject> = {
  [K in keyof TRecord]: VariantConstructor<K, TRecord>
}

export const taggedUnion = <TRecord extends AnyObject>(): Union<TRecord> =>
  new Proxy(
    {},
    {
      get(_target, tag, _receiver) {
        if (typeof tag !== "string") {
          return null
        } else return (data: any) => ({ tag, data })
      },
    },
  ) as unknown as Union<TRecord>

type MatchFunction<Type, Res> = Type extends void ? () => Res : (data: Type) => Res

type MatchAllObject<Record extends AnyObject, Res> = {
  [K in keyof Record]: MatchFunction<Record[K], Res>
}

type MatchPartialObject<Record extends AnyObject, Res> = {
  [K in keyof Record]?: MatchFunction<Record[K], Res>
}

type Match = {
  <TRecord extends AnyObject, TMatch extends MatchAllObject<TRecord, any>>(
    val: Variants<TRecord>,
    matchObj: TMatch,
    catchAll: never,
  ): TMatch extends MatchAllObject<TRecord, infer Res> ? Res : never

  <TRecord extends AnyObject, TMatch extends MatchPartialObject<TRecord, any>>(
    val: Variants<TRecord>,
    matchObj: TMatch,
    catchAll: (
      a: Variants<Omit<TRecord, keyof TMatch>>,
    ) => TMatch extends MatchPartialObject<any, infer Res> ? Res : never,
  ): TMatch extends MatchPartialObject<TRecord, infer Res> ? Res : never
}

export const match: Match = (val: any, matchObj: any, catchAll: any) =>
  catchAll
    ? performMatchDefault(val, matchObj, catchAll as any)
    : (performMatch(val, matchObj as any) as any)

export const matchDeferred: MatchDeferred = (_union, matchObj) =>
  function match(val: any) {
    return performMatch(val, matchObj)
  } as any

const performMatch = <TRecord extends AnyObject, TRes>(
  val: Variants<TRecord>,
  matchObj: MatchAllObject<TRecord, TRes>,
): TRes => {
  return matchObj[val.tag](val.data)
}

const performMatchDefault = <TRecord extends AnyObject, TRes>(
  val: Variants<TRecord>,
  matchObj: MatchPartialObject<TRecord, TRes>,
  catchAll: (a: Variants<TRecord>) => TRes,
): TRes => {
  const matchedFunction = matchObj[val.tag]
  if (matchedFunction) {
    return matchedFunction(val.data)
  } else {
    return catchAll(val)
  }
}

interface MatchDeferred {
  <TRecord extends AnyObject, TMatch extends MatchAllObject<TRecord, any>>(
    union: Union<TRecord>,
    matchObj: TMatch,
  ): TMatch extends MatchAllObject<TRecord, infer Res> ? (val: Variants<TRecord>) => Res : never
}

interface MatchTuple {
  <
    RecordOne extends AnyObject,
    RecordTwo extends AnyObject,
    TMatch extends MatchTupleObject<RecordOne, RecordTwo, any>,
  >(
    one: Variants<RecordOne>,
    two: Variants<RecordTwo>,
    matchObj: TMatch,
  ): TMatch extends MatchTupleObject<RecordOne, RecordTwo, infer Res> ? Res : never
}

interface MatchTupleDeferred {
  <
    RecordOne extends AnyObject,
    RecordTwo extends AnyObject,
    TMatch extends MatchTupleObject<RecordOne, RecordTwo, any>,
  >(
    one: Union<RecordOne>,
    two: Union<RecordTwo>,
    matchObj: TMatch,
  ): TMatch extends MatchTupleObject<RecordOne, RecordTwo, infer Res>
    ? (one: Variants<RecordOne>, two: Variants<RecordTwo>) => Res
    : never
}

type MatchTupleObject<RecordOne extends AnyObject, RecordTwo extends AnyObject, Res> = {
  [KOne in keyof RecordOne]?: {
    [KTwo in keyof RecordTwo]?: (one: RecordOne[KOne], two: RecordTwo[KTwo]) => Res
  }
} & { default: (one: Variants<RecordOne>, two: Variants<RecordTwo>) => Res }

export const matchTuple: MatchTuple = (valA, valB, matchObj) =>
  performTupleMatch(valA, valB, matchObj)

export const matchDeferredTuple: MatchTupleDeferred = (_unionA, _unionB, matchObj) =>
  function matchTuple(a: any, b: any) {
    return performTupleMatch(a, b, matchObj)
  } as any

const performTupleMatch = <RecA extends AnyObject, RecB extends AnyObject, TRes>(
  a: Variants<RecA>,
  b: Variants<RecB>,
  matchObj: MatchTupleObject<RecA, RecB, TRes>,
): TRes => {
  const matchB = matchObj[a.tag]

  if (matchB) {
    const matchFunc = matchB[b.tag]
    if (matchFunc) {
      return matchFunc(a.data, b.data)
    }
  }

  return matchObj.default(a, b)
}
