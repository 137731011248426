import { AlertIcon } from "@pomebile/design-system"
import { Dialog } from "../../components/Dialog"
import { Avatar, VStack, Button, Txt } from "@pomebile/primitives"

export interface ResubmissionDialogProps {
  isOpen: boolean
  reason?: string
  onContinue: () => void
}

export const ResubmissionDialog = ({ reason, isOpen, onContinue }: ResubmissionDialogProps) => {
  return (
    <Dialog open={isOpen} onClose={onContinue}>
      <VStack padding="xl" gap="xl" justifyContent="center" alignItems="center">
        <VStack gap="xs" alignItems="center">
          <Avatar
            size="md"
            variant="error"
            svg={<AlertIcon width={34} height={34} fill="icon-error" />}
          />
          <Txt variant="headline2" as="h1" textAlign="center">
            Retry identity verification
          </Txt>
          <Txt variant="body1" textAlign="center">
            We were unable to successfully verify your identity. Please try again to continue.
          </Txt>
          {reason && (
            <Txt variant="body1" textAlign="center" color="text-error">
              {reason}
            </Txt>
          )}
        </VStack>
        <Button web-type="button" onClick={onContinue}>
          Continue
        </Button>
      </VStack>
    </Dialog>
  )
}
