import { FC } from "react"
import { Dialog } from "./Dialog"
import { DialogHeader } from "./Dialog/DialogHeader"
import { Avatar, VStack, Txt, Link, HStack, Button } from "@pomebile/primitives"
import { BankIcon, CrossIcon, NotificationIcon, TrendUpIcon, VSpace } from "@pomebile/design-system"
import { createLoggingContext } from "../sharedShellLogic"

interface Props {
  open: boolean
  onClose: () => void
  plaidLinkUrl: string
}

type ListItem = {
  icon: JSX.Element
  text: string
}

const listItems: ListItem[] = [
  {
    icon: <BankIcon width={16} height={16} fill="icon-emphasis" />,
    text: "Securely link the bank account where you deposit your main income.",
  },
  {
    icon: <TrendUpIcon width={16} height={16} fill="icon-emphasis" />,
    text: "Once you’ve linked your bank account, submit your application to receive your initial credit offer.",
  },
  {
    icon: <NotificationIcon width={16} height={16} fill="icon-emphasis" />,
    text: "We will let you know in the future if you become eligible for a credit upgrade.",
  },
]

const IconList: FC<{ listItems: ListItem[] }> = ({ listItems }) => (
  <VStack gap="xl2" padding={{ x: "sm" }}>
    {listItems.map(({ icon, text }, index) => (
      <HStack key={index} alignItems="center" gap="md">
        <Avatar size="sm" svg={icon} />
        <HStack flex={1}>
          <Txt variant="button2">{text}</Txt>
        </HStack>
      </HStack>
    ))}
  </VStack>
)

export const LinkBankDialog: FC<Props> = ({ open, onClose, plaidLinkUrl }) => {
  const { logEvent } = createLoggingContext()
  const handleOpenPlaidFlow = () => {
    logEvent("Plaid Check: Entered Plaid Flow")
    window.location.href = plaidLinkUrl
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader variant="noBorder">
        <HStack width="full" justifyContent="flex-end">
          <Link onClick={onClose}>
            <CrossIcon width={24} height={24} />
          </Link>
        </HStack>
      </DialogHeader>
      <VStack padding={{ x: "xl", bottom: "xl" }} alignItems="center">
        <Avatar size="md" svg={<TrendUpIcon fill="icon-emphasis" />} />
        <VSpace height="sm" />
        <Txt variant="headline3" as="h1" textAlign="center">
          Exclusive Access: Credit Upgrade Program
        </Txt>
        <VSpace height="xs" />
        <Txt variant="body1" textAlign="center">
          Link your bank account to help us determine your future eligibility for an upgrade on your
          initial offer.
        </Txt>
        <VSpace height="xl" />
        <IconList listItems={listItems} />
        <VSpace height="xl" />
        <Txt variant="caption" color="text-caption" textAlign="center">
          While linking your bank account won’t affect your initial offer, it will give you
          exclusive access to the Pomelo credit upgrade program.
        </Txt>
        <VSpace height="xl2" />
        <Button onClick={handleOpenPlaidFlow}>Continue</Button>
      </VStack>
    </Dialog>
  )
}
