import { Path, Svg } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const SupportIcon = ({ width = 24, height = 24, fill = "icon-default" }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" customFill="none">
      <Path
        d="M17.5001 22.5C17.5001 22.7967 17.4121 23.0867 17.2473 23.3334C17.0825 23.58 16.8482 23.7723 16.5741 23.8858C16.3 23.9994 15.9984 24.0291 15.7074 23.9712C15.4165 23.9133 15.1492 23.7705 14.9394 23.5607C14.7296 23.3509 14.5868 23.0836 14.5289 22.7927C14.471 22.5017 14.5007 22.2001 14.6143 21.926C14.7278 21.6519 14.9201 21.4176 15.1667 21.2528C15.4134 21.088 15.7034 21 16.0001 21C16.3979 21 16.7794 21.1581 17.0607 21.4394C17.342 21.7207 17.5001 22.1022 17.5001 22.5ZM16.0001 9.00002C13.2426 9.00002 11.0001 11.0188 11.0001 13.5V14C11.0001 14.2652 11.1054 14.5196 11.293 14.7071C11.4805 14.8947 11.7349 15 12.0001 15C12.2653 15 12.5197 14.8947 12.7072 14.7071C12.8947 14.5196 13.0001 14.2652 13.0001 14V13.5C13.0001 12.125 14.3463 11 16.0001 11C17.6538 11 19.0001 12.125 19.0001 13.5C19.0001 14.875 17.6538 16 16.0001 16C15.7349 16 15.4805 16.1054 15.293 16.2929C15.1054 16.4804 15.0001 16.7348 15.0001 17V18C15.0001 18.2652 15.1054 18.5196 15.293 18.7071C15.4805 18.8947 15.7349 19 16.0001 19C16.2653 19 16.5197 18.8947 16.7072 18.7071C16.8947 18.5196 17.0001 18.2652 17.0001 18V17.91C19.2801 17.4913 21.0001 15.6725 21.0001 13.5C21.0001 11.0188 18.7576 9.00002 16.0001 9.00002Z"
        fill={fill}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8813 26.2101C14.2309 27.0049 15.3044 27.3334 15.9998 27.3334C16.6954 27.3334 17.769 27.0049 19.1186 26.2101C20.4251 25.4407 21.8083 24.3415 23.0749 23.0749C24.3416 21.8083 25.4408 20.4251 26.2101 19.1186C27.005 17.769 27.3334 16.6955 27.3334 16C27.3334 15.3045 27.005 14.231 26.2101 12.8814C25.4408 11.575 24.3416 10.1918 23.0749 8.92516C21.8083 7.65855 20.4251 6.55936 19.1186 5.78997C17.769 4.99513 16.6954 4.66669 15.9998 4.66669C15.3044 4.66669 14.2309 4.9951 12.8813 5.78994C11.575 6.55933 10.1918 7.65852 8.92517 8.92513C7.65858 10.1917 6.5594 11.575 5.79001 12.8814C4.99517 14.231 4.66675 15.3045 4.66675 16C4.66675 16.6955 4.99517 17.769 5.79001 19.1186C6.5594 20.4251 7.65858 21.8083 8.92517 23.0749C10.1918 24.3415 11.575 25.4407 12.8813 26.2101ZM2.66675 16C2.66675 20.8598 11.1403 29.3334 15.9998 29.3334C20.8599 29.3334 29.3334 20.8598 29.3334 16C29.3334 11.1402 20.8599 2.66669 15.9998 2.66669C11.1403 2.66669 2.66675 11.1402 2.66675 16Z"
        fill={fill}
      />
    </Svg>
  )
}
