import { Fragment } from "react"
import { Dialog as HUIDialog, Transition } from "@headlessui/react"
import {
  bodyTranslateStyles,
  centerModalContentStyles,
  containerHeights,
  dialogContainerStyles,
  dialogContentStyles,
  dialogWrapperLargeViewportStyles,
  dialogWrapperStyles,
  enterBodyStyles,
  enterStyles,
  largeViewportStyles,
  leaveStyles,
  opacityFullStyles,
  opacityZeroStyles,
  overlayStyles,
} from "./Dialog.css"

type DialogProps = {
  children?: JSX.Element[] | JSX.Element
  open: boolean
  initialFocusRef?: any
  onClose: () => void
  fullWidth?: boolean
}

export const Dialog = ({ children, open, initialFocusRef, onClose }: DialogProps) => {
  return (
    <Transition appear show={open} as={Fragment}>
      <HUIDialog
        initialFocus={initialFocusRef}
        as="div"
        className={dialogContainerStyles}
        onClose={onClose}
      >
        <div className={`${dialogWrapperStyles} ${dialogWrapperLargeViewportStyles}`}>
          <Transition.Child
            as={Fragment}
            enter={enterStyles}
            enterFrom={opacityZeroStyles}
            enterTo={opacityFullStyles}
            leave={leaveStyles}
            leaveFrom={opacityFullStyles}
            leaveTo={opacityZeroStyles}
          >
            <HUIDialog.Overlay className={overlayStyles} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className={centerModalContentStyles} aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter={enterStyles}
            enterFrom={enterBodyStyles}
            enterTo={bodyTranslateStyles}
            leave={leaveStyles}
            leaveFrom={bodyTranslateStyles}
            leaveTo={enterBodyStyles}
          >
            <div className={`${dialogContentStyles} ${largeViewportStyles} ${containerHeights}`}>
              {children}
            </div>
          </Transition.Child>
        </div>
      </HUIDialog>
    </Transition>
  )
}
