import { Path, Svg } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const EnvelopeIcon = ({ width = 32, height = 32, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" customFill="none">
      <Path
        d="M28 6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7V24C3 24.5304 3.21071 25.0391 3.58579 25.4142C3.96086 25.7893 4.46957 26 5 26H27C27.5304 26 28.0391 25.7893 28.4142 25.4142C28.7893 25.0391 29 24.5304 29 24V7C29 6.73478 28.8946 6.48043 28.7071 6.29289C28.5196 6.10536 28.2652 6 28 6ZM25.4287 8L16 16.6437L6.57125 8H25.4287ZM27 24H5V9.27375L15.3237 18.7375C15.5082 18.9069 15.7496 19.0008 16 19.0008C16.2504 19.0008 16.4918 18.9069 16.6763 18.7375L27 9.27375V24Z"
        fill={fill}
      />
    </Svg>
  )
}
