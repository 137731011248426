import { Svg, Path, Defs, ClipPath, Rect, G } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const PHFlagIcon = ({ width = 28, height = 28 }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 28 28" customFill="none">
      <G clipPath="url(#clip0_1270_10928)">
        <Path
          d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
          customFill="#F0F0F0"
        />
        <Path
          d="M14.0001 0V14L4.10059 23.8995C6.63409 26.433 10.1341 28 14.0001 28C21.7321 28 28.0001 21.7319 28.0001 14C28.0001 6.26806 14.0001 0 14.0001 0Z"
          customFill="#D80027"
        />
        <Path
          d="M14.0001 0C10.1341 0 6.63409 1.56702 4.10059 4.10047C7.53551 7.53539 8.75488 8.75481 14.0001 14H28.0001C28.0001 6.26806 21.7321 0 14.0001 0Z"
          customFill="#0052B4"
        />
        <Path
          d="M9.5866 14.0001L7.87685 13.1958L8.78734 11.54L6.9307 11.8951L6.69544 10.0195L5.40219 11.399L4.10899 10.0195L3.87367 11.8951L2.01709 11.5399L2.92758 13.1958L1.21777 14.0001L2.92758 14.8043L2.01709 16.4602L3.87384 16.105L4.10894 17.9806L5.40219 16.6012L6.69544 17.9806L6.9307 16.105L8.78723 16.4602L7.8768 14.8044L9.5866 14.0001Z"
          customFill="#FFDA44"
        />
        <Path
          d="M3.73445 5.47827L4.30342 6.27359L5.23557 5.97833L4.6549 6.76518L5.22387 7.5605L4.29604 7.25146L3.71542 8.03825L3.7227 7.06044L2.79492 6.75134L3.72723 6.45608L3.73445 5.47827Z"
          customFill="#FFDA44"
        />
        <Path
          d="M3.73445 19.9618L4.30342 20.7571L5.23557 20.4618L4.6549 21.2487L5.22387 22.044L4.29604 21.735L3.71542 22.5218L3.7227 21.544L2.79492 21.2349L3.72723 20.9396L3.73445 19.9618Z"
          customFill="#FFDA44"
        />
        <Path
          d="M11.8497 12.7201L11.2808 13.5154L10.3486 13.22L10.9293 14.0069L10.3603 14.8023L11.2882 14.4932L11.8688 15.28L11.8615 14.3023L12.7893 13.9932L11.857 13.6979L11.8497 12.7201Z"
          customFill="#FFDA44"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_1270_10928">
          <Rect width={28} height={28} customFill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
