import { HStack, Secure, Stack, Txt, VStack } from "@pomebile/primitives"
import { useEffect, useRef } from "react"
import { nativeTextStyle, sprinkles } from "@pomebile/primitives-web"
import * as Yup from "yup"
import {
  AuthExistingUser,
  AuthTempToken,
  Country,
  GenerateSmsOtpResponse,
  OTPAuthResponse,
} from "../api/webRoutes"
import { inputStyles } from "./OTP.css"
import { ScreenForm } from "../components/ScreenForm"
import { useForm } from "../components/Form/useForm"
import * as V from "../utils/formValidation/validationMessages"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { TextFieldErrorText } from "@pomebile/design-system"
import { useLogging } from "../utils/logging"
import { StickyBottom } from "../components/StickyBottom"
import { formatPhoneNumber } from "../utils/format"

const OTP_LENGTH = 6
// Regexp breaks down to: start of string + <OTP_LENGTH> digits + end of string
const OTP_REGEXP = new RegExp(`^\\d{${OTP_LENGTH}}$`)
export const OTPCodeSchema = Yup.object({
  // Odd bug in browser that allows some browsers to input characters in numeric fields does not play well with
  // Yup's required field, so combined both regexp matches and 'required' and number of characters into one regexp
  code: Yup.string().required(V.MSG_REQUIRED).matches(OTP_REGEXP, V.MSG_INVALID_OTP(OTP_LENGTH)),
})

export type OTPScreenProps = {
  api: {
    requestOTPCode: () => Promise<GenerateSmsOtpResponse>
    verifyOTPCode: (code: string) => Promise<OTPAuthResponse>
  }

  phoneNumber: string
  country: Country
  onDone: (authResult: AuthTempToken | AuthExistingUser | { tag: "disabledUser" }) => void
}

export function OTPScreen({ api, phoneNumber, country, onDone }: OTPScreenProps) {
  const formRef = useRef<HTMLFormElement>(null)
  const { logError } = useLogging()

  const [submit, getFieldProps, status, { setError, isFormValid }] = useForm({
    name: "Phone Code", // Note: Must match 1.0 name for analytics
    schema: OTPCodeSchema,
    initial: { code: "" },
    submit: async ({ code }) => {
      const res = await api.verifyOTPCode(code)

      if (res.tag !== "codeMismatched") {
        onDone(res)
      } else {
        setError("code", "Invalid verification code entered")
      }
    },
  })

  useEffect(() => {
    const promise = api.requestOTPCode()

    promise
      .then((res) => {
        if (res.tag === "disabledUser") {
          onDone({ tag: res.tag })
        }
      })
      .catch((error) => {
        logError(error)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { errorText, value, onBlur, onChange } = getFieldProps("code")

  useEffect(() => {
    if (value.length === OTP_LENGTH) {
      // This needs to be done in a useEffect because the form values don't update until the next render
      // Sending a form submit event ensures that all the necessary validations and animations are run
      formRef.current?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))
    }
  }, [value])

  return (
    <ScreenForm ref={formRef} onSubmit={submit}>
      <VStack gap="xs" justifyContent="space-between" height="full">
        <div>
          <Txt variant="headline2" as="h1">
            Enter the 6-digit code
          </Txt>
          <Secure>
            <Txt>
              Please enter the 6-digit code sent to {country === "US" ? "" : "+63-"}
              {formatPhoneNumber(phoneNumber, "-")}.
            </Txt>
          </Secure>
        </div>
        <VStack justifyContent="center" alignItems="center">
          {/* Flag: Bespoke input for artisan styles */}
          <input
            data-public={true}
            value={value}
            onBlur={() => onBlur()}
            onChange={(e) => {
              if (e.target.value.length <= OTP_LENGTH) {
                onChange(e.target.value)
              }
            }}
            type="number"
            placeholder="000000"
            inputMode="decimal"
            className={`${nativeTextStyle} ${sprinkles({
              fontSize: "xl3",
              textAlign: "center",
            })} ${inputStyles}`}
          />

          <HStack width="auto" justifyContent="center" alignItems="center">
            {errorText ? <TextFieldErrorText error={errorText} /> : <Stack padding="xs" />}
          </HStack>
        </VStack>

        <StickyBottom>
          <VStack gap="sm">
            {/* Removing for now, will re-introduce in FEINF-397
          <Button
            web-type="button"
            variant="text"
            onClick={() => {
              // neither error handling nor success validation for resending
              api.requestOTPCode().catch(logError)
            }}
          >
            Resend Code
          </Button> */}
            <FormSubmitButton disabled={!value || !isFormValid} status={status}>
              Continue
            </FormSubmitButton>
          </VStack>
        </StickyBottom>
      </VStack>
    </ScreenForm>
  )
}
