/**
 * This component is meant to be used for full screen wrappers as a form element.
 * Helps to control the empty white space-between elements in a column in the
 * scenario when the body content is not enough to fill in the vertical void.
 */

import { forwardRef } from "react"
import { sprinkles } from "@pomebile/primitives-web"

type ScreenFormProps = {
  onSubmit: (e: any) => void
  children: React.ReactNode
}

export const ScreenForm = forwardRef<HTMLFormElement, ScreenFormProps>(function ScreenForm(
  { onSubmit, children },
  ref,
) {
  return (
    <form
      ref={ref}
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(e)
      }}
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flexGrow: 1,
        maxWidth: "maxWidth",
        padding: "xl",
      })}
    >
      {children}
    </form>
  )
})
