import { CheckIcon } from "@pomebile/design-system"
import { Avatar, Button, Txt, VStack } from "@pomebile/primitives"
import { Dialog } from "../../components/Dialog"
import { Country } from "../../api/webRoutes"

interface CardOrderedDialogProps {
  isOpen: boolean
  onContinue: () => void
  destinationCountry: Country
}

export function CardOrderedDialog({
  destinationCountry,
  isOpen,
  onContinue,
}: CardOrderedDialogProps) {
  return (
    <Dialog open={isOpen} onClose={() => {}}>
      <VStack padding="xl" gap="xl" justifyContent="center" alignItems="center">
        <VStack gap="xs">
          <VStack gap="sm" justifyContent="center" alignItems="center">
            <Avatar size="md" variant="success" svg={<CheckIcon fill="icon-success" />} />
            <Txt textAlign="center" variant="headline3">
              Card Ordered
            </Txt>
          </VStack>
          {destinationCountry === "US" ? (
            <Txt textAlign="center" variant="body2">
              We’ll let you know when your Pomelo Mastercard&#174; has shipped via USPS. It should
              arrive in 3-5 business days to your home address.
            </Txt>
          ) : (
            <Txt textAlign="center" variant="body2">
              We’ll let you know when your Pomelo Mastercard® has shipped via courier delivery. It
              should arrive in 5-7 business days to your home address.
            </Txt>
          )}
        </VStack>
        <Button web-type="button" onClick={onContinue}>
          Continue
        </Button>
      </VStack>
    </Dialog>
  )
}
