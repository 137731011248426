import { Path, Svg } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const CardIcon = ({ width = 24, height = 24, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 32 32" customFill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.332 8.66669H8.66536C7.69894 8.66669 7.09441 8.66824 6.6389 8.70546C6.20674 8.74077 6.08763 8.79796 6.06004 8.81201C5.80916 8.93984 5.60519 9.14382 5.47736 9.3947C5.4633 9.42228 5.40611 9.5414 5.3708 9.97356C5.33359 10.4291 5.33203 11.0336 5.33203 12V20C5.33203 20.9664 5.33359 21.571 5.3708 22.0265C5.40611 22.4586 5.4633 22.5778 5.47736 22.6053C5.60519 22.8562 5.80916 23.0602 6.06004 23.188C6.08763 23.2021 6.20674 23.2593 6.6389 23.2946C7.09441 23.3318 7.69894 23.3334 8.66536 23.3334H23.332C24.2985 23.3334 24.903 23.3318 25.3585 23.2946C25.7907 23.2593 25.9098 23.2021 25.9374 23.188C26.1882 23.0602 26.3922 22.8562 26.52 22.6053C26.5341 22.5778 26.5913 22.4586 26.6266 22.0265C26.6638 21.571 26.6654 20.9664 26.6654 20V12C26.6654 11.0336 26.6638 10.4291 26.6266 9.97356C26.5913 9.5414 26.5341 9.42228 26.52 9.3947C26.3922 9.14382 26.1882 8.93984 25.9374 8.81201C25.9098 8.79796 25.7907 8.74077 25.3585 8.70546C24.903 8.66824 24.2985 8.66669 23.332 8.66669ZM3.69534 8.48672C3.33203 9.19976 3.33203 10.1332 3.33203 12V20C3.33203 21.8669 3.33203 22.8003 3.69534 23.5133C4.01492 24.1405 4.52486 24.6505 5.15206 24.97C5.8651 25.3334 6.79852 25.3334 8.66536 25.3334H23.332C25.1989 25.3334 26.1323 25.3334 26.8453 24.97C27.4725 24.6505 27.9825 24.1405 28.3021 23.5133C28.6654 22.8003 28.6654 21.8669 28.6654 20V12C28.6654 10.1332 28.6654 9.19976 28.3021 8.48672C27.9825 7.85951 27.4725 7.34958 26.8453 7.03C26.1323 6.66669 25.1989 6.66669 23.332 6.66669H8.66536C6.79852 6.66669 5.8651 6.66669 5.15206 7.03C4.52486 7.34958 4.01492 7.85951 3.69534 8.48672Z"
        fill={fill}
      />
      <Path
        d="M18.6654 13C18.6654 12.4477 19.1131 12 19.6654 12H22.332C22.8843 12 23.332 12.4477 23.332 13V13.6667C23.332 14.219 22.8843 14.6667 22.332 14.6667H19.6654C19.1131 14.6667 18.6654 14.219 18.6654 13.6667V13Z"
        fill={fill}
      />
    </Svg>
  )
}
