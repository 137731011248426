import { Svg, Path, G, Mask, ClipPath, Rect, Defs } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const PomeloIcon = ({ width = 30, height = 36 }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 30 36" customFill="none">
      <G clipPath="url(#clip0_256_5984)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3684 5.84804H13.5928L15.8919 2.20602C16.759 0.830673 18.2545 0 19.8585 0H26.6335L24.3357 3.64203C23.4672 5.0167 21.9731 5.84804 20.3684 5.84804Z"
          customFill="#00B198"
        />
        <Mask
          id="mask0_256_5984"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={7}
          width={30}
          height={29}
        >
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.82666 7.12256H29.1731V36H0.82666V7.12256Z"
            customFill="white"
          />
        </Mask>
        <G mask="url(#mask0_256_5984)">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.9996 9.25591C11.0486 9.25591 2.92027 17.536 2.92027 21.5613C2.92027 25.5867 11.0486 33.8668 14.9996 33.8668C18.9512 33.8668 27.0802 25.5867 27.0802 21.5613C27.0802 17.536 18.9512 9.25591 14.9996 9.25591ZM14.9996 36.0001C9.83394 36.0001 0.82666 26.824 0.82666 21.5613C0.82666 16.2986 9.83394 7.12256 14.9996 7.12256C20.1658 7.12256 29.1731 16.2986 29.1731 21.5613C29.1731 26.824 20.1658 36.0001 14.9996 36.0001Z"
            customFill="#00B198"
          />
        </G>
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0002 20.1444C13.5703 20.1444 10.8667 17.3897 10.8667 15.9337C10.8667 14.4776 13.5703 11.7223 15.0002 11.7223C16.4296 11.7223 19.1338 14.4776 19.1338 15.9337C19.1338 17.3897 16.4296 20.1444 15.0002 20.1444Z"
          customFill="#FF3F78"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0002 31.4C13.5703 31.4 10.8667 28.6453 10.8667 27.1893C10.8667 25.7333 13.5703 22.9779 15.0002 22.9779C16.4296 22.9779 19.1338 25.7333 19.1338 27.1893C19.1338 28.6453 16.4296 31.4 15.0002 31.4Z"
          customFill="#FF3F78"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.47583 25.772C8.04584 25.772 5.34229 23.0173 5.34229 21.5613C5.34229 20.1046 8.04584 17.3506 9.47583 17.3506C10.9052 17.3506 13.6094 20.1046 13.6094 21.5613C13.6094 23.0173 10.9052 25.772 9.47583 25.772Z"
          customFill="#FF3F78"
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5247 25.772C19.0947 25.772 16.3911 23.0173 16.3911 21.5613C16.3911 20.1046 19.0947 17.3506 20.5247 17.3506C21.9546 17.3506 24.6582 20.1046 24.6582 21.5613C24.6582 23.0173 21.9546 25.772 20.5247 25.772Z"
          customFill="#FF3F78"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_256_5984">
          <Rect width={28.3465} height={36} customFill="white" transform="translate(0.82666)" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
