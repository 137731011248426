import { Svg, Path } from "@pomebile/primitives"
import { IconProps } from "./icon-contract"

export const ExclamationIcon = ({ width = 24, height = 24, fill }: IconProps) => {
  return (
    <Svg width={width} height={height} fill={fill}>
      <Path
        d="M8.00001 8.83342C7.72386 8.83342 7.50001 8.60956 7.50001 8.33342V5.66675C7.50001 5.39061 7.72386 5.16675 8.00001 5.16675C8.27615 5.16675 8.50001 5.39061 8.50001 5.66675V8.33342C8.50001 8.60956 8.27615 8.83342 8.00001 8.83342Z"
        fill={fill}
      />
      <Path
        d="M8.00001 9.33342C7.63182 9.33342 7.33334 9.63189 7.33334 10.0001C7.33334 10.3683 7.63182 10.6667 8.00001 10.6667C8.36819 10.6667 8.66667 10.3683 8.66667 10.0001C8.66667 9.63189 8.3682 9.33342 8.00001 9.33342Z"
        fill={fill}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00001 13.3334C10.9455 13.3334 13.3333 10.9456 13.3333 8.00008C13.3333 5.05456 10.9455 2.66675 8.00001 2.66675C5.05449 2.66675 2.66667 5.05456 2.66667 8.00008C2.66667 10.9456 5.05449 13.3334 8.00001 13.3334ZM8.00001 12.3334C10.3932 12.3334 12.3333 10.3933 12.3333 8.00008C12.3333 5.60685 10.3932 3.66675 8.00001 3.66675C5.60677 3.66675 3.66667 5.60685 3.66667 8.00008C3.66667 10.3933 5.60677 12.3334 8.00001 12.3334Z"
        fill={fill}
      />
    </Svg>
  )
}
