import { core, usAutocompletePro, usZipcode } from "smartystreets-javascript-sdk"

const key = "93888005864925481"
const credentials = new core.SharedCredentials(key)

const usAutocompleteClientBuilder = new core.ClientBuilder(credentials).withLicenses([
  "us-autocomplete-pro-cloud",
])
const usZipcodeClientBuilder = new core.ClientBuilder(credentials)

export const usAutocompleteClient = usAutocompleteClientBuilder.buildUsAutocompleteProClient()
export const usZipcodeClient = usZipcodeClientBuilder.buildUsZipcodeClient()

export const findAddress = async (address: string): Promise<usAutocompletePro.Lookup> => {
  const lookup = new usAutocompletePro.Lookup(address)
  lookup.maxResults = 8

  return usAutocompleteClient.send(lookup)
}

export const validateZipAgainstState = async (zipCode: string, state: string): Promise<boolean> => {
  // 1. Create zip code lookup object
  const city = undefined
  const lookup = new usZipcode.Lookup(city, state, zipCode)

  // 2. Fetch addresses using lookup
  let response: core.Batch<usZipcode.Lookup>
  try {
    response = await usZipcodeClient.send(lookup)
  } catch (err) {
    // in case of network or other issues (such as auth) assume that the zip and states are valid
    // TODO rework
    return true
  }

  // 3a. Terminate early if no results were found
  if (!response.lookups.length) {
    return false
  }

  // 3b. Because we're using SmartyStreets' zipcode client, it is biased towards fetching addresses that have the input zipCode (i.e. returns address with same zip code but different state).
  // To further validate the response, we should go through all the resulting addresses and find at least a single one with the same zip code and state as our inputs
  const isValid = !!response.lookups.find((lookupRes) =>
    lookupRes.result.find(
      (address) =>
        address.valid &&
        address.zipcodes.find(
          (zipcode) => zipcode.zipcode === zipCode && zipcode.stateAbbreviation === state,
        ),
    ),
  )

  return isValid
}
