import { useEffect } from "react"
import { Full9SsnOutcome, Last4SsnOutcome, SsnRequirementStatus } from "../api/webRoutes"
import { createLoggingContext } from "../sharedShellLogic"
import { useFeatureFlag } from "../utils/featureFlag"
import { VerifyIdentityFullSsn } from "./VerifyIdentityFullSsn"
import { VerifyIdentityLastFour } from "./VerifyIdentityLastFour"

export type VerifyIdentityProps = {
  api: {
    submitDateOfBirth: (dateOfBirth: [year: number, day: number, month: number]) => Promise<void>
    submitLast4Ssn: (last4: string) => Promise<Last4SsnOutcome>
    submitFullSsn: (ssn: string) => Promise<Full9SsnOutcome>
  }
  onDone: (result: Last4SsnOutcome) => void
}

export function VerifyIdentity({ api, onDone }: VerifyIdentityProps) {
  const { enabledFlags } = useFeatureFlag()
  const isSsn9Enabled = enabledFlags.includes("SSN9_CHECK")
  const { logEvent } = createLoggingContext()

  useEffect(() => {
    logEvent("Viewed VerifyIdentity screen", { isSsn9Enabled })
  }, [isSsn9Enabled, logEvent])

  // TODO: Remove check + render full ssn when experiment is over
  if (isSsn9Enabled) {
    return <VerifyIdentityFullSsn api={api} onDone={onDone} />
  } else {
    return <VerifyIdentityLastFour api={api} onDone={onDone} />
  }
}
