import { HStack, Secure, Txt, VStack } from "@pomebile/primitives"
import { ScreenForm } from "../../components/ScreenForm"
import { StickyBottom } from "../../components/StickyBottom"
import { FormSubmitButton } from "../../components/Form/FormSubmitButton"
import { useState } from "react"
import { CardPreview } from "../../components/CardPreview/CardPreview"
import { CardColorSelector } from "./CardColorSelectorComponent"
import { CardColors } from "@pomebile/primitives/tokens"
import { CreditAppIdent, PHAddress, USAddress } from "../../api/webRoutes"
import { FormStatus } from "../../components/Form/useForm"
import { Card } from "../UnsecuredOffer/UnsecuredOffer"
import { CardOrderedDialog } from "./CardOrderedDialog"
import { Callout } from "@pomebile/design-system"

export interface CardSelectorProps {
  api: {
    submitCardConfiguration: (cardId: string) => Promise<CreditAppIdent>
  }

  address: USAddress | PHAddress
  nameOnCard?: string
  cardOptions: Card[]
  onDone: (selectedCardColor: CardColors) => void
}

export function CardSelector({
  api: { submitCardConfiguration },
  address,
  nameOnCard,
  cardOptions,
  onDone,
}: CardSelectorProps) {
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [status, setStatus] = useState<FormStatus>("idle")
  const [selectedCardId, setSelectedCard] = useState<number>(0)

  const handleDialogContinue = () => {
    setDialogOpen(false)
    onDone(cardOptions[selectedCardId].color)
  }

  return (
    <ScreenForm
      onSubmit={() => {
        setStatus("submitting")

        submitCardConfiguration(cardOptions[selectedCardId].cardConfigId)
          .then(() => {
            setStatus("done")
            setDialogOpen(true)
          })
          .catch(() => {
            setStatus("submitErr")
          })
      }}
    >
      <VStack gap="xl2">
        <VStack>
          <Txt textAlign="center" variant="headline2" as="h2">
            Select your card style
          </Txt>
          <HStack alignItems="center" justifyContent="center">
            <CardPreview cardColor={cardOptions[selectedCardId].color} nameOnCard={nameOnCard} />
          </HStack>
          <HStack justifyContent="center">
            <HStack justifyContent="center" gap="md">
              {cardOptions.map((option, index) => (
                <CardColorSelector
                  key={option.cardConfigId}
                  cardColor={option.color}
                  isSelected={option.color === cardOptions[selectedCardId].color}
                  onSelect={() => {
                    setSelectedCard(index)
                  }}
                />
              ))}
            </HStack>
          </HStack>
        </VStack>
        {address.country === "US" ? (
          <Txt variant="body2" textAlign="center">
            Your Pomelo Mastercard will arrive in 3-5 business days via USPS to your home address:
          </Txt>
        ) : (
          <Txt variant="body2" textAlign="center">
            Your Pomelo Mastercard will arrive in 5-7 business days via courier delivery to your
            home address:
          </Txt>
        )}

        <VStack gap="xs">
          <Callout>
            <VStack padding="xs2">
              <Secure>
                <Txt variant="body1">{address.addressLineOne}</Txt>
                {address.addressLineTwo && <Txt variant="body1">{address.addressLineTwo}</Txt>}
              </Secure>

              {address.country === "PH" ? (
                <>
                  <Secure>
                    <Txt variant="body1">{`${address.barangay}, ${address.cityOrMunicipality}`}</Txt>
                    <Txt variant="body1">{`${address.postalCode} ${address.province}`}</Txt>
                  </Secure>
                  <Txt variant="body1">Philippines</Txt>
                </>
              ) : (
                <>
                  <Secure>
                    <Txt variant="body1">{`${address.city}, ${address.state}${
                      address.zip ? ` ${address.zip}` : ""
                    }`}</Txt>
                  </Secure>
                  <Txt variant="body1">USA</Txt>
                </>
              )}
            </VStack>
          </Callout>
          <Txt variant="caption" color="text-caption" textAlign="center">
            This is pre-filled with the information from your Pomelo account and cannot be changed.
          </Txt>
        </VStack>
        <StickyBottom>
          <FormSubmitButton status={status}>Continue</FormSubmitButton>
        </StickyBottom>

        <CardOrderedDialog
          destinationCountry={address.country}
          isOpen={isDialogOpen}
          onContinue={handleDialogContinue}
        />
      </VStack>
    </ScreenForm>
  )
}
