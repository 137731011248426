import { Txt, VStack } from "@pomebile/primitives"
import { useEffect } from "react"
import { ScreenFrame } from "../components/ScreenFrame"

export type OpenAppScreenProps = {
  navigateToApp(): void
}

export function OpenAppScreen({ navigateToApp }: OpenAppScreenProps) {
  useEffect(() => {
    setTimeout(navigateToApp, 3000)
  }, [navigateToApp])

  return (
    <ScreenFrame>
      <VStack alignItems="center" justifyContent="center" height="full">
        <img src="images/pomelo_spinner_gray.gif" alt="Loading Spinner" width={60} height={60} />
        <Txt variant="headline2" textAlign="center">
          We’re logging you in
        </Txt>
        <Txt variant="body2" textAlign="center">
          If you are not redirected, please close this window and log in via the app
        </Txt>
      </VStack>
    </ScreenFrame>
  )
}
