/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SubmitCreditRequest {
  /**
   * List of documents that the applicant is signing
   */
  documents?: Array<SubmitCreditRequestDocumentsEnum>
  /**
   * Annual income stated by the applicant
   */
  statedIncome?: number
}

export const SubmitCreditRequestDocumentsEnum = {
  PomeloTermsV1: "POMELO_TERMS_V1",
  CoastalTermsV1: "COASTAL_TERMS_V1",
  EsignV1: "ESIGN_V1",
  CardholderAgreementV1: "CARDHOLDER_AGREEMENT_V1",
  PomeloTerms: "POMELO_TERMS",
  CoastalTerms: "COASTAL_TERMS",
  Esign: "ESIGN",
  CardholderAgreement: "CARDHOLDER_AGREEMENT",
  PromoTerms: "PROMO_TERMS",
  MtpPromoTerms: "MTP_PROMO_TERMS",
} as const
export type SubmitCreditRequestDocumentsEnum =
  (typeof SubmitCreditRequestDocumentsEnum)[keyof typeof SubmitCreditRequestDocumentsEnum]
