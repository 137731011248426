import { Stack } from "@pomebile/primitives"
import { BackgroundColors } from "@pomebile/primitives/tokens"

export type CalloutVariants = "info" | "error" | "warning" | "success"

interface CalloutProps {
  icon?: JSX.Element
  children: React.ReactNode
  variant?: CalloutVariants
}

export const Callout = ({ children, variant = "info" }: CalloutProps) => {
  let backgroundColor: BackgroundColors

  switch (variant) {
    case "info":
      backgroundColor = "background-callout-default"
      break
    case "warning":
      backgroundColor = "background-callout-warning"
      break
    case "error":
      backgroundColor = "background-callout-error"
      break
    case "success":
      backgroundColor = "background-callout-success"
  }

  return (
    <Stack padding={{ y: "md", x: "lg" }} border={{}} borderRadius="sm" fill={backgroundColor}>
      {children}
    </Stack>
  )
}
