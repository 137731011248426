import { TextField, VSpace } from "@pomebile/design-system"
import { Secure, Txt, VStack } from "@pomebile/primitives"
import * as Yup from "yup"
import * as V from "../utils/formValidation/validationMessages"
import { ScreenForm } from "../components/ScreenForm"
import { StickyBottom } from "../components/StickyBottom"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { useForm } from "../components/Form/useForm"
import { FullSsnOutcome, SsnRequirementStatus } from "../api/webRoutes"

export const SsnSchema = Yup.object().shape({
  ssn: Yup.string()
    .test("is-valid-ssn", V.MSG_SSN, (value) => !!value && /^\d{3}-\d{2}-\d{4}$/.test(value))
    .test("is-not-itin", V.MSG_ITIN, (value) => !!value && !/^9/.test(value))
    .max(11, V.MSG_SSN)
    .min(11, V.MSG_SSN)
    .required(V.MSG_REQUIRED),
})

export type FullSsnProps = {
  api: {
    addSsn: (ssn: string) => Promise<FullSsnOutcome>
  }
  onDone: (outcome: Exclude<FullSsnOutcome, "unexpectedResult">) => void
}

const extractSsnDigits = (ssn: string) => ssn.replace(/\D/g, "")

const formatSsn = (ssnDigits: string) => {
  const firstPart = ssnDigits.slice(0, 3)
  const middlePart = ssnDigits.slice(3, 5)
  const lastPart = ssnDigits.slice(5)

  if (lastPart.length > 0) {
    return `${firstPart}-${middlePart}-${lastPart}`
  }
  if (middlePart.length > 0) {
    return `${firstPart}-${middlePart}`
  }
  return `${firstPart}`
}

export function FullSsn({ api, onDone }: FullSsnProps) {
  const [submit, getFieldProps, status] = useForm({
    name: "SSN", // Note: Must match 1.0 name for analytics
    schema: SsnSchema,
    initial: { ssn: "" },
    submit: async ({ ssn }) => {
      const ssnDigits = extractSsnDigits(ssn)
      const res = await api.addSsn(ssnDigits)
      if (res === "unexpectedResult") {
        throw new Error("Unexpected response from submitDetails")
      }
      onDone(res)
    },
  })

  const { onChange, ...restOfSsnProps } = getFieldProps("ssn")

  const handleSsnChange = (value: string) => {
    if (value.length <= 11) {
      const ssnDigits = extractSsnDigits(value)
      const formattedSsn = formatSsn(ssnDigits)
      onChange(formattedSsn)
    }
  }

  return (
    <ScreenForm onSubmit={submit}>
      <VStack>
        <Txt variant="headline2" as="h2">
          Additional Info Required
        </Txt>
        <VSpace height="xs" />
        <Txt>
          We were not able to verify your identify with the last 4 digits of your SSN. Please enter
          your full SSN instead.
        </Txt>
        <VSpace height="xl2" />

        <Secure>
          <TextField
            label="Full SSN"
            placeholder="000-00-0000"
            type="text"
            {...restOfSsnProps}
            onChange={handleSsnChange}
            supportingText="Your info is encrypted using banking-grade technology."
          />
        </Secure>
      </VStack>
      <VSpace height="xl2" />

      <StickyBottom>
        <FormSubmitButton status={status}>Submit</FormSubmitButton>
        <VSpace height="md" />
        <VStack justifyContent="center">
          <Txt textAlign="center" variant="caption">
            Viewing your offer won't impact your credit score.
          </Txt>
        </VStack>
      </StickyBottom>
    </ScreenForm>
  )
}

function modifyField<T extends object, K extends keyof T>(
  field: K,
  setValue: (f: (obj: T) => T) => void,
): (val: T[K]) => void {
  return (val) => setValue((prev) => ({ ...prev, [field]: val }))
}
