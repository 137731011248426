import { Svg, Path, SvgProps, G, Mask } from "@pomebile/primitives"

export const GCashMiniIcon = ({ width = 28, height = 24 }: SvgProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 28 24" customFill="none">
      <Mask
        id="mask0_1651_13728"
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={width}
        height={height}
      >
        <Path d="M28 0H0V24H28V0Z" customFill="#FFFFFF" />
      </Mask>
      <G mask="url(#mask0_1651_13728)">
        <Path
          d="M25.282 12.0173C25.282 14.2103 24.7795 16.3245 23.8304 18.2243C23.5121 18.8618 23.7169 19.6403 24.3116 20.0213C24.9836 20.4518 25.8759 20.2035 26.2362 19.4835C27.3577 17.2425 27.9899 14.7053 27.9899 12.0173C27.9899 9.3345 27.3599 6.8018 26.2421 4.563C25.8825 3.8423 24.9902 3.594 24.3175 4.0245C23.7228 4.4055 23.518 5.1833 23.8356 5.8208C24.7817 7.7175 25.282 9.828 25.282 12.0173Z"
          customFill="#5BBCE6"
        />
        <Path
          d="M20.9034 12.0172C20.9034 13.377 20.6175 14.6917 20.0744 15.891C19.79 16.5195 20.0081 17.2657 20.5836 17.634C21.277 18.078 22.1943 17.799 22.537 17.0422C23.2406 15.4867 23.612 13.7805 23.612 12.0172C23.612 10.2585 23.2429 8.5575 22.5421 7.005C22.2002 6.2475 21.2821 5.9677 20.5888 6.4117C20.014 6.78 19.7952 7.5255 20.0789 8.154C20.619 9.3502 20.9034 10.6612 20.9034 12.0172Z"
          customFill="#5BBCE6"
        />
        <Path
          d="M11.8057 21.2775C9.3756 21.2775 7.0906 20.3145 5.3723 18.5655C3.654 16.8165 2.7079 14.4908 2.7079 12.0173C2.7079 9.5438 3.654 7.218 5.3723 5.469C7.0906 3.72 9.3756 2.757 11.8057 2.757C14.4296 2.757 16.2975 3.843 17.3622 4.6815C17.9023 5.106 18.6664 5.0618 19.1483 4.5705C19.7253 3.984 19.6641 3.015 19.0172 2.5088C17.5693 1.3763 15.1384 0 11.8057 0C8.652 0 5.6877 1.2502 3.4573 3.5198C1.2276 5.7893 0 8.8073 0 12.0173C0 15.2078 1.2408 18.258 3.4573 20.514C5.6869 22.7843 8.652 24.0338 11.8057 24.0338C14.4583 24.0338 16.9768 23.1495 19.0371 21.5183C19.6693 21.018 19.7245 20.0648 19.1579 19.488C19.1483 19.4783 19.1387 19.4685 19.1292 19.4588C18.6546 18.9765 17.9023 18.9278 17.3711 19.3448C16.3299 20.1623 14.4414 21.2775 11.8057 21.2775Z"
          customFill="#115FAF"
        />
        <Path
          d="M18.8825 11.0355C18.6268 10.7753 18.2798 10.629 17.9187 10.629H17.9172L13.1771 10.635C12.4248 10.6358 11.8147 11.2568 11.8147 12.0233C11.8147 12.7898 12.4248 13.4115 13.1786 13.4115C13.1808 13.4115 13.1838 13.4108 13.1867 13.4108L16.3551 13.4063C15.7509 15.4485 13.8521 16.9245 11.6342 16.8413C9.07288 16.746 7.03548 14.5598 7.06938 11.9513C7.10408 9.3158 9.21438 7.1895 11.8118 7.1895C12.7188 7.1895 13.5868 7.4468 14.3354 7.9275C14.8741 8.2733 15.5778 8.184 16.028 7.7258C16.6418 7.101 16.5312 6.0555 15.7973 5.5823C14.1763 4.5383 12.1986 4.1595 10.1951 4.5855C7.32808 5.1953 5.08878 7.5008 4.50228 10.4213C3.52008 15.3083 7.18068 19.6208 11.8118 19.6208C15.9314 19.6208 19.2826 16.2098 19.2826 12.0173C19.2826 11.649 19.1382 11.2958 18.8825 11.0355Z"
          customFill="#1B2A7A"
        />
      </G>
    </Svg>
  )
}
