import { Avatar, HStack, Txt, VStack } from "@pomebile/primitives"
import { ChatTextIcon, LinkBreakIcon } from "@pomebile/design-system"
import { ScreenFrame } from "../components/ScreenFrame"

export function NrauInvalidInviteScreen(/* { onDone }: InvalidInviteProps */) {
  return (
    <ScreenFrame>
      <VStack height="full" justifyContent="space-between">
        <VStack height="full" gap="xl2" padding={{ bottom: "lg" }}>
          <VStack padding={{ bottom: "xl3" }}>
            <Txt variant="headline2">It looks like your invite link is no longer valid</Txt>
            <Txt variant="body1">
              We want to make sure we add you to your loved one's Pomelo plan.
            </Txt>
          </VStack>

          <VStack gap="xl">
            <VStack gap="xs">
              <Txt variant="overline2" color="text-disabled">
                This could be due to:
              </Txt>
              <HStack alignItems="center" gap="md">
                <Avatar
                  size="lg"
                  svg={<LinkBreakIcon width={32} height={32} fill="icon-brand" />}
                />

                <VStack>
                  <Txt variant="subtitle2">Link already used</Txt>
                  <Txt>The link may have already been used or deleted by the sender.</Txt>
                </VStack>
              </HStack>
            </VStack>

            <VStack gap="xs">
              <Txt variant="overline2" color="text-disabled">
                What to do next:
              </Txt>
              <HStack alignItems="center" gap="md">
                <Avatar size="lg" svg={<ChatTextIcon width={32} height={32} fill="icon-brand" />} />

                <VStack>
                  <Txt variant="subtitle2">Contact your loved one who invited you to Pomelo</Txt>
                  <Txt>
                    Ask them to send you a new, valid invite link to join their Pomelo plan.
                  </Txt>
                </VStack>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
      </VStack>
    </ScreenFrame>
  )
}
