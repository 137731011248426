import { AvatarComponent } from "@pomebile/primitives"
import {
  avatarColorVariants,
  avatarImgStyles,
  avatarObjectFit,
  avatarSizeVariants,
  containerClipPathStyles,
  containerLayoutStyles,
} from "./Avatar.css"
import { VStack } from "../Stack/Stack"
import { Svg, ClipPath, Defs, Path } from "../Svg/Svg"
import { Txt } from "../.."

export const Avatar: AvatarComponent = ({ variant, size, svg, text, image }) => {
  const isDev = process.env.NODE_ENV === "development"

  const renderChildren = () => {
    if (text) {
      if (typeof text === "string") {
        return <span>{text}</span>
      }

      if (isDev && text.type.name !== Txt.name) {
        throw new Error("Invalid Text")
      }

      return text
    }

    if (image) {
      return (
        <img className={`${avatarImgStyles} ${avatarObjectFit}`} src={image.uri} alt={image.alt} />
      )
    }

    if (svg) {
      if (isDev && !svg.type.name.includes("Icon")) {
        throw new Error("Invalid SVG")
      }

      return svg
    }

    return undefined
  }

  const colorVariant = variant ? avatarColorVariants[variant] : avatarColorVariants.default
  const sizeVariant = size ? avatarSizeVariants[size] : avatarSizeVariants.md

  return (
    <VStack width="auto">
      <div
        className={`${containerLayoutStyles} ${containerClipPathStyles} ${colorVariant} ${sizeVariant}`}
      >
        {renderChildren()}
      </div>
      {/* Svg here is used to declare container clip path that's why we're using a width and height of zero to essentially render nothing */}
      <Svg width={0} height={0} customFill="none">
        <Defs>
          <ClipPath id="avatarShape" clipPathUnits="objectBoundingBox">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              // path coordinates are relative to allow resizing
              d="M0.5,1 C0.318,1,0,0.682,0,0.5 C0,0.318,0.318,0,0.5,0 C0.682,0,1,0.318,1,0.5 C1,0.682,0.682,1,0.5,1"
            />
          </ClipPath>
        </Defs>
      </Svg>
    </VStack>
  )
}
